/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { toast } from 'react-toastify';
import Table from '../../components/Table';
import { api } from '../../services/api';
import LoadingPage from '../../components/LoadingPage';
import AvisoCadastro from '../../components/AvisoCadastro';

export default function HistoricoContratosTermos() {
  const [listaHistorico, setListaHistorico] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);

  const key = localStorage.getItem('@EPF2:keyTitular');

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchListaHistoricoContratos() {
    try {
      const res = await api.get(`consultaAPI/consultas/contratos/${key}/todos`);
      return setListaHistorico(res.data.result);
    } catch (error) {
      return toast.error('Não foi possível exibir a lista de históricos');
    }
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchListaHistoricoContratos();
    setLoadingPage(false);
  }, []);

  const columnsListaHistorico = [
    {
      id: 'numeroContrato',
      Header: 'Contrato',
      accessor: 'numeroContrato',
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: 'status',
    },
    {
      id: 'projeto',
      Header: 'Projeto',
      accessor: 'projeto',
    },
    {
      id: 'vigencia',
      Header: 'Vigência',
      accessor: 'vigencia',
    },
    {
      id: 'valor',
      Header: 'Valor',
      accessor: 'valor',
    },
  ];

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <Form>
        <Form.Group>
          <h3>Histórico de Contratos</h3>
          <br />

          <Table columns={columnsListaHistorico} data={listaHistorico} />
        </Form.Group>
      </Form>
    </>
  );
}
