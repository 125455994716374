import React from 'react';
import { Button } from 'react-bootstrap';
import { FaPowerOff } from 'react-icons/fa';

import { useAuth } from '../../contexts/auth';

import { Container } from './styles';

export default function ProfileDetalhes() {
  const { signed, signOut } = useAuth();

  return (
    <>
      <Container>
        <div>
          <img
            src={`${process.env.REACT_APP_API_SEG}FotoUsuario/ObterPorId/${signed.IdUsuario}`}
            alt="Foto usuário logado"
          />
        </div>
        <p>{signed.Nome}</p>
        <small>{signed.Email}</small>
        <hr />
        <Button
          type="button"
          variant="danger"
          size="sm"
          block
          onClick={() => signOut()}
        >
          <FaPowerOff /> Sair
        </Button>
      </Container>
    </>
  );
}
