/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Table from '../../components/Table';
import { api } from '../../services/api';

export default function AcoesPendentes({ fetchTotal }) {
  const [acoesPendentes, setAcoesPendentes] = useState([]);
  const history = useHistory();

  async function fetchAcoesPendentes() {
    try {
      const res = await api.get(`cadastroapi/api/v1/AcoesPendentes/`);
      // const res = await api.get(``);
      setAcoesPendentes(res.data.result);
      fetchTotal(res.data.result.length, 'acoesPendentes');
    } catch (err) {
      toast.error('Não foi possível carregar a lista de ações pendentes.');
    }
  }

  const redirecionar = (keyTitularDados, tipoPendencia, keySolicitacao) => {
    localStorage.setItem('@EPF2:keyTitular', keyTitularDados);

    if (tipoPendencia === 1 || tipoPendencia === 2) {
      localStorage.setItem('@EPF2:menu', 'Cadastro');
      history.push('/dadosBasicos');
    }

    if (tipoPendencia === 3) {
      localStorage.setItem('@EPF2:menu', 'Bolsas');
      history.push({
        pathname: `/bolsas/relatorios/solicitacao/${keySolicitacao}`,
        state: 'Editar',
      });
    }

    if (tipoPendencia === 4) {
      localStorage.setItem('@EPF2:menu', 'Bolsas');
      history.push({
        pathname: `/bolsas/cancelamentos/solicitacao/${keySolicitacao}`,
        state: 'Editar',
      });
    }
  };

  useEffect(() => fetchAcoesPendentes(), []);

  const columnsAcoesPendentes = [
    {
      id: 'descricaoVinculo',
      Header: 'Vínculo',
      accessor: 'vinculoDescricao',
      disableFilters: true,
    },
    {
      id: 'statusDescricao',
      Header: 'Status',
      accessor: 'tipoAcaoPendente',
      disableFilters: true,
    },
    {
      id: 'justificativa',
      Header: 'Justificativa',
      accessor: 'descricao',
      disableFilters: true,
    },
    {
      id: 'data',
      Header: 'Data',
      accessor: 'dataInclusaoTexto',
      disableFilters: true,
    },
    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          <Button
            variant="primary"
            size="sm"
            onClick={() =>
              redirecionar(
                rows.row.original.keyTitularDados,
                rows.row.original.tipoPendencia,
                rows.row.original.keySolicitacaoSistemaOrigem
              )
            }
          >
            Visualizar
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Table data={acoesPendentes} columns={columnsAcoesPendentes} />
    </>
  );
}
