import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { toast } from 'react-toastify';
import { api } from '../../../services/api';
import {
  isFieldAvailable,
  mascara,
  isValidDate,
  stringToDate,
  validaIdade,
  dateValid,
} from '../../../utils';

import AvisoCadastro from '../../../components/AvisoCadastro';
import LoadingPage from '../../../components/LoadingPage';

import Anexos from '../../../components/Anexos';
import { useParametrizacao } from '../../../contexts/parametrizacao';
import { useVinculo } from '../../../contexts/vinculo';
import { useEtapasVinculo } from '../../../contexts/etapasVinculo';

export default function DocumentosIdentificacao() {
  const [camposParametrizados, setCamposParametrizados] = useState([]);
  const [documentosIdentificacao, setDocumentosIdentificacao] = useState([]);
  const [tiposDeVisto, setTiposDeVisto] = useState([]);
  const [listaUf, setListaUf] = useState([]);
  const [isEstrangeiro, setIsEstrangeiro] = useState(null);
  const [generoTitular, setGeneroTitular] = useState([]);
  const [dataNascimentoTitular, setDataNascimentoTitular] = useState([]);
  const [exibirCR, setExibirCR] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSalvarCadastro, setLoadingSalvarCadastro] = useState(false);
  const [camposDesabilitados, setCamposDesabilitados] = useState(false);

  const { handleSubmit, register, watch, reset, getValues } = useForm({
    mode: 'onBlur',
  });

  const key = localStorage.getItem('@EPF2:keyTitular');
  const { parametrizacao } = useParametrizacao();
  const { vinculo } = useVinculo();
  const { etapasVinculo } = useEtapasVinculo();

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchDadosBasicos() {
    const res = await api.get(
      `cadastroapi/api/v1/DadosBasicos/${key}/detalhes`
    );

    if (res.data.result[0]) {
      setIsEstrangeiro(res.data.result[0].estrangeiro);
      setGeneroTitular(res.data.result[0].idGenero);
      setDataNascimentoTitular(res.data.result[0].dataNascimento);
    }
  }

  async function fetchDocumentosIdentificacao() {
    if (key && key.length) {
      try {
        const res = await api.get(
          `cadastroapi/api/v1/Documentos/${key}/documentos`
        );
        setDocumentosIdentificacao(res.data.result);
      } catch (error) {
        return toast.error('Não foi possível carregar as informações.');
      }
    }

    return '';
  }

  async function fetchListaUF() {
    try {
      const res = await api.get('dicionarioapi/api/v1/Dicionarios/tipos-uf');
      setListaUf(res.data.result);
    } catch (error) {
      return toast.error('Não foi possível carregar a lista de UFs.');
    }

    return '';
  }

  async function fetchTiposDeVisto() {
    try {
      const res = await api.get('dicionarioapi/api/v1/Dicionarios/tipos-visto');
      setTiposDeVisto(res.data.result);
    } catch (error) {
      return toast.error('Não foi possível carregar as tipos de vistos.');
    }

    return '';
  }

  function certificadoReservista() {
    // mostrar opções do certificado de reservista para o sexo masculino
    // com idade igual ou menor que 45 anos
    if (generoTitular === 1 && validaIdade(dataNascimentoTitular)) {
      setExibirCR(true);
    }
  }

  const naoPossuiNis = watch('naoPossuiNis');

  useEffect(() => {
    const remover = {};

    if (naoPossuiNis) {
      remover.nis = '';
    }
    if (remover) {
      reset({ ...getValues(), ...remover });
    }
  }, [naoPossuiNis]);

  useEffect(async () => {
    setLoadingPage(true);
    await fetchDadosBasicos();
    await fetchTiposDeVisto();
    await fetchListaUF();
    await fetchDocumentosIdentificacao();
    setLoadingPage(false);
  }, []);

  useEffect(() => {
    if (dataNascimentoTitular) {
      certificadoReservista();
    }
  }, [dataNascimentoTitular]);

  useEffect(() => {
    setCamposParametrizados(parametrizacao[4]?.campos);
    const campos = parametrizacao[4]?.campos.every(
      (field) => field.isObrigatorio === false
    );
    setCamposDesabilitados(campos);
  }, [parametrizacao]);

  useEffect(() => {
    if (documentosIdentificacao.keyTitularDados) {
      reset({
        cpf: documentosIdentificacao.cpfNumero,
        ctpsDigital: JSON.stringify(
          documentosIdentificacao.isPossuiCtpsDigital
        ),
        categoriaCertificadoReservista: documentosIdentificacao.crCategoria,
        numeroCertificadoReservista: documentosIdentificacao.crNumero,
        orgaoEmissorCertificadoReservista:
          documentosIdentificacao.crOrgaoEmissor,
        ufExpedicaoCertificadoReservista: documentosIdentificacao.crUf,
        nis: documentosIdentificacao.nisNumero,
        naoPossuiNis: !documentosIdentificacao.isPossuiNIS,
        orgaoEmissorOC: documentosIdentificacao.ocNomeOrgaoEmissor,
        numeroOC: documentosIdentificacao.ocNumero,
        siglaOrgaoEmissor: documentosIdentificacao.ocSiglaEmissor,
        ufExpedicaoOC: documentosIdentificacao.ocUf,
        numeroPassaporte: documentosIdentificacao.paNumero,
        rg: documentosIdentificacao.rgNumero,
        orgaoEmissorRG: documentosIdentificacao.rgOrgaoEmissor,
        ufExpedicaoRG: documentosIdentificacao.rgUf,
        numeroRNE: documentosIdentificacao.rneNumero,
        orgaoEmissorRNE: documentosIdentificacao.rneOrgaoEmissor,
        ufRNE: documentosIdentificacao.rneUf,
        tituloEleitor: documentosIdentificacao.teNumero,
        secao: documentosIdentificacao.teSecao,
        ufExpedicaoTitulo: documentosIdentificacao.teUf,
        zona: documentosIdentificacao.teZonaEleitoral,
        tipoVisto: documentosIdentificacao.vtIdTipo,
        numeroSerie: documentosIdentificacao.vtNumeroSerie,

        rgDataValidade: documentosIdentificacao.rgValidade,
        rgDataExpedicao: documentosIdentificacao.rgExpedicao,

        ocDataValidade: documentosIdentificacao.ocValidade,
        ocDataExpedicao: documentosIdentificacao.ocExpedicao,

        teDataExpedicao: documentosIdentificacao.teExpedicao,

        rneDataExpedicao: documentosIdentificacao.rneExpedicao,
        rneDataValidade: documentosIdentificacao.rneValidade,

        crDataExpedicao: documentosIdentificacao.crExpedicao,

        paDataEmissao: documentosIdentificacao.padEmissao,
        paDataValidade: documentosIdentificacao.paValidade,

        vtDataValidade: documentosIdentificacao.vtValidade,
        vtDataEmissao: documentosIdentificacao.vtEmissao,
      });
    }
  }, [documentosIdentificacao]);

  async function handleSubmitSalvarDocumentoIdentificacao(data) {
    if (!dateValid) {
      return toast.error('Data preenchida inválida.');
    }

    let dadosFormulario = {
      keyTitularDados: key,
      cpfNumero: documentosIdentificacao.cpfNumero,
      nisNumero: data.nis || null,
      isPossuiNIS: !data.naoPossuiNis,
      isPossuiCtpsDigital: data.ctpsDigital && JSON.parse(data.ctpsDigital),
      rgNumero: data.rg,
      rgOrgaoEmissor: data.orgaoEmissorRG,
      rgDataValidade: stringToDate(data.rgDataValidade),
      rgUf: data.ufExpedicaoRG,
      rgDataExpedicao: stringToDate(data.rgDataExpedicao),
      ocNumero: data.numeroOC,
      ocSiglaEmissor: data.siglaOrgaoEmissor,
      ocDataValidade: stringToDate(data.ocDataValidade),
      ocNomeOrgaoEmissor: data.orgaoEmissorOC,
      ocUf: data.ufExpedicaoOC,
      ocDataExpedicao: stringToDate(data.ocDataExpedicao),
      teNumero: data.tituloEleitor,
      teZonaEleitoral: data.zona,
      teDataExpedicao: stringToDate(data.teDataExpedicao),
      teUf: data.ufExpedicaoTitulo,
      teSecao: data.secao,

      crUf: data.ufExpedicaoCertificadoReservista,
    };

    if (exibirCR) {
      // salvar certificado de reservista para sexo masculino
      // com idade igual ou menor que 45 anos
      dadosFormulario = {
        ...dadosFormulario,
        crNumero: data.numeroCertificadoReservista,
        crCategoria: data.categoriaCertificadoReservista,
        crOrgaoEmissor: data.orgaoEmissorCertificadoReservista,
        crDataExpedicao: stringToDate(data.crDataExpedicao),
      };
    }

    if (isEstrangeiro) {
      dadosFormulario = {
        ...dadosFormulario,
        rneNumero: data.numeroRNE,
        rneOrgaoEmissor: data.orgaoEmissorRNE,
        rneDataExpedicao: stringToDate(data.rneDataExpedicao),
        rneDataValidade: stringToDate(data.rneDataValidade),
        rneUf: data.ufRNE,
        paNumero: data.numeroPassaporte,
        paDataEmissao: stringToDate(data.paDataEmissao),
        paDataValidade: stringToDate(data.paDataValidade),
        vtNumeroSerie: data.numeroSerie,
        vtDataValidade: stringToDate(data.vtDataValidade),
        vtIdTipo: data.tipoVisto || 0,
        vtDataEmissao: stringToDate(data.vtDataEmissao),
      };
    }

    try {
      setLoadingSalvarCadastro(true);
      await api.put('cadastroapi/api/v1/Documentos', dadosFormulario);
      setLoadingSalvarCadastro(false);
      fetchDocumentosIdentificacao();
      return toast.success('Cadastro salvo.');
    } catch (err) {
      setLoadingSalvarCadastro(false);
      return toast.error('Não foi possivel salvar.');
    }
  }

  if (vinculo.idCadastroStatus === 6) {
    return (
      <AvisoCadastro msg="Esse vínculo foi excluído, por esse motivo essa etapa não pode ser acessada." />
    );
  }

  if (etapasVinculo?.blocos?.[3].isAtivo && camposDesabilitados) {
    return <AvisoCadastro msg="Nenhum campo habilitado para preenchimento." />;
  }

  if (etapasVinculo?.blocos && !etapasVinculo?.blocos?.[3].isAtivo) {
    return (
      <AvisoCadastro msg="Essa etapa está desabilitada para esse vínculo." />
    );
  }

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Documentos de Identificação</h3>

      {vinculo.mensagemEdicaoCadastro && (
        <AvisoCadastro msg={vinculo.mensagemEdicaoCadastro} vinculo={vinculo} />
      )}

      <Form onSubmit={handleSubmit(handleSubmitSalvarDocumentoIdentificacao)}>
        <Form.Group>
          <Row>
            {isFieldAvailable('Documento_CPF', camposParametrizados) && (
              <Col md="6">
                <Form.Label>CPF</Form.Label>
                <Form.Control name="cpf" ref={register()} disabled />
              </Col>
            )}
          </Row>
          <br />
          {!isEstrangeiro && (
            <>
              <Row>
                {isFieldAvailable('Documento_NIS', camposParametrizados) && (
                  <>
                    <Col md="6">
                      <Form.Label>NIS(PIS/Pasep/NIT)</Form.Label>
                      <Form.Control
                        name="nis"
                        ref={register()}
                        disabled={
                          naoPossuiNis || !vinculo.habilitaEditarCadastro
                        }
                        maxLength="100"
                      />
                    </Col>
                    <Col md="6">
                      <Form.Check
                        name="naoPossuiNis"
                        ref={register()}
                        type="checkbox"
                        label="Não possui NIS"
                        style={{ paddingTop: '30px' }}
                        disabled={!vinculo.habilitaEditarCadastro}
                      />
                    </Col>
                  </>
                )}

                <br />
                <br />
              </Row>
            </>
          )}
          <br />
          {isFieldAvailable(
            'Documento_IsPossuiCtpsDigital',
            camposParametrizados
          ) && (
            <Row>
              <br />

              <Col md="8">
                <Form.Label>CTPS Digital</Form.Label>
                <br clear="all" />
                <br />
                <Form.Check
                  name="ctpsDigital"
                  type="radio"
                  value="true"
                  label="Sim"
                  ref={register()}
                  style={{ float: 'left', marginRight: '20px' }}
                  disabled={!vinculo.habilitaEditarCadastro}
                />
                <Form.Check
                  name="ctpsDigital"
                  type="radio"
                  value="false"
                  label="Não"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                />
              </Col>
            </Row>
          )}

          {isFieldAvailable('Documento_RG', camposParametrizados) && (
            <Row>
              <br />

              <Col>
                <br />
                <h5>Registro Geral</h5>
              </Col>
            </Row>
          )}
          <Row>
            {isFieldAvailable('Documento_RG', camposParametrizados) && (
              <Col md="6">
                <Form.Label>Nº RG</Form.Label>
                <Form.Control
                  name="rg"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
            {isFieldAvailable(
              'Documento_OrgaoEmissorRG',
              camposParametrizados
            ) && (
              <Col md="6">
                <Form.Label>Órgão Emissor</Form.Label>
                <Form.Control
                  name="orgaoEmissorRG"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
          </Row>
          <Row>
            <br />

            {isFieldAvailable(
              'Documento_DataValidadeRG',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>Data de Validade</Form.Label>
                <Form.Control
                  name="rgDataValidade"
                  ref={register()}
                  placeholder="__/__/____"
                  onInput={(e) => mascara(e.target, 'data')}
                  onBlur={(e) => isValidDate(e.target)}
                  disabled={!vinculo.habilitaEditarCadastro}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                />
              </Col>
            )}
            {isFieldAvailable('Documento_UfRG', camposParametrizados) && (
              <Col md="4">
                <br />
                <Form.Label>UF Expedição</Form.Label>
                <Form.Control
                  name="ufExpedicaoRG"
                  ref={register()}
                  as="select"
                  disabled={!vinculo.habilitaEditarCadastro}
                >
                  <option aria-label="Escolha" />
                  {listaUf.map((uf) => (
                    <option key={uf.id} value={uf.id}>
                      {uf.descricao}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            )}
            {isFieldAvailable(
              'Documento_DataExpedicaoRG',
              camposParametrizados
            ) && (
              <>
                <Col md="4">
                  <br />
                  <Form.Label>Data de Expedição</Form.Label>
                  <Form.Control
                    name="rgDataExpedicao"
                    ref={register()}
                    placeholder="__/__/____"
                    onInput={(e) => mascara(e.target, 'data')}
                    onBlur={(e) => isValidDate(e.target)}
                    disabled={!vinculo.habilitaEditarCadastro}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Col>
                <br />
                <br />
              </>
            )}
          </Row>

          {isFieldAvailable('Documento_OrgaoClasse', camposParametrizados) && (
            <Row>
              <Col>
                <br />
                <h5>Órgão de Classe</h5>
              </Col>
            </Row>
          )}

          <br />
          <Row>
            {isFieldAvailable(
              'Documento_OrgaoClasse',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>Nº Órgão de Classe</Form.Label>
                <Form.Control
                  name="numeroOC"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
            {isFieldAvailable(
              'Documento_SiglaEmissorOrgaoClasse',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>Sigla Órgão Emissor</Form.Label>
                <Form.Control
                  name="siglaOrgaoEmissor"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
            {isFieldAvailable(
              'Documento_DataValidadeOrgaoClasse',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>Data de Validade</Form.Label>
                <Form.Control
                  name="ocDataValidade"
                  ref={register()}
                  placeholder="__/__/____"
                  onInput={(e) => mascara(e.target, 'data')}
                  onBlur={(e) => isValidDate(e.target)}
                  disabled={!vinculo.habilitaEditarCadastro}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                />
              </Col>
            )}
          </Row>
          <br />
          <Row>
            {isFieldAvailable(
              'Documento_NomeOrgaoEmissorOrgaoClasse',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>Órgão Emissor</Form.Label>
                <Form.Control
                  name="orgaoEmissorOC"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
            {isFieldAvailable(
              'Documento_UfOrgaoClasse',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>UF Expedição</Form.Label>
                <Form.Control
                  name="ufExpedicaoOC"
                  ref={register()}
                  as="select"
                  disabled={!vinculo.habilitaEditarCadastro}
                >
                  <option aria-label="Escolha" />
                  {listaUf.map((uf) => (
                    <option key={uf.id} value={uf.id}>
                      {uf.descricao}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            )}
            {isFieldAvailable(
              'Documento_DataExpedicaoOrgaoClasse',
              camposParametrizados
            ) && (
              <>
                <Col md="4">
                  <Form.Label>Data de Expedição</Form.Label>
                  <Form.Control
                    name="ocDataExpedicao"
                    ref={register()}
                    placeholder="__/__/____"
                    onInput={(e) => mascara(e.target, 'data')}
                    onBlur={(e) => isValidDate(e.target)}
                    disabled={!vinculo.habilitaEditarCadastro}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Col>
                <br />
                <br />
              </>
            )}
          </Row>

          {!isEstrangeiro && (
            <>
              {isFieldAvailable(
                'Documento_NumeroTituloEleitor',
                camposParametrizados
              ) && (
                <Row>
                  <Col>
                    <br />
                    <h5>Título de Eleitor</h5>
                  </Col>
                </Row>
              )}

              <Row>
                {isFieldAvailable(
                  'Documento_NumeroTituloEleitor',
                  camposParametrizados
                ) && (
                  <Col md="4">
                    <Form.Label>Título Eleitor</Form.Label>
                    <Form.Control
                      name="tituloEleitor"
                      ref={register()}
                      disabled={!vinculo.habilitaEditarCadastro}
                      maxLength="100"
                    />
                  </Col>
                )}
                {isFieldAvailable(
                  'Documento_ZonaEleitoralTituloEleitor',
                  camposParametrizados
                ) && (
                  <Col md="4">
                    <Form.Label>Zona</Form.Label>
                    <Form.Control
                      name="zona"
                      ref={register()}
                      disabled={!vinculo.habilitaEditarCadastro}
                      maxLength="100"
                    />
                  </Col>
                )}
                {isFieldAvailable(
                  'Documento_SecaoTituloEleitor',
                  camposParametrizados
                ) && (
                  <Col md="4">
                    <Form.Label>Seção</Form.Label>
                    <Form.Control
                      name="secao"
                      ref={register()}
                      disabled={!vinculo.habilitaEditarCadastro}
                      maxLength="100"
                    />
                  </Col>
                )}
              </Row>
              <br />
              <Row>
                {isFieldAvailable(
                  'Documento_DataExpedicaoTituloEleitor',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>Data de Expedição</Form.Label>
                    <Form.Control
                      name="teDataExpedicao"
                      ref={register()}
                      placeholder="__/__/____"
                      onInput={(e) => mascara(e.target, 'data')}
                      onBlur={(e) => isValidDate(e.target)}
                      disabled={!vinculo.habilitaEditarCadastro}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </Col>
                )}
                {isFieldAvailable(
                  'Documento_UfTituloEleitor',
                  camposParametrizados
                ) && (
                  <>
                    <Col md="6">
                      <Form.Label>UF Expedicão</Form.Label>
                      <Form.Control
                        name="ufExpedicaoTitulo"
                        ref={register()}
                        as="select"
                        disabled={!vinculo.habilitaEditarCadastro}
                      >
                        <option aria-label="Escolha" />
                        {listaUf.map((uf) => (
                          <option key={uf.id} value={uf.id}>
                            {uf.descricao}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <br />
                    <br />
                  </>
                )}
              </Row>

              {exibirCR && (
                <>
                  {isFieldAvailable(
                    'Documento_NumeroReservista',
                    camposParametrizados
                  ) && (
                    <Row>
                      <Col>
                        <br />
                        <h5>Certificado de Reservista</h5>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    {isFieldAvailable(
                      'Documento_NumeroReservista',
                      camposParametrizados
                    ) && (
                      <Col md="4">
                        <Form.Label>Nº Certificado</Form.Label>
                        <Form.Control
                          name="numeroCertificadoReservista"
                          ref={register()}
                          disabled={!vinculo.habilitaEditarCadastro}
                        />
                      </Col>
                    )}
                    {isFieldAvailable(
                      'Documento_CategoriaReservista',
                      camposParametrizados
                    ) && (
                      <Col md="4">
                        <Form.Label>Categoria Certificado</Form.Label>
                        <Form.Control
                          name="categoriaCertificadoReservista"
                          ref={register()}
                          disabled={!vinculo.habilitaEditarCadastro}
                        />
                      </Col>
                    )}
                    {isFieldAvailable(
                      'Documento_OrgaoEmissorReservista',
                      camposParametrizados
                    ) && (
                      <Col md="4">
                        <Form.Label>Orgão Emissor</Form.Label>
                        <Form.Control
                          name="orgaoEmissorCertificadoReservista"
                          ref={register()}
                          disabled={!vinculo.habilitaEditarCadastro}
                        />
                      </Col>
                    )}
                  </Row>
                  <br />
                  <Row>
                    {isFieldAvailable(
                      'Documento_DataExpedicaoReservista',
                      camposParametrizados
                    ) && (
                      <Col md="6">
                        <Form.Label>Data de Expedição</Form.Label>
                        <Form.Control
                          name="crDataExpedicao"
                          ref={register()}
                          placeholder="__/__/____"
                          onInput={(e) => mascara(e.target, 'data')}
                          onBlur={(e) => isValidDate(e.target)}
                          disabled={!vinculo.habilitaEditarCadastro}
                          onPaste={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </Col>
                    )}
                    {isFieldAvailable(
                      'Documento_UfReservista',
                      camposParametrizados
                    ) && (
                      <Col md="6">
                        <Form.Label>UF Expedição</Form.Label>
                        <Form.Control
                          name="ufExpedicaoCertificadoReservista"
                          ref={register()}
                          as="select"
                          disabled={!vinculo.habilitaEditarCadastro}
                        >
                          <option aria-label="Escolha" />
                          {listaUf.map((uf) => (
                            <option key={uf.id} value={uf.id}>
                              {uf.descricao}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    )}
                  </Row>
                </>
              )}
              <br />
            </>
          )}
          {isEstrangeiro && <br />}

          {isFieldAvailable(
            'Documento_NumeroPassaporte',
            camposParametrizados
          ) &&
            isEstrangeiro && (
              <Row>
                <Col>
                  <br />
                  <h5>Passaporte</h5>
                </Col>
              </Row>
            )}

          <Row>
            {isFieldAvailable(
              'Documento_NumeroPassaporte',
              camposParametrizados
            ) &&
              isEstrangeiro && (
                <Col md="4">
                  <Form.Label>Nº Passaporte</Form.Label>
                  <Form.Control
                    name="numeroPassaporte"
                    ref={register()}
                    disabled={!vinculo.habilitaEditarCadastro}
                  />
                </Col>
              )}
            {isFieldAvailable(
              'Documento_DataEmissaoPassaporte',
              camposParametrizados
            ) &&
              isEstrangeiro && (
                <Col md="4">
                  <Form.Label>Data de Emissão</Form.Label>
                  <Form.Control
                    name="paDataEmissao"
                    ref={register()}
                    placeholder="__/__/____"
                    onInput={(e) => mascara(e.target, 'data')}
                    onBlur={(e) => isValidDate(e.target)}
                    disabled={!vinculo.habilitaEditarCadastro}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Col>
              )}
            {isFieldAvailable(
              'Documento_DataValidadePassaporte',
              camposParametrizados
            ) &&
              isEstrangeiro && (
                <Col md="4">
                  <Form.Label>Data de Validade</Form.Label>
                  <Form.Control
                    name="paDataValidade"
                    ref={register()}
                    placeholder="__/__/____"
                    onInput={(e) => mascara(e.target, 'data')}
                    onBlur={(e) => isValidDate(e.target)}
                    disabled={!vinculo.habilitaEditarCadastro}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Col>
              )}
          </Row>
          {isEstrangeiro && <br />}
          <Row>
            {isFieldAvailable('Documento_SerieVisto', camposParametrizados) &&
              isEstrangeiro && (
                <Col md="6">
                  <Form.Label>Nº Série do Visto</Form.Label>
                  <Form.Control
                    name="numeroSerie"
                    ref={register()}
                    disabled={!vinculo.habilitaEditarCadastro}
                  />
                </Col>
              )}
            {isFieldAvailable('Documento_IdTipoVisto', camposParametrizados) &&
              isEstrangeiro && (
                <Col md="6">
                  <Form.Label className="no-padding">Tipo do Visto</Form.Label>
                  <Form.Control
                    name="tipoVisto"
                    ref={register()}
                    as="select"
                    disabled={!vinculo.habilitaEditarCadastro}
                  >
                    <option aria-label="Escolha" />
                    {tiposDeVisto.map((visto) => (
                      <option key={visto.id} value={visto.id}>
                        {visto.descricao}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              )}
          </Row>
          {isEstrangeiro && <br />}
          <Row>
            {isFieldAvailable(
              'Documento_DataValidadeVisto',
              camposParametrizados
            ) &&
              isEstrangeiro && (
                <Col md="6">
                  <Form.Label>Data de Validade do Visto</Form.Label>
                  <Form.Control
                    name="vtDataValidade"
                    ref={register()}
                    placeholder="__/__/____"
                    onInput={(e) => mascara(e.target, 'data')}
                    onBlur={(e) => isValidDate(e.target)}
                    disabled={!vinculo.habilitaEditarCadastro}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Col>
              )}
            {isFieldAvailable(
              'Documento_DataEmissaoVisto',
              camposParametrizados
            ) &&
              isEstrangeiro && (
                <Col md="6">
                  <Form.Label>Data de Emissão do Visto</Form.Label>
                  <Form.Control
                    name="vtDataEmissao"
                    ref={register()}
                    placeholder="__/__/____"
                    onInput={(e) => mascara(e.target, 'data')}
                    onBlur={(e) => isValidDate(e.target)}
                    disabled={!vinculo.habilitaEditarCadastro}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Col>
              )}
          </Row>
          {isEstrangeiro && <br />}
          {isEstrangeiro && <br />}

          {isFieldAvailable('Documento_RNE', camposParametrizados) &&
            isEstrangeiro && (
              <Row>
                <Col>
                  <br />
                  <h5>Registro Nacional de Estrangeiros - RNE</h5>
                </Col>
              </Row>
            )}

          <Row>
            {isFieldAvailable('Documento_RNE', camposParametrizados) &&
              isEstrangeiro && (
                <Col md="6">
                  <Form.Label>Nº RNE</Form.Label>
                  <Form.Control
                    name="numeroRNE"
                    ref={register()}
                    disabled={!vinculo.habilitaEditarCadastro}
                  />
                </Col>
              )}
            {isFieldAvailable(
              'Documento_OrgaoEmissorRNE',
              camposParametrizados
            ) &&
              isEstrangeiro && (
                <Col md="6">
                  <Form.Label>Órgão Emissor</Form.Label>
                  <Form.Control
                    name="orgaoEmissorRNE"
                    ref={register()}
                    disabled={!vinculo.habilitaEditarCadastro}
                  />
                </Col>
              )}
          </Row>
          {isEstrangeiro && <br />}
          <Row>
            {isFieldAvailable(
              'Documento_DataExpedicaoRNE',
              camposParametrizados
            ) &&
              isEstrangeiro && (
                <Col md="4">
                  <Form.Label>Data de Expedição</Form.Label>
                  <Form.Control
                    name="rneDataExpedicao"
                    ref={register()}
                    placeholder="__/__/____"
                    onInput={(e) => mascara(e.target, 'data')}
                    onBlur={(e) => isValidDate(e.target)}
                    disabled={!vinculo.habilitaEditarCadastro}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Col>
              )}
            {isFieldAvailable('Documento_DataRNE', camposParametrizados) &&
              isEstrangeiro && (
                <Col md="4">
                  <Form.Label>Data de Validade</Form.Label>
                  <Form.Control
                    name="rneDataValidade"
                    ref={register()}
                    placeholder="__/__/____"
                    onInput={(e) => mascara(e.target, 'data')}
                    onBlur={(e) => isValidDate(e.target)}
                    disabled={!vinculo.habilitaEditarCadastro}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Col>
              )}
            {isFieldAvailable('Documento_UfRNE', camposParametrizados) &&
              isEstrangeiro && (
                <>
                  <Col md="4">
                    <Form.Label>UF Expedição</Form.Label>
                    <Form.Control
                      name="ufRNE"
                      ref={register()}
                      as="select"
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="Escolha" />
                      {listaUf.map((uf) => (
                        <option key={uf.id} value={uf.id}>
                          {uf.descricao}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </>
              )}
          </Row>

          <br />
          <br />

          <h5>Anexos</h5>

          <Anexos idBlocoDados={4} />

          <br />
          <br />

          <Row>
            <Col md="4">
              <Button
                type="submit"
                variant="success"
                disabled={
                  !vinculo.habilitaEditarCadastro || loadingSalvarCadastro
                }
              >
                {loadingSalvarCadastro ? 'Aguarde...' : 'Salvar Cadastro'}
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </>
  );
}
