/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import { Container, Row, Form, Col, Button, Alert } from 'react-bootstrap';

import { equals, isStrongPassword, isEmail } from 'validator';

import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Wrapper, Footer } from './styles';
import { maskCpf, isValidCPF } from '../../utils/index';

import fiotec from '../../images/logo-fiotec.svg';
import epf from '../../images/logo-epf2.png';

import { api } from '../../services/api';

export default function PrimeiroAcesso() {
  const { key } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [dadosConvite, setDadosConvite] = useState({});
  const [cpf, setCpf] = useState('');

  const { handleSubmit, register, errors, reset } = useForm({
    mode: 'onBlur',
  });

  async function fetchDadosConvite() {
    try {
      const res = await api.get(`conviteapi/api/v1/PrimeiroAcesso/${key}`);

      if (res.data.result.cpf) {
        setCpf(res.data.result.cpf);
      }
      return setDadosConvite(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível identificar a chave do convite');
    }
  }

  function validarForcaSenha(senha) {
    if (
      isStrongPassword(senha, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      })
    ) {
      return true;
    }

    toast.error(
      'A senha precisa conter no mínimo 8 caracteres com pelo menos uma letra maiúscula, uma minúscula e um número.'
    );

    return false;
  }

  function validarRepeticaoSenha(senha, senhaConfirmacao) {
    if (equals(senha, senhaConfirmacao)) {
      return true;
    }

    toast.error('As senhas precisam ser iguais');

    return false;
  }

  function emailValido(email) {
    if (isEmail(email)) {
      return true;
    }

    toast.error('Informe um e-mail válido');

    return false;
  }

  async function handleCadastrar(data) {
    if (!isValidCPF(cpf)) {
      setCpf('');
      return toast.error('CPF inválido');
    }

    const model = {
      keyConvite: key,
      nome: data.nome,
      email: dadosConvite.email,
      cpf: dadosConvite.cpf ? dadosConvite.cpf : cpf,
      senha: data.senha,
    };

    if (
      emailValido(dadosConvite.email) &&
      validarForcaSenha(data.senha) &&
      validarRepeticaoSenha(data.senha, data.senhaConfirmacao)
    ) {
      try {
        setLoading(true);
        await api.post(`conviteapi/api/v1/PrimeiroAcesso/${key}`, model);
        return toast.success(
          'Cadastro realizado. Redirecionando para o login...',
          {
            autoClose: 3000,
            onClose: () => history.push(`/`),
          }
        );
      } catch (err) {
        setLoading(false);
        return toast.error('Falha ao realizar o cadastro');
      }
    }

    return '';
  }

  useEffect(() => {
    fetchDadosConvite();
  }, []);

  useEffect(() => {
    reset({
      nome: dadosConvite.nomeDestinatario,
      email: dadosConvite.email,
      cpf: dadosConvite.cpf,
    });
  }, [dadosConvite]);

  return (
    <>
      <Container className="login">
        <Row>
          <Wrapper>
            <div className="logo">
              <img src={fiotec} className="logoFiotec" alt="Logo Fiotec" />
              <br />
              <img src={epf} alt="Logo EPF 2" />
            </div>

            {dadosConvite.isUtilizado ? (
              <Alert
                variant="danger"
                style={{
                  marginTop: '30px',
                  lineHeight: '20px',
                  textAlign: 'center',
                }}
              >
                <>
                  <p>
                    O link do convite é inválido pois já foi utilizado antes.
                    Caso tenha cadastrado sua senha, <a href="/">clique aqui</a>{' '}
                    para fazer a sua autenticação. Se o processo de criação de
                    senha não foi realizado, solicite ao projeto o envio de um
                    novo convite.
                  </p>
                </>
              </Alert>
            ) : (
              <Form sm="6" onSubmit={handleSubmit(handleCadastrar)}>
                <Form.Group>
                  <Form.Label column sm="12">
                    Nome
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control
                      type="text"
                      name="nome"
                      ref={register({ required: true })}
                    />
                    <span className="errorValidation">
                      {errors.nome && 'Informe o nome'}
                    </span>
                  </Col>

                  <Form.Label column sm="12">
                    E-mail
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control
                      type="text"
                      name="email"
                      ref={register({ required: true })}
                      disabled
                    />
                  </Col>

                  <Form.Label column sm="12">
                    CPF
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control
                      type="text"
                      name="cpf"
                      ref={register({ required: true })}
                      onChange={(e) => setCpf(maskCpf(e.target.value))}
                      value={cpf}
                      disabled={dadosConvite.cpf}
                      maxLength="14"
                    />
                  </Col>

                  <Form.Label column sm="12">
                    Senha
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control
                      type="password"
                      name="senha"
                      ref={register({ required: true })}
                    />
                    <span className="errorValidation">
                      {errors.senha && 'Informe a senha'}
                    </span>
                  </Col>

                  <Form.Label column sm="12">
                    Confirme sua senha
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control
                      type="password"
                      name="senhaConfirmacao"
                      ref={register({ required: true })}
                    />
                    <span className="errorValidation">
                      {errors.senhaConfirmacao &&
                        'Informe a confirmação de senha'}
                    </span>
                  </Col>

                  <br />
                  <br />

                  <Col sm="12">
                    <Button
                      variant="primary"
                      type="submit"
                      block
                      disabled={loading}
                    >
                      {loading ? 'Aguarde...' : 'Cadastrar'}
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            )}
          </Wrapper>
        </Row>

        <Footer>
          <p>Fundação de apoio à Fiocruz - Fiotec</p>
          <p>Rio de Janeiro, Avenida Brasil, 4036, Manguinhos</p>
        </Footer>
      </Container>
    </>
  );
}
