/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import {
  Tab,
  OverlayTrigger,
  Tooltip,
  Nav,
  Row,
  Col,
  Spinner,
  Alert,
} from 'react-bootstrap';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';

import AcoesPendentes from './acoesPendentes';
import Novos from './novos';
import EmPreenchimento from './emPreenchimento';
import Validados from './validados';
import EmValidacao from './emValidacao';
import Excluidos from './excluidos';
import Vencidos from './vencidos';
import { api } from '../../services/api';

export default function Inicio() {
  const [totalAcoesPendentes, setTotalAcoesPendentes] = useState(false);
  const [totalNovos, setTotalNovos] = useState(false);
  const [totalEmPreenchimento, setTotalEmPreenchimento] = useState(false);
  const [totalEmValidacao, setTotalEmValidacao] = useState(false);
  const [totalValidados, setTotalValidados] = useState(false);
  const [totalExcluidos, setTotalExcluidos] = useState(false);
  const [totalVencidos, setTotalVencidos] = useState(false);
  const [cpfCripto, setCpfCripto] = useState('');

  function fetchTotal(total, aba) {
    if (aba === 'acoesPendentes') {
      setTotalAcoesPendentes(total);
    }

    if (aba === 'novos') {
      console.log(total);
      setTotalNovos(total);
    }

    if (aba === 'emPreenchimento') {
      console.log(total);
      setTotalEmPreenchimento(total);
    }

    if (aba === 'emValidacao') {
      setTotalEmValidacao(total);
    }

    if (aba === 'validados') {
      setTotalValidados(total);
    }

    if (aba === 'excluidos') {
      setTotalExcluidos(total);
    }

    if (aba === 'vencidos') {
      setTotalVencidos(total);
    }
  }

  function addStyle(aba, titulo, total) {
    if (aba.innerHTML === titulo) {
      setTimeout(() => {
        aba.setAttribute('data-total', total);
        if (total !== 0) {
          aba.classList.remove('alert-cinza-after');
          aba.classList.add('alert-after');
        } else {
          aba.classList.add('alert-cinza-after');
          aba.classList.remove('alert-after');
        }
      }, 1000);
    }
  }

  function styleTotal() {
    const abas = document.querySelectorAll('#inicio .nav-item a');

    for (let i = 0; i < abas.length; i++) {
      const aba = abas[i];

      addStyle(aba, 'Ações Pendentes', totalAcoesPendentes);
      addStyle(aba, 'Novos', totalNovos);
      addStyle(aba, 'Em Preenchimento', totalEmPreenchimento);
      addStyle(aba, 'Em Validação', totalEmValidacao);
      addStyle(aba, 'Validados', totalValidados);
      addStyle(aba, 'Vencidos', totalVencidos);
      addStyle(aba, 'Excluídos', totalExcluidos);
    }
  }

  useEffect(() => {
    styleTotal();
  }, [
    totalAcoesPendentes,
    totalNovos,
    totalEmPreenchimento,
    totalEmValidacao,
    totalValidados,
    totalVencidos,
    totalExcluidos,
  ]);

  async function fetchCpfCripto() {
    try {
      const res = await api.get(
        `cadastroapi/api/v1/Cadastros/cpf-criptografado`
      );
      setCpfCripto(res.data.result.cpfCriptografado);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchCpfCripto();
  }, []);

  const TooltipTopNavItem = ({ title, tooltipMessage, eventKey }) => (
    <OverlayTrigger
      key={`${eventKey}-top`}
      placement="top"
      overlay={<Tooltip id="tooltip-top">{tooltipMessage}</Tooltip>}
    >
      <Nav.Item className="nav nav-tabs">
        <Nav.Link eventKey={eventKey} className="nav-item nav-link">
          {title}
        </Nav.Link>
      </Nav.Item>
    </OverlayTrigger>
  );

  return (
    <>
      <h3>Início</h3>

      {cpfCripto !== '' && (
        <Alert variant="success">
          <BsFillExclamationTriangleFill />{' '}
          <a
            style={{ color: '#000' }}
            href={`http://servicos.fiotec.org.br/InformeRendimentos/InformeEsp.aspx?k=${cpfCripto}`}
            target="_blank"
            rel="noreferrer"
          >
            Informe de rendimentos disponível, clique aqui para acessar.
          </a>
        </Alert>
      )}

      <h5>Meus Cadastros</h5>

      <div id="inicio">
        <Tab.Container
          defaultActiveKey="Ações Pendentes"
          onSelect={() => styleTotal()}
        >
          <Row>
            <Col sm={12}>
              <Nav>
                <TooltipTopNavItem
                  title={
                    totalAcoesPendentes === false ? (
                      <>
                        Ações Pendentes <Spinner animation="border" size="sm" />
                      </>
                    ) : (
                      'Ações Pendentes'
                    )
                  }
                  tooltipMessage="Cadastros devolvidos para ajustes."
                  eventKey="Ações Pendentes"
                />

                <TooltipTopNavItem
                  title={
                    totalNovos === false ? (
                      <>
                        Novos <Spinner animation="border" size="sm" />
                      </>
                    ) : (
                      'Novos'
                    )
                  }
                  tooltipMessage="Cadastros não iniciados."
                  eventKey="Novos"
                />

                <TooltipTopNavItem
                  title={
                    totalEmPreenchimento === false ? (
                      <>
                        Em Preenchimento{' '}
                        <Spinner animation="border" size="sm" />
                      </>
                    ) : (
                      'Em Preenchimento'
                    )
                  }
                  tooltipMessage="Cadastros salvos como rascunho."
                  eventKey="Em Preenchimento"
                />

                <TooltipTopNavItem
                  title={
                    totalEmValidacao === false ? (
                      <>
                        Em Validação <Spinner animation="border" size="sm" />
                      </>
                    ) : (
                      'Em Validação'
                    )
                  }
                  tooltipMessage="Cadastros concluídos e enviados para validação da Fiotec."
                  eventKey="Em Validação"
                />

                <TooltipTopNavItem
                  title={
                    totalValidados === false ? (
                      <>
                        Validados <Spinner animation="border" size="sm" />
                      </>
                    ) : (
                      'Validados'
                    )
                  }
                  tooltipMessage="Cadastros concluídos e validados pela Fiotec. Clique aqui para realizar seus serviços"
                  eventKey="Validados"
                />

                <TooltipTopNavItem
                  title={
                    totalVencidos === false ? (
                      <>
                        Vencidos <Spinner animation="border" size="sm" />
                      </>
                    ) : (
                      'Vencidos'
                    )
                  }
                  tooltipMessage="Cadastros vencidos."
                  eventKey="Vencidos"
                />

                <TooltipTopNavItem
                  title={
                    totalExcluidos === false ? (
                      <>
                        Excluídos <Spinner animation="border" size="sm" />
                      </>
                    ) : (
                      'Excluídos'
                    )
                  }
                  tooltipMessage="Cadastros excluídos."
                  eventKey="Excluídos"
                />
              </Nav>
            </Col>

            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="Ações Pendentes">
                  <AcoesPendentes fetchTotal={fetchTotal} />
                </Tab.Pane>
                <Tab.Pane eventKey="Novos">
                  <Novos fetchTotal={fetchTotal} />
                </Tab.Pane>
                <Tab.Pane eventKey="Em Preenchimento">
                  <EmPreenchimento fetchTotal={fetchTotal} />
                </Tab.Pane>
                <Tab.Pane eventKey="Em Validação">
                  <EmValidacao fetchTotal={fetchTotal} />
                </Tab.Pane>
                <Tab.Pane eventKey="Validados">
                  <Validados fetchTotal={fetchTotal} />
                </Tab.Pane>
                <Tab.Pane eventKey="Vencidos">
                  <Vencidos fetchTotal={fetchTotal} />
                </Tab.Pane>
                <Tab.Pane eventKey="Excluídos">
                  <Excluidos fetchTotal={fetchTotal} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
}
