/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Alert, ListGroup } from 'react-bootstrap';
import {
  BsFillExclamationTriangleFill,
  BsExclamationCircle,
} from 'react-icons/bs';
import { toast } from 'react-toastify';

import AvisoCadastro from '../../../components/AvisoCadastro';
import LoadingPage from '../../../components/LoadingPage';

import { useVinculo } from '../../../contexts/vinculo';

import { api } from '../../../services/api';

export default function EnviarValidacao() {
  const key = localStorage.getItem('@EPF2:keyTitular');
  const { ContextTitularResumo, vinculo } = useVinculo();

  const [errors, setErrors] = useState([]);
  const [autorizacoes, setAutorizacoes] = useState([]);
  const [botaoEnviar, setBotaoEnviar] = useState(false);
  const [erroApi, setErroApi] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [checkbox, setCheckbox] = useState(false);

  const history = useHistory();

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function validar() {
    try {
      const res = await api.get(
        `/cadastroapi/api/v1/Cadastros/${key}/criticar-finalizacao`
      );
      setErrors(res.data.result);
    } catch (err) {
      setErroApi(true);
      toast.error('Não foi possível verificar as pendencias');
    }
  }

  function handleAutorizarInformacoes(
    idTipoTratamentoDados,
    autorizado,
    isDependeAutorizacao,
    descricao
  ) {
    if (autorizacoes.length >= 1) {
      const atualizacao = autorizacoes.map((autorizacao) =>
        autorizacao.idTipoTratamentoDados === idTipoTratamentoDados
          ? {
              idTipoTratamentoDados,
              autorizado,
              descricao,
              keyTitularDados: key,
              isDependeAutorizacao,
            }
          : autorizacao
      );

      setAutorizacoes(atualizacao);
    } else {
      setAutorizacoes((prevState) => [
        ...prevState,
        {
          idTipoTratamentoDados,
          autorizado,
          descricao,
          keyTitularDados: key,
          isDependeAutorizacao,
        },
      ]);
    }
  }

  async function handleEnviarParaValidacao() {
    // Define a prop autorizado pra true sempre que isDependenteAutorizacao for false

    const atualizacao = autorizacoes.map((autorizacao) =>
      autorizacao.isDependeAutorizacao === false
        ? {
            idTipoTratamentoDados: autorizacao.idTipoTratamentoDados,
            autorizado: true,
            keyTitularDados: autorizacao.keyTitularDados,
            isDependeAutorizacao: autorizacao.isDependeAutorizacao,
          }
        : autorizacao
    );

    try {
      await api.patch(`cadastroapi/api/v1/Cadastros/${key}`, atualizacao);
      history.push('/inicio');
      return toast.success('Enviado com sucesso');
    } catch (err) {
      return toast.error('Não foi possível enviar');
    }
  }

  useEffect(async () => {
    setLoadingPage(true);
    await ContextTitularResumo(key);
    await validar();
    setLoadingPage(false);
  }, []);

  useEffect(async () => {
    if (vinculo.tratamentosDados) {
      setAutorizacoes(vinculo.tratamentosDados);
    }
  }, [vinculo]);

  // Verifica se todas autorizações foram concedidas para habilitar o Enviar.
  useEffect(() => {
    // Verifica se o usuário autorizou as que são obrigatorias
    const existePendencia = autorizacoes.filter(
      (autorizacao) =>
        (autorizacao.isDependeAutorizacao === true &&
          autorizacao.autorizado === false) ||
        (autorizacao.isDependeAutorizacao === true &&
          autorizacao.autorizado === null)
    );

    if (existePendencia.length === 0) {
      setBotaoEnviar(true);
    } else {
      setBotaoEnviar(false);
    }
  }, [autorizacoes]);

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      {vinculo.mensagemEdicaoCadastro && (
        <AvisoCadastro msg={vinculo.mensagemEdicaoCadastro} />
      )}

      <h3>Enviar para Validação</h3>
      {errors?.length >= 1 && (
        <Alert variant="info">
          <>
            <p>
              <BsFillExclamationTriangleFill /> O cadastro não pode ser enviado
              para validação por existirem dados inconsistentes.
            </p>
          </>
        </Alert>
      )}

      {errors?.map((error) => (
        <div key={error.etapa}>
          <h6>
            <strong>{error.etapa}</strong>
          </h6>
          <ul>
            {error.mensagens.map((mensagem) => (
              <li>
                <BsExclamationCircle key={mensagem.mensagem} />{' '}
                {mensagem.mensagem}
              </li>
            ))}
          </ul>
          <br />
        </div>
      ))}

      {!errors.length && (
        <>
          <p>
            Para o pagamento financeiro será necessário autorização para
            utilizar as seguintes informações pessoais do seu cadastro
          </p>
          <ListGroup>
            {autorizacoes?.map((tratamento) => (
              <ListGroup.Item key={tratamento.idTipoTratamentoDados}>
                <Row>
                  <>
                    <Col md="9">{tratamento.descricao}</Col>
                    <Col md="3">
                      {tratamento.isDependeAutorizacao ? (
                        <>
                          {tratamento.autorizado ? (
                            <Button
                              variant="warning"
                              size="sm"
                              disabled={!vinculo.habilitaEditarCadastro}
                              onClick={() => {
                                handleAutorizarInformacoes(
                                  tratamento.idTipoTratamentoDados,
                                  false,
                                  tratamento.isDependeAutorizacao,
                                  tratamento.descricao
                                );
                              }}
                            >
                              Desfazer Autorização
                            </Button>
                          ) : (
                            <Button
                              variant="success"
                              size="sm"
                              disabled={!vinculo.habilitaEditarCadastro}
                              onClick={() => {
                                handleAutorizarInformacoes(
                                  tratamento.idTipoTratamentoDados,
                                  true,
                                  tratamento.isDependeAutorizacao,
                                  tratamento.descricao
                                );
                              }}
                            >
                              Autorizar
                            </Button>
                          )}
                        </>
                      ) : (
                        'Obrigação legal / normativa'
                      )}
                    </Col>
                  </>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <br />
          <p>
            <input
              type="checkbox"
              defaultChecked={!vinculo.habilitaEditarCadastro}
              onChange={() => setCheckbox(!checkbox)}
              disabled={!vinculo.habilitaEditarCadastro}
            />{' '}
            Estou ciente de que a veracidade dessas informações são de minha
            responsabilidade, sob as penas da legislação vigente.
          </p>
          <br />
          <br />
          <Button
            variant={!botaoEnviar ? 'secondary' : 'success'}
            disabled={
              !botaoEnviar ||
              !checkbox ||
              erroApi ||
              !vinculo.habilitaEditarCadastro
            }
            onClick={() => handleEnviarParaValidacao()}
          >
            Enviar
          </Button>
        </>
      )}
    </>
  );
}
