import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Table from '../../../../components/Table';
import { api } from '../../../../services/api';
import LoadingPage from '../../../../components/LoadingPage';
import AvisoCadastro from '../../../../components/AvisoCadastro';

export default function DocumentosHistoricoSolicitacoes() {
  const [listaHistoricoSolicitacoes, setListaHistoricoSolicitacoes] = useState(
    []
  );
  const [loadingPage, setLoadingPage] = useState(false);

  const key = localStorage.getItem('@EPF2:keyTitular');

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchHistoricoSolicitacoes() {
    try {
      const res = await api.get(`consultaAPI/documentos/${key}`);
      return setListaHistoricoSolicitacoes(res.data.result);
    } catch (error) {
      return toast.error('Não foi possível exibir a lista de históricos');
    }
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchHistoricoSolicitacoes();
    setLoadingPage(false);
  }, []);

  const columnsHistoricoDeSolicitacoes = [
    {
      id: 'numeroContrato',
      Header: 'Número Contrato',
      accessor: 'numeroContrato',
    },
    {
      id: 'dataEnvio',
      Header: 'Data da Solicitação',
      accessor: 'dataEnvio',
    },
    {
      id: 'tipoDocumento',
      Header: 'Tipo de Documento',
      accessor: 'tipoDocumento',
    },
    {
      id: 'projeto',
      Header: 'Projeto',
      accessor: 'projeto',
    },
    {
      id: 'subProjeto',
      Header: 'SubProjeto',
      accessor: 'subProjeto',
    },
  ];

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Histórico de Solicitações de Documentos</h3>
      <br />
      <Table
        columns={columnsHistoricoDeSolicitacoes}
        data={listaHistoricoSolicitacoes}
      />
    </>
  );
}
