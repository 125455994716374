/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { Navbar, Container, Row, Col } from 'react-bootstrap';

import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import OutsideClickHandler from 'react-outside-click-handler';
import epf from '../../images/logo-epf2.png';

import Menu from '../../components/Menu';

import { Wrapper, Footer, SectionContent, Profile } from './styles';

import ProfileDetalhes from '../../components/Profile';

import TipoDeCadastro from '../../components/TipoDeCadastro';

export default function LayoutDefault({ children }) {
  const [showUser, setShowUser] = useState(false);

  const usuario = JSON.parse(localStorage.getItem('@EPF2Auth:user'));

  const location = useLocation();

  return (
    <Wrapper>
      <>
        <Navbar bg="light">
          <Container fluid>
            <Navbar.Brand href="/">
              <img src={epf} alt="EPF 2" />
            </Navbar.Brand>

            <Profile aria-hidden="true" onClick={() => setShowUser(!showUser)}>
              <img
                src={`${process.env.REACT_APP_API_SEG}/FotoUsuario/ObterPorId/${usuario.IdUsuario}`}
                alt="Foto usuário logado"
                width="45"
                className={showUser ? 'ativo' : 'inativo'}
              />
              {showUser ? <FaAngleUp /> : <FaAngleDown />}

              {showUser && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowUser(false);
                  }}
                >
                  <ProfileDetalhes />
                </OutsideClickHandler>
              )}
            </Profile>
          </Container>
        </Navbar>

        <Container fluid>
          <Row>
            <>
              {location.pathname === '/inicio' ? (
                <Col md={12} className="content">
                  <SectionContent>{children}</SectionContent>
                </Col>
              ) : (
                <>
                  <Col md={2} className="menu">
                    <Menu />
                  </Col>

                  <Col md={10} className="content">
                    <SectionContent>
                      <TipoDeCadastro />
                      {children}
                    </SectionContent>
                  </Col>
                </>
              )}
            </>
          </Row>
        </Container>

        <Footer>
          <Container fluid>
            <ul>
              <li>
                <a
                  href="https://www.fiotec.fiocruz.br/wikiepf/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Central de Ajuda (Wiki)
                </a>
              </li>
            </ul>
          </Container>
        </Footer>
      </>
    </Wrapper>
  );
}

LayoutDefault.propTypes = {
  children: PropTypes.element.isRequired,
};
