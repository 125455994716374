/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useVinculo } from '../../../../contexts/vinculo';
import { api } from '../../../../services/api';
import LoadingPage from '../../../../components/LoadingPage';
import AvisoCadastro from '../../../../components/AvisoCadastro';
import Table from '../../../../components/Table';

export default function DocumentosSolicitarDeclaracoes() {
  const [listaBolsas, setListaBolsas] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loading, setLoading] = useState(false);

  const key = localStorage.getItem('@EPF2:keyTitular');
  const { vinculo } = useVinculo();

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchDados() {
    try {
      const res = await api.get(
        `/ConsultaAPI/consultas/contratos/${key}/relatorio-bolsa`
      );
      return setListaBolsas(res.data.result);
    } catch (error) {
      return toast.error('Não foi possível exibir a lista de contratos');
    }
  }

  async function handleSolicitar(keyContrato) {
    const dadosDeclaracao = {
      idTitular: vinculo.id,
      keyContrato,
    };
    setLoading(true);
    try {
      await api.post(
        'SolicitacaoAPI/api/Documentos/bolsa-declaracao',
        dadosDeclaracao
      );
      setLoading(false);
      return toast.success(
        'Solicitação feita com sucesso. Aguarde o retorno por e-mail.'
      );
    } catch (err) {
      setLoading(false);
      return toast.error('Não foi possível solicitar');
    }
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchDados(fetchDados);
    setLoadingPage(false);
  }, []);

  const columnsListaBolsasTitularRelatorio = [
    {
      id: 'numeroContrato',
      Header: 'Contrato',
      accessor: 'numeroContrato',
    },
    {
      id: 'projeto',
      Header: 'Projeto',
      accessor: 'projeto',
    },
    {
      id: 'vigencia',
      Header: 'Vigência',
      accessor: 'vigencia',
    },
    {
      id: 'valor',
      Header: 'Valor',
      accessor: 'valor',
    },
    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          <Button
            variant="primary"
            size="sm"
            disabled={loading}
            onClick={() => handleSolicitar(rows.row.original.keyContrato)}
          >
            Solicitar
          </Button>
        </>
      ),
    },
  ];

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Solicitar declaração</h3>
      <br />

      <Table columns={columnsListaBolsasTitularRelatorio} data={listaBolsas} />
    </>
  );
}
