import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { api } from '../../../services/api';
import Anexos from '../../../components/Anexos';
import { isFieldAvailable } from '../../../utils';

import AvisoCadastro from '../../../components/AvisoCadastro';
import LoadingPage from '../../../components/LoadingPage';

import { useParametrizacao } from '../../../contexts/parametrizacao';
import { useVinculo } from '../../../contexts/vinculo';
import { useEtapasVinculo } from '../../../contexts/etapasVinculo';

export default function InformacoesComplementares() {
  const [camposParametrizados, setCamposParametrizados] = useState([]);
  const [listaInfoComplementar, setListaInfoComplementar] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSalvarCadastro, setLoadingSalvarCadastro] = useState(false);
  const [camposDesabilitados, setCamposDesabilitados] = useState(false);

  const { handleSubmit, register } = useForm({
    mode: 'onBlur',
  });

  const key = localStorage.getItem('@EPF2:keyTitular');
  const { parametrizacao } = useParametrizacao();
  const { vinculo } = useVinculo();
  const { etapasVinculo } = useEtapasVinculo();

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchInformacoesComplementares() {
    try {
      const res = await api.get(
        `cadastroapi/api/v1/InformacoesComplementares/${key}`
      );
      setListaInfoComplementar(res.data.result);
    } catch (error) {
      return toast.error('Não foi possível carregar as informações.');
    }
    return '';
  }

  async function handleInformacaoComplementar(data) {
    try {
      setLoadingSalvarCadastro(true);

      await api.put(`cadastroapi/api/v1/InformacoesComplementares`, {
        keyTitularDados: key,
        curriculoLattes: data.curriculoLatte,
        perfilProfissional: data.perfilProfissional,
        informacoesComplementares: data.informacoesComplementares,
      });
      setLoadingSalvarCadastro(false);

      toast.success('Cadastro salvo.');
      fetchInformacoesComplementares();
      return fetchInformacoesComplementares();
    } catch (err) {
      setLoadingSalvarCadastro(false);

      return toast.error('Não foi possível salvar a informação.');
    }
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchInformacoesComplementares();
    setLoadingPage(false);
  }, []);

  useEffect(() => {
    setCamposParametrizados(parametrizacao[1]?.campos);
    const campos = parametrizacao[1]?.campos.every(
      (field) => field.isObrigatorio === false
    );
    setCamposDesabilitados(campos);
  }, [parametrizacao]);

  if (vinculo.idCadastroStatus === 6) {
    return (
      <AvisoCadastro msg="Esse vínculo foi excluído, por esse motivo essa etapa não pode ser acessada." />
    );
  }

  if (etapasVinculo?.blocos?.[8].isAtivo && camposDesabilitados) {
    return <AvisoCadastro msg="Nenhum campo habilitado para preenchimento." />;
  }

  if (etapasVinculo?.blocos && !etapasVinculo?.blocos?.[8].isAtivo) {
    return (
      <AvisoCadastro msg="Essa etapa está desabilitada para esse vínculo." />
    );
  }

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Informações Complementares</h3>

      {vinculo.mensagemEdicaoCadastro && (
        <AvisoCadastro msg={vinculo.mensagemEdicaoCadastro} vinculo={vinculo} />
      )}

      <Form onSubmit={handleSubmit(handleInformacaoComplementar)}>
        <Form.Group>
          <Row>
            {isFieldAvailable(
              'InformacaoComplementar_CurriculoLattes',
              camposParametrizados
            ) && (
              <Col md="12">
                <Form.Label>Currículo Lattes(se houver)</Form.Label>
                <Form.Control
                  name="curriculoLatte"
                  ref={register()}
                  defaultValue={
                    listaInfoComplementar &&
                    listaInfoComplementar.curriculoLattes
                  }
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
          </Row>

          <br />
          <Row>
            {isFieldAvailable(
              'InformacaoComplementar_PerfilProfissional',
              camposParametrizados
            ) && (
              <Col md="12">
                <Form.Label>Perfil Profissional</Form.Label>
                <Form.Control
                  name="perfilProfissional"
                  as="textarea"
                  rows={5}
                  ref={register()}
                  defaultValue={
                    listaInfoComplementar &&
                    listaInfoComplementar.perfilProfissional
                  }
                  disabled={!vinculo.habilitaEditarCadastro}
                />
              </Col>
            )}
          </Row>
          <br />
          <Row>
            {isFieldAvailable(
              'InformacaoComplementar_InformacoesComplementares',
              camposParametrizados
            ) && (
              <Col md="12">
                <Form.Label>Informações Complementares</Form.Label>
                <Form.Control
                  name="informacoesComplementares"
                  as="textarea"
                  rows={5}
                  ref={register()}
                  defaultValue={
                    listaInfoComplementar &&
                    listaInfoComplementar.informacoesComplementares
                  }
                  disabled={!vinculo.habilitaEditarCadastro}
                />
              </Col>
            )}
          </Row>
          <br />
          <br />
          <h5>Anexos</h5>
          <Anexos idBlocoDados={9} />
          <br />
          <br />

          <Row>
            <Col md="4">
              <Button
                type="submit"
                variant="success"
                disabled={
                  !vinculo.habilitaEditarCadastro || loadingSalvarCadastro
                }
              >
                {loadingSalvarCadastro ? 'Aguarde...' : 'Salvar Cadastro'}
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </>
  );
}
