/* eslint-disable react/prop-types */
import React, { createContext, useState, useContext } from 'react';
import { api } from '../services/api';

const EtapasVinculoContext = createContext({});

export function useEtapasVinculo() {
  const context = useContext(EtapasVinculoContext);
  return context;
}

export function EtapasVinculoProvider({ children }) {
  const [etapasVinculo, setEtapasVinculo] = useState({});

  async function ContextEtapasVinculo(keyTitular) {
    try {
      const res = await api.get(
        `parametrizacaoAPI/api/v1/VisibilidadeBlocoDados/${keyTitular}`
      );
      setEtapasVinculo(res.data.result[0]);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <EtapasVinculoContext.Provider
      value={{
        etapasVinculo,
        ContextEtapasVinculo,
      }}
    >
      {children}
    </EtapasVinculoContext.Provider>
  );
}

export default { useEtapasVinculo };
