/* eslint-disable react/prop-types */
import React, { createContext, useState, useContext } from 'react';
import { api } from '../services/api';

const ParametrizacaoContext = createContext({});

export function useParametrizacao() {
  const context = useContext(ParametrizacaoContext);
  return context;
}

export function ParametrizacaoProvider({ children }) {
  const [parametrizacao, setParametrizacao] = useState({});

  async function ContextParametrizacao(idTitularTipo) {
    try {
      const res = await api.get(
        `/ParametrizacaoAPI/api/v1/VisibilidadeCampos/${idTitularTipo}`
      );
      setParametrizacao(res.data.result);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <ParametrizacaoContext.Provider
      value={{
        parametrizacao,
        ContextParametrizacao,
      }}
    >
      {children}
    </ParametrizacaoContext.Provider>
  );
}

export default { useParametrizacao };
