/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */

import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button, Accordion, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { parseISO, format } from 'date-fns';

import { toast } from 'react-toastify';
import uniqid from 'uniqid';
import filesize from 'filesize';
import Upload from '../../../../components/Upload';
import FileList from '../../../../components/FileList';
import InformacoesProjeto from '../../../../components/informacoesProjeto';
import Table from '../../../../components/Table';
import Confirm from '../../../../components/Confirm';
import LoadingPage from '../../../../components/LoadingPage';
import AvisoCadastro from '../../../../components/AvisoCadastro';

import { api, apiBolsas } from '../../../../services/api';

export default function IncluirRelatorio() {
  const { handleSubmit, register, reset, errors } = useForm({
    mode: 'onBlur',
  });

  const key = localStorage.getItem('@EPF2:keyTitular');
  const { keySolicitacao } = useParams();
  const { state: acao } = useLocation();
  const history = useHistory();

  const [relatorio, setRelatorio] = useState({});
  const [atividades, setAtividades] = useState([]);
  const [finalidades, setFinalidades] = useState([]);
  const [parcelas, setParcelas] = useState([]);
  const [listaAnexos, setListaAnexos] = useState([]);
  const [listaHistorico, setListaHistorico] = useState([]);

  const [anexosUpload, setAnexosUpload] = useState([]);
  const [parcela, setParcela] = useState(null);
  const [finalidade, setFinalidade] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingRascunho, setLoadingRascunho] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [selectParcela, setSelectParcela] = useState(true);
  const [contadorTexto, setContadorTexto] = useState({});
  const [contadorComentario, setContadorComentario] = useState({});

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchRelatorio() {
    const res = await api.get(
      `/ConsultaAPI/relatorios-bolsas/${keySolicitacao}/detalhes `
    );
    setRelatorio(res.data.result);
    setAtividades(res.data.result.atividades);
    setListaAnexos(res.data.result.listaAnexo);
    setListaHistorico(res.data.result.listaHistorico);
  }

  async function fetchFinalidades() {
    const res = await api.get(
      `/DicionarioAPI/api/v1/Dicionarios/tipos-finalidade`
    );
    setFinalidades(res.data.result);
  }

  async function fetchParcelas(idContrato) {
    try {
      const res = await apiBolsas.get(
        `RelatoriosAPI/api/v1/Relatorios/${idContrato}/parcelas-disponiveis`
      );
      setParcelas(res.data.result);
    } catch (err) {
      toast.error('Não foi possível carregar as parcelas.');
    }
  }

  function handleUpload(files) {
    files.map((file) =>
      setAnexosUpload([
        ...anexosUpload,
        {
          file,
          id: uniqid(),
          name: file.name,
          readableSize: filesize(file.size),
        },
      ])
    );
  }

  async function handleDeleteAnexo(fileId) {
    const newAnexos = anexosUpload.filter((anexo) => anexo.id !== fileId);
    setAnexosUpload(newAnexos);
  }

  async function handleExcluirAnexoGrid(key) {
    try {
      await api.delete(`solicitacaoapi/relatorios-bolsas/${key}/exclui-anexo`);
      toast.success('Excluído com sucesso.');
      return fetchRelatorio();
    } catch (err) {
      toast.error('Não foi possível excluir.');
      return fetchRelatorio();
    }
  }

  async function handleSalvarRelatorio(data, enviaAprovacao) {
    if (!data && !enviaAprovacao) {
      return toast.error('Não foi possível salvar como rascunho.');
    }

    if (!data && enviaAprovacao) {
      return toast.error('Não foi possível salvar e enviar.');
    }

    if (!finalidade || finalidade === '0') {
      return toast.error('Escolha a finalidade.');
    }

    if (finalidade === '1' && parcela === null) {
      return toast.error('Escolha a parcela');
    }

    if (finalidade === '1' && parcela === '') {
      return toast.error('Escolha a parcela');
    }

    delete data.finalidade;
    delete data.parcela;

    const array = Object.values(data);

    const atividades = array.map((obj) => ({
      id: obj.id,
      comentario: obj.comentario,
      produtos: [...obj.produtos.filter((item) => item)],
    }));

    if (atividades.length <= 0) {
      return toast.error('Nenhuma atividade enviada.');
    }

    const relatorioPronto = {
      keySolicitacao,
      keyTitularDados: key,
      codigoFinalidade: finalidade,
      dataCompetencia: finalidade === '5' ? parcela : null,
      enviaAprovacao,
      atividades,
    };

    const dataForm = new FormData();
    const config = {
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    dataForm.append('model', JSON.stringify(relatorioPronto));

    anexosUpload.forEach((a) => {
      dataForm.append(`Anexos`, a.file);
    });

    if (enviaAprovacao) {
      const result = await Confirm.show({
        title: 'Confirmação',
        message:
          'Tem certeza que deseja enviar o relatório? Após o envio você não poderá alterar até o retorno da análise.',
      });

      if (result) {
        try {
          setLoading(true);

          await api.put(
            '/solicitacaoAPI/relatorios-bolsas/atualizar',
            dataForm,
            config
          );

          setLoading(false);
          setAnexosUpload([]);
          fetchRelatorio();

          toast.success('Relatório salvo e enviado.');
          return history.push('../historico');
        } catch (err) {
          setLoading(false);
          return toast.error('Não foi possível salvar e enviar.');
        }
      }
    } else {
      try {
        setLoadingRascunho(true);

        await api.put(
          '/solicitacaoAPI/relatorios-bolsas/atualizar',
          dataForm,
          config
        );

        setLoadingRascunho(false);
        setAnexosUpload([]);
        fetchRelatorio();

        toast.success('Relatório salvo como rascunho.');
      } catch (err) {
        setLoadingRascunho(false);
        return toast.error('Não foi possível salvar.');
      }
    }

    return '';
  }

  useEffect(() => {
    if (!acao) {
      throw new Error('Ação inválida');
    }

    if (acao === 'Visualizar') {
      setReadOnly(true);
    }

    return '';
  }, []);

  useEffect(async () => {
    setLoadingPage(true);
    await fetchRelatorio();
    await fetchFinalidades();
    setLoadingPage(false);
  }, []);

  useEffect(() => {
    if (relatorio.idContrato) {
      fetchParcelas(relatorio.idContrato);
    }
  }, [relatorio]);

  useEffect(() => {
    reset({
      finalidade: String(relatorio.codigoFinalidade),
      parcela: relatorio.parcela,
    });

    setFinalidade(String(relatorio.codigoFinalidade));
    setParcela(relatorio.parcela);
  }, [finalidades, relatorio, parcelas]);

  useEffect(() => {
    if (relatorio.parcela) {
      const parcelaExiste = !!parcelas.find(
        (parcela) => parcela.dataCompetencia === relatorio.parcela
      );

      setSelectParcela(parcelaExiste);
    }
  }, [relatorio, parcelas]);

  const columnsAnexos = [
    {
      Header: 'ARQUIVO',
      accessor: 'nomeArquivo',
      width: 200,
      Cell: (rows) => (
        <>
          <a href={`${rows.row.original.urlDownload}`}>
            {rows.row.original.nomeArquivo}
          </a>
        </>
      ),
      disableFilters: true,
    },
    {
      Header: 'Ações',
      Cell: (rows) =>
        !readOnly ? (
          <Button
            variant="danger"
            size="sm"
            onClick={() =>
              handleExcluirAnexoGrid(rows.row.original.codDownload)
            }
          >
            Excluir
          </Button>
        ) : (
          ''
        ),
    },
  ];

  const columnsHistorico = [
    {
      id: 'usuario',
      Header: 'Usuário',
      accessor: 'usuario',
    },
    {
      id: 'dataInclusao',
      Header: 'Data',
      accessor: 'dataInclusao',
    },
    {
      id: 'status',
      Header: 'Status do Documento',
      accessor: 'status',
    },
    {
      id: 'observacao',
      Header: 'Observação',
      accessor: 'observacao',
    },
  ];

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Relatório de Bolsa</h3>

      <p>
        <Form.Label>Solicitação</Form.Label>: {relatorio.numeracaoUnica}
      </p>

      <InformacoesProjeto keyContrato={relatorio.keyContrato} />

      <Row>
        <Col md="4">
          <Form.Label>Finalidade</Form.Label>
          <Form.Control
            name="finalidade"
            disabled={readOnly}
            as="select"
            ref={register()}
            onChange={(e) => setFinalidade(e.target.value)}
            defaultValue="3"
          >
            <option aria-label="Escolha" />
            {finalidades.map((finalidade) => (
              <option value={finalidade.id}>{finalidade.descricao}</option>
            ))}
          </Form.Control>
        </Col>

        {finalidade === '5' && (
          <Col md="4">
            <Form.Label>Relatório</Form.Label>
            {selectParcela ? (
              <Form.Control
                name="parcela"
                disabled={readOnly}
                as="select"
                ref={register({ required: true })}
                onChange={(e) => setParcela(e.target.value)}
              >
                <option aria-label="Escolha" />
                {parcelas.map((parcela) => (
                  <option value={parcela.dataCompetencia}>
                    {format(parseISO(parcela.dataCompetencia), 'dd/MM/yyyy', {
                      timeZone: 'America/Sao_Paulo',
                    })}{' '}
                    -{' '}
                    {parcela.isObrigatorio
                      ? 'Parcela Obrigatória'
                      : 'Parcela Opcional'}
                  </option>
                ))}
              </Form.Control>
            ) : (
              <Form.Control
                name="parcela"
                disabled
                value={
                  relatorio?.parcela !== null &&
                  format(parseISO(relatorio.parcela), 'dd/MM/yyyy', {
                    timeZone: 'America/Sao_Paulo',
                  })
                }
              />
            )}
            <span className="errorValidation">
              {errors.parcela && 'Selecione a Parcela'}
            </span>
          </Col>
        )}
      </Row>

      <br />
      <br />

      <h5>Atividades do Bolsista</h5>

      <Form>
        <Accordion defaultActiveKey="1">
          {atividades?.length <= 0 && <p>Nenhuma atividade cadastrada.</p>}

          {atividades?.map((atividade, index) => (
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey={atividade.id}
                  style={{ padding: '10px 0' }}
                >
                  Atividade {index + 1}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={atividade.id}>
                <Card.Body>
                  <Row>
                    <Col md="12">
                      <strong>Descrição da Atividade</strong>
                      <p style={{ marginTop: '10px' }}>
                        {atividade.descricao || 'Nenhuma descrição encontrada.'}
                      </p>

                      <Form.Control
                        name={`${atividade.id}.id`}
                        value={`${atividade.id}`}
                        style={{ display: 'none' }}
                      />
                    </Col>
                  </Row>

                  <br />

                  {atividade.produtos.map((prod, prodIndex) => (
                    <>
                      <Row
                        style={{
                          padding: '20px 10px',
                          margin: '0 0 30px 0',
                          background: '#F7F7F7',
                          marginBottom: '15px',
                          borderRadius: '5px',
                        }}
                      >
                        <Col md="12" style={{ marginBottom: '20px' }}>
                          <strong>Produto/Entrega</strong>
                          <p style={{ marginTop: '10px' }}>
                            {prod.descricao || 'Nenhuma descrição encontrada.'}
                          </p>

                          <Form.Control
                            name={`${atividade.id}.produtos.${prod.id}.id`}
                            value={`${prod.id}`}
                            ref={register()}
                            style={{ display: 'none' }}
                          />
                        </Col>

                        <Col md="3">
                          <strong>Status</strong>
                          <p style={{ marginTop: '10px' }}>
                            <Form.Check
                              ref={register()}
                              type="radio"
                              value="não iniciado"
                              name={`${atividade.id}.produtos.${prod.id}.situacao`}
                              label="Não iniciado"
                              defaultChecked={prod.situacao === 'Não iniciado'}
                              disabled={readOnly}
                            />
                            <Form.Check
                              ref={register()}
                              type="radio"
                              value="em andamento"
                              name={`${atividade.id}.produtos.${prod.id}.situacao`}
                              label="Em Andamento"
                              defaultChecked={prod.situacao === 'Em Andamento'}
                              disabled={readOnly}
                            />
                            <Form.Check
                              ref={register()}
                              type="radio"
                              value="concluido"
                              name={`${atividade.id}.produtos.${prod.id}.situacao`}
                              label="Concluído"
                              defaultChecked={prod.situacao === 'Concluido'}
                              disabled={readOnly}
                            />
                          </p>
                        </Col>

                        <Col md="9">
                          <strong>Detalhamento</strong>
                          <p style={{ marginTop: '10px' }}>
                            <Form.Control
                              name={`${atividade.id}.produtos.${prod.id}.detalhamento`}
                              as="textarea"
                              rows={7}
                              ref={register()}
                              maxLength="4000"
                              defaultValue={prod.detalhamento}
                              disabled={readOnly}
                              onChange={(e) => {
                                setContadorTexto({
                                  ...contadorTexto,
                                  [`atividade${index}produto${prodIndex}`]: {
                                    detalhamento: e.target.value,
                                  },
                                });
                              }}
                            />
                          </p>

                          <small>
                            Você pode digitar mais{' '}
                            <b>
                              {4000 -
                                (contadorTexto[
                                  `atividade${index}produto${prodIndex}`
                                ]?.detalhamento?.length
                                  ? contadorTexto[
                                      `atividade${index}produto${prodIndex}`
                                    ]?.detalhamento.length
                                  : 0)}{' '}
                            </b>
                            caracteres
                          </small>
                        </Col>
                      </Row>
                    </>
                  ))}

                  <br />

                  <Row>
                    <Col md="12">
                      <strong>Comentários Adicionais</strong>
                      <p style={{ marginTop: '5px' }}>
                        <Form.Control
                          name={`${atividade.id}.comentario`}
                          as="textarea"
                          rows={7}
                          ref={register()}
                          maxLength="4000"
                          defaultValue={atividade.comentario}
                          disabled={readOnly}
                          onChange={(e) => {
                            setContadorComentario({
                              ...contadorComentario,
                              [index]: { comentario: e.target.value },
                            });
                          }}
                        />
                      </p>

                      <small>
                        Você pode digitar mais{' '}
                        <b>
                          {4000 -
                            (contadorComentario[index]?.comentario?.length
                              ? contadorComentario[index]?.comentario.length
                              : 0)}{' '}
                        </b>
                        caracteres
                      </small>

                      <Form.Control
                        name={`${atividade.id}.id`}
                        ref={register()}
                        value={`${atividade.id}`}
                        style={{ display: 'none' }}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
        <br />

        <br />
        <h5>Anexos</h5>

        {!readOnly && (
          <>
            <Upload onUpload={handleUpload} />
            <FileList files={anexosUpload} onDelete={handleDeleteAnexo} />
          </>
        )}

        {listaAnexos?.length >= 1 && (
          <Table
            data={listaAnexos}
            columns={columnsAnexos}
            showPageSizeOptions={false}
            showPagination=""
            defaultPageSize={5}
            minRows={0}
            pageText="Página"
            loadingText="Carregando..."
            previousText="Página anterior"
            nextText="Próxima página"
            ofText="de"
            className="-highlight"
            noDataText="Sem resultados"
          />
        )}

        {!listaAnexos?.length >= 1 && !anexosUpload?.length >= 1 && (
          <p>Nenhum anexo encontrado.</p>
        )}

        <br />

        <h5> Histórico </h5>

        <Table
          data={listaHistorico}
          columns={columnsHistorico}
          showPageSizeOptions={false}
          showPagination=""
          defaultPageSize={5}
          minRows={0}
          pageText="Página"
          loadingText="Carregando..."
          previousText="Página anterior"
          nextText="Próxima página"
          ofText="de"
          className="-highlight"
          noDataText="Sem resultados"
        />

        {!readOnly && (
          <>
            <Button
              variant="primary"
              disabled={loadingRascunho}
              onClick={handleSubmit((d) => handleSalvarRelatorio(d, false))}
            >
              {loadingRascunho ? 'Enviando...' : 'Salvar como Rascunho'}
            </Button>

            <Button
              variant="success"
              disabled={loading}
              onClick={handleSubmit((d) => handleSalvarRelatorio(d, true))}
            >
              {loading ? 'Enviando...' : 'Salvar e Enviar'}
            </Button>
          </>
        )}
      </Form>
    </>
  );
}
