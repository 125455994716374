/* eslint-disable react/prop-types */
import React, { createContext, useState, useContext } from 'react';
import { api } from '../services/api';

const VinculoContext = createContext({});

export function useVinculo() {
  const context = useContext(VinculoContext);
  return context;
}

export function VinculoProvider({ children }) {
  const [vinculo, setVinculo] = useState({});
  const [menu, setMenu] = useState({});

  async function ContextTitularResumo(keyTitular) {
    try {
      const res = await api.get(
        `cadastroapi/api/v1/Cadastros/${keyTitular}/resumo`
      );
      setVinculo(res.data.result);
      setMenu(res.data.result.permissaoAcesso);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <VinculoContext.Provider
      value={{
        vinculo,
        menu,
        ContextTitularResumo,
      }}
    >
      {children}
    </VinculoContext.Provider>
  );
}

export default { useVinculo };
