/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */

export function removeSpaceInitialAndEnd(string) {
  if (!string) {
    return string;
  }

  return string.trim();
}

export function formatCountDown(time) {
  const date = new Date(time * 1000);
  let hh = date.getUTCHours();
  let mm = date.getUTCMinutes();
  let ss = date.getSeconds();
  if (hh < 10) {
    hh = `0${hh}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  if (ss < 10) {
    ss = `0${ss}`;
  }
  return hh !== '00' ? `${hh}:${mm}:${ss}` : `${mm}:${ss}`;
}

export function getAccessToken() {
  return localStorage.getItem('@EPF2Auth:token');
}
export function timeToken(token) {
  return (
    (new Date(
      JSON.parse(
        decodeURIComponent(
          atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/'))
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
        )
      ).exp * 1000
    ) -
      Date.now()) /
    1000 /
    60
  );
}

export function renovaTokenAutomaticamente(token, minutes) {
  return timeToken(token) < minutes && timeToken(token) > 2;
}

export function onlyNumber(e) {
  ['e', 'E', '+', '-', ' '].includes(e.key) && e.preventDefault();
}

export function isFieldAvailable(fieldName, fieldsParams) {
  if (!fieldName || !fieldsParams) return false;
  const field = fieldsParams.filter((param) => param.nomeDB === fieldName);
  const isAvailable = field[0]?.isObrigatorio || false;
  return isAvailable;
}

export function validaIdade(data) {
  if (data) {
    // let data = document.getElementById('nascimento').value; // pega o valor do input
    data = data.replace(/\//g, '-'); // substitui eventuais barras (ex. IE) "/" por hífen "-"
    const dataArray = data.split('-'); // quebra a data em array

    // para o IE onde será inserido no formato dd/MM/yyyy
    if (dataArray[0].length !== 4) {
      data = `${dataArray[2]}-${dataArray[1]}-${dataArray[0]}`; // remonto a data no formato yyyy/MM/dd
    }

    // comparo as datas e calculo a idade
    const hoje = new Date();
    const nasc = new Date(data);
    let idade = hoje.getFullYear() - nasc.getFullYear();
    const m = hoje.getMonth() - nasc.getMonth();
    if (m < 0 || (m === 0 && hoje.getDate() < nasc.getDate())) idade--;

    if (idade <= 45) {
      // alert('Idade menor ou igual a 45 anos.');
      return true;
    }

    // if (idade >= 18 && idade <= 60) {
    //   alert('Maior de 18, pode se cadastrar.');
    //   return true;
    // }

    // se for maior que 60 não vai acontecer nada!
    return false;
  }

  return '';
}

export function isValidCPF(cpf) {
  if (typeof cpf !== 'string') return false;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;

  cpf = cpf.split('');
  const validator = cpf
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map((el) => +el);

  const toValidate = (pop) =>
    cpf
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map((el) => +el);
  const rest = (count, pop) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10;
  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
}

export function stringToDate(dateStr) {
  if (dateStr) {
    return new Date(
      String(`${dateStr.split('/').reverse().join('/')} 00:00:00`)
    );
  }

  return null;
}

export let dateValid = true;

export function isValidDate(data) {
  if (!data.value || data.value === '') {
    dateValid = true;
    return dateValid;
  }

  if (data.value) {
    if (!data.value.includes('/')) {
      dateValid = false;
      return dateValid;
    }

    // Se a data enviada tiver mais que 10 caracteres, não pode ser enviado.
    if (data.value.includes('/')) {
      // Se a data enviada tiver mais que 10 caracteres, não pode ser enviado.
      if (data.value.length < 10) {
        dateValid = false;
        return dateValid;
      }

      // Se o dia, o mês e o ano forem inválidos, não pode ser enviado.
      const teste = data.value.split('/').reverse();

      const year = parseInt(teste[0]);
      const month = parseInt(teste[1]);
      const day = parseInt(teste[2]);

      const d = new Date(year, month - 1, day);

      if (
        d.getFullYear() > 1900 &&
        d.getFullYear() < 2100 &&
        d.getMonth() === month - 1 &&
        d.getDate() === day
      ) {
        dateValid = true;
        return dateValid;
      }

      dateValid = false;
      return dateValid;
    }
  }

  return true;
}

export function removerCaracter(data) {
  // deixa somente os numeros na string.
  return data.replace(/[^0-9]/g, '');
}

export function mascara(i, t) {
  const v = i.value;

  if (isNaN(v[v.length - 1])) {
    i.value = v.substring(0, v.length - 1);
    return;
  }

  if (t === 'data') {
    i.setAttribute('maxlength', '10');

    i.addEventListener('keypress', (e) => {
      if (e.charCode < 47 || e.charCode > 57) {
        e.preventDefault();
      }

      const len = i.value.length;

      // If we're at a particular place, let the user type the slash
      // i.e., 12/12/1212
      if (len !== 1 || len !== 3) {
        if (e.charCode === 47) {
          e.preventDefault();
        }
      }

      // If they don't add the slash, do it for them...
      if (len === 2) {
        i.value += '/';
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        i.value += '/';
      }
    });
  }

  if (t === 'cpf') {
    i.setAttribute('maxlength', '14');
    if (v.length === 3 || v.length === 7) i.value += '.';
    if (v.length === 11) i.value += '-';
  }

  if (t === 'cnpj') {
    i.setAttribute('maxlength', '18');
    if (v.length === 2 || v.length === 6) i.value += '.';
    if (v.length === 10) i.value += '/';
    if (v.length === 15) i.value += '-';
  }

  if (t === 'cep') {
    i.setAttribute('maxlength', '9');
    if (v.length === 5) i.value += '-';
  }

  if (t === 'tel') {
    if (v[4] === '9') {
      i.setAttribute('maxlength', '14');

      if (v.length === 1) {
        const primeiroDigito = i.value; // guarda o primeiro valor digitado
        i.value = ''; // limpa todo o value
        i.value += `(${primeiroDigito}`; // adicona no value parenteses concatenado com o primeiro digito.
      }
      if (v.length === 3) i.value += ')';
      if (v.length === 9) i.value += '-';
    } else {
      i.setAttribute('maxlength', '13');
      if (v.length === 1) {
        const primeiroDigito = i.value; // guarda o primeiro valor digitado
        i.value = ''; // limpa todo o value
        i.value += `(${primeiroDigito}`; // adicona no value parenteses concatenado com o primeiro digito.
      }
      if (v.length === 3) i.value += ')';
      if (v.length === 8) i.value += '-';
    }
  }

  // if (t === 'cel') {
  //   i.setAttribute('maxlength', '14');

  //   if (v.length === 1) {
  //     const primeiroDigito = i.value; // guarda o primeiro valor digitado
  //     i.value = ''; // limpa todo o value
  //     i.value += `(${primeiroDigito}`; // adicona no value parenteses concatenado com o primeiro digito.
  //   }
  //   if (v.length === 3) i.value += ')';
  //   if (v.length === 9) i.value += '-';
  // }

  // if (t === 'fixo') {
  //   i.setAttribute('maxlength', '13');

  //   if (v.length === 1) {
  //     const primeiroDigito = i.value; // guarda o primeiro valor digitado
  //     i.value = ''; // limpa todo o value
  //     i.value += `(${primeiroDigito}`; // adicona no value parenteses concatenado com o primeiro digito.
  //   }
  //   if (v.length === 3) i.value += ')';
  //   if (v.length === 8) i.value += '-';
  // }
}

export function maskCpf(cpf) {
  return cpf
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}
