/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, ListGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { api } from '../../services/api';

import { useVinculo } from '../../contexts/vinculo';

import AvisoCadastro from '../../components/AvisoCadastro';

export default function TratamentoDados() {
  const [tratamentoDados, setTratamentoDados] = useState([]);
  const [autorizacoes, setAutorizacoes] = useState([]);
  const [btnAutorizacoes, setBtnAutorizacoes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { vinculo } = useVinculo();

  const key = localStorage.getItem('@EPF2:keyTitular');

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  function handleAutorizarInformacoes(
    idTipoTratamentoDados,
    autorizado,
    isDependeAutorizacao
  ) {
    setBtnAutorizacoes((prevState) => ({
      ...prevState,
      [idTipoTratamentoDados]: autorizado,
    }));

    if (autorizacoes.length >= 1) {
      const atualizacao = autorizacoes.map((autorizacao) =>
        autorizacao.idTipoTratamentoDados === idTipoTratamentoDados
          ? {
              idTipoTratamentoDados,
              autorizado,
              keyTitularDados: key,
              isDependeAutorizacao,
            }
          : autorizacao
      );

      setAutorizacoes(atualizacao);
    } else {
      setAutorizacoes((prevState) => [
        ...prevState,
        {
          idTipoTratamentoDados,
          autorizado,
          keyTitularDados: key,
          isDependeAutorizacao,
        },
      ]);
    }
  }

  async function handleSalvarTratamento() {
    // Define a prop autorizado pra true sempre que isDependenteAutorizacao for false

    const atualizacao = autorizacoes.map((autorizacao) =>
      autorizacao.isDependeAutorizacao === false
        ? {
            idTipoTratamentoDados: autorizacao.idTipoTratamentoDados,
            autorizado: true,
            keyTitularDados: autorizacao.keyTitularDados,
            isDependeAutorizacao: autorizacao.isDependeAutorizacao,
          }
        : autorizacao
    );

    try {
      setLoading(true);
      await api.patch(
        `cadastroapi/api/v1/Cadastros/${key}/configura-tratamento`,
        atualizacao
      );
      setLoading(false);
      return toast.success('Salvo com sucesso.');
    } catch (err) {
      setLoading(true);
      return toast.error('Não foi possível salvar.');
    }
  }

  useEffect(async () => {
    if (vinculo.tratamentosDados) {
      setTratamentoDados(vinculo.tratamentosDados);
    }
  }, [vinculo]);

  useEffect(() => {
    for (let i = 0; i < tratamentoDados.length; i++) {
      handleAutorizarInformacoes(
        tratamentoDados[i].idTipoTratamentoDados,
        tratamentoDados[i].autorizado,
        tratamentoDados[i].isDependeAutorizacao
      );
    }
  }, [tratamentoDados]);

  return (
    <>
      <h3>Tratamento de Dados</h3>

      {vinculo.mensagemEdicaoCadastro && (
        <AvisoCadastro msg={vinculo.mensagemEdicaoCadastro} />
      )}

      <ListGroup>
        {tratamentoDados?.map((tratamento) => (
          <ListGroup.Item key={tratamento.idTipoTratamentoDados}>
            <Row>
              <>
                <Col md="9">{tratamento.descricao}</Col>
                <Col md="3">
                  {tratamento.isDependeAutorizacao ? (
                    <>
                      {btnAutorizacoes[tratamento.idTipoTratamentoDados] ? (
                        <Button
                          variant="warning"
                          size="sm"
                          disabled={!vinculo.habilitaEditarCadastro}
                          onClick={() => {
                            handleAutorizarInformacoes(
                              tratamento.idTipoTratamentoDados,
                              false,
                              tratamento.isDependeAutorizacao
                            );
                          }}
                        >
                          Desfazer Autorização
                        </Button>
                      ) : (
                        <Button
                          variant="success"
                          size="sm"
                          disabled={!vinculo.habilitaEditarCadastro}
                          onClick={() => {
                            handleAutorizarInformacoes(
                              tratamento.idTipoTratamentoDados,
                              true,
                              tratamento.isDependeAutorizacao
                            );
                          }}
                        >
                          Autorizar
                        </Button>
                      )}
                    </>
                  ) : (
                    'Obrigação legal / normativa'
                  )}
                </Col>
              </>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <br />
      <br />
      <Button
        variant="success"
        size="sm"
        onClick={() => handleSalvarTratamento()}
        disabled={loading || !vinculo.habilitaEditarCadastro}
      >
        {loading ? 'Salvando...' : 'Salvar'}
      </Button>
    </>
  );
}
