/* eslint-disable no-unused-vars */
import axios from 'axios';
import { toast } from 'react-toastify';
import { removeSpaceInitialAndEnd } from '../utils';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_SISTEMA}`,
});

export const apiSeg = axios.create({
  baseURL: `${process.env.REACT_APP_API_SEG}`,
});

export const apiBolsas = axios.create({
  baseURL: `${process.env.REACT_APP_API_SISTEMA_BOLSAS}`,
});

api.interceptors.request.use(async (request) => {
  try {
    if (request.method !== 'get' && request.data) {
      const { data } = request;

      Object.keys(data).forEach((key) => {
        if (typeof data[key] === 'string') {
          if (key !== 'keyTitularDados') {
            data[key] = removeSpaceInitialAndEnd(data[key]);
          }
        }
      });
    }

    return request;
  } catch (error) {
    return toast.error('Não foi possível enviar.');
  }
});
