/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Table from '../../components/Table';
import LoadingPage from '../../components/LoadingPage';
import { api } from '../../services/api';

export default function Download() {
  const key = localStorage.getItem('@EPF2:keyTitular');

  const [downloads, setDownloads] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);

  async function fecthDownloads() {
    try {
      const res = await api.get(
        `consultaAPI/consultas/documentos/${key}/downloads`
      );
      setDownloads(res.data.result);
    } catch (err) {
      toast.error('Não foi possível carregar a tabela de termos.');
    }
  }

  async function handleDownload(url) {
    const res = await api.get(url, {
      responseType: 'blob',
    });
    const file = new Blob([res.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fecthDownloads();
    setLoadingPage(false);
  }, []);

  const columnsDownload = [
    {
      id: 'resumo',
      Header: 'Descrição',
      accessor: 'resumo',
    },
    {
      id: 'download',
      Header: 'Download',
      accessor: 'urlDownload',
      disableFilters: true,
      Cell: (rows) => (
        <>
          <Button
            onClick={() => handleDownload(rows.row.original.urlDownload)}
            className=" btn-primary btn-sm"
          >
            Download
          </Button>
        </>
      ),
    },
  ];

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Downloads</h3>

      {downloads?.length < 1 && <span>Nenhum download encontrado.</span>}

      <Tabs>
        {downloads?.map((item) => (
          <Tab eventKey={item.tipo} title={item.tipo}>
            <Table columns={columnsDownload} data={item.documentos} />
          </Tab>
        ))}
      </Tabs>
    </>
  );
}
