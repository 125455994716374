import { createGlobalStyle } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  body, input, button, .form-control-plaintext, .form-control {
    font: 14px 'Verdana', sans-serif;
    color: #606060;
  }



 #inicio .nav-item a::after {
    content: attr(data-total) ;
    background: #ddd;
    border-radius: 50%;
    padding: 2px 4px;
    margin-left: 10px;
    color: #fff;
    font-size: 12px;
    display: block;
    float: right;
    width: 20px;
    height: 20px;
    text-align: center;
}

.alert-after::after {
  background: #de6363!important;
}

.alert-cinza-after::after {
  background: #ddd!important;
}




  .modal {
    overflow-y: scroll!important;
  }

.modal-90w {
  max-width: 90%!important;
}

  .signin {
    background-color: #389bc6;
  }
  a {
    text-decoration: none
  }
  ul {
    list-style: none;
    li {
      padding: 5px;
    }
  }

  p {
    line-height: 21px;
  }

  .errorValidation {
    display: block;
  }
  .form-check {
    display: flex;
    align-items: center;
    &-label {
    font-weight: bold;
   }
  }
  label {
    font-weight: bold;
  }
  .popover {
    max-width: 450px;
  }
  .jumbotron {
    background: #f9f9f9;
    border: 1px solid #e1e1e1;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 25px;
    .form-group {
      padding-bottom: 0;
    }
  }

  button {
    cursor: pointer;
  }
  h3, h4, h5 {
    margin: 10px 0 25px 0;
  }
  .active {
    color: #606060!important;
  }
  .arrowActive {
    transform: rotate(180deg)
  }
  .col-form-label {
    font-weight: bold;
  }
  .form-group {
    padding-bottom: 25px;
    margin-bottom: 0;
  }
.filtro {
   display: flex;
  .form-check {
    margin-right: 25px;
  }
}
.tab-pane {
  padding: 20px 10px;
}
  .no-padding {
    padding: 0;
  }
  .btn {
    margin-right: 10px;
  }
  .navbar {
    border-bottom: 1px solid #eee;
  }
  .pagination  {
    justify-content: flex-end;
    align-items: center;
  }
  .footerTable {
    display: flex;
    justify-content: space-between;
  }
  .Toastify__toast-body {
    line-height: 20px;
  }
.btn-success.disabled, .btn-success:disabled {
  cursor: default;
}
.subMenuShow {
  display: block!important;
}
.subMenuShowActive {
  font-weight: bold!important;
}
#subMenu:hover {
  cursor: pointer;
}
.errorValidation {
  color: #E56060;
  font-size: 12px;
}
.link_disable {
  color: #ddd;
}
.btn-acessar {
  width: 100%;
}

.login {
  background-color: rgb(56, 155, 198);
    max-width: 100%;
    margin: 0px;
    height: 100vh;
}

.logoFiotec {
    width: 100px;
    margin-bottom: 20px;
    opacity: 0.3;
}
.logo {
    text-align: center;
    margin: 10px 0px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(239, 239, 239);
}
.modal_70w {
  max-width: 60%;
  .modal-body {
    max-width: 100%
  }
}

.tg  {width: 100%;
text-align: center;
border-collapse:collapse;
border-spacing:0;
border-color:#fff;
}
.tg td{
  padding:10px;
  height: 100px;
  border-style:solid;
  border-width:1px;
  overflow:hidden;
  word-break:normal;
  border-color:#ddd;
  color:#666;
  background-color:#fff;
  }
.tg th{
  font-weight:bold;
  padding:10px;
  border-style:solid;
  border-width:1px;
  overflow:hidden;
  word-break:normal;
  border-color:#ddd;
  color:#666;
  background-color:#efefef;
  }


  .confirm {
  z-index: 999999!important;
}
    .modal-card {
    margin: 100px auto;
    width: 500px;
    }
    .modal-card-head {
        align-items: center;
    background-color: #a24545;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 20px;
    position: relative;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    h3 {
      margin: 5px 10px;
        font-weight: bold;
        font-size: 18px;
        color: #fff;
    }

    svg {
        color: #fff;
        font-size: 22px;
      }
}

.modal-card-body {
    -webkit-overflow-scrolling: touch;
    background-color: white;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: 20px;
    opacity: 0.99;

    p {
      line-height: 22px;
      margin-bottom: 0;
    }
}
.modal-card-foot {
    align-items: center;
    background-color: white;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 20px;
    position: relative;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.modal.is-active {
    display: flex;
}
    .modal-background {
        background-color: rgba(10, 10, 10, 0.50);
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

  .alert {
    margin-bottom: 2rem;
  }
  .alert p {
    margin-bottom: 5px;
  }
  .cancelado p {
    color: #000;
    opacity: 0.2;
  }
  tr.cancelado td button {
    color: #ccc;
  }
  button[disabled]:hover {
    cursor: not-allowed
}
.react-datepicker-wrapper {
  width: 100%;
}

.class-for-description small{
  color: #999!important;
}

.table td {
  padding: 0.55rem
}

.table-hover tbody tr:hover {
  background-color: #fafafa
}

.modal-card-body {
    -webkit-overflow-scrolling: touch;
    background-color: white;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: 20px;
    opacity: 0.99;
    p {
      line-height: 22px;
      margin-bottom: 0;
    }
}
.modal-card-foot {
    align-items: center;
    background-color: white;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 20px;
    position: relative;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.modal.is-active {
    display: flex;
}
    .modal-background {
        background-color: rgba(10, 10, 10, 0.50);
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
  .lightbox {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.2);
    width: 100%;
    height: 100%;
    &:hover {
      cursor: zoom-out;
    }
	}
  .lightbox .box {
    max-width:500px;
    margin: 2% auto;
    padding:10px 20px 10px 20px;
    background-color:#FFF;
    box-shadow: 0px 1px 26px -3px #777777;
    position: relative;
    z-index: 2;
    display:block;
  }
  .lightbox .box img {
      width: 100%;
      max-width: 900px;
  }
  .lightbox_miniatura {
    width: 100%;
    margin-top: 5px;
    max-width: 200px;
    border: 1px solid #ddd;
    &:hover {
      cursor: zoom-in;
    }
  }



.nav-item {
  width: 100%;

       @media (min-width: 768px) {
        width: auto;
      }

    &.active {
      background-color: #e9e9e9!important;

      @media (min-width: 768px) {
        background-color: #fff!important;
        border-color: #dee2e6 #dee2e6 #fff!important;
    }
  }
}




`;
