/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';

import Dropzone, { useDropzone } from 'react-dropzone';

import { DropContainer, UploadMessage } from './styles';

export default function Upload({ onUpload }) {
  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return (
        <UploadMessage>
          Clique aqui para selecionar o(s) arquivo(s) que deseja anexar.
        </UploadMessage>
      );
    }

    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado.</UploadMessage>;
    }

    return (
      <UploadMessage type="success">Solte o(s) arquivo(s) aqui.</UploadMessage>
    );
  }

  // const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
  });

  return (
    <Dropzone
      accept="application/pdf"
      onDropAccepted={onUpload}
      multiple={false}
      maxFiles={1}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropContainer
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
        </DropContainer>
      )}
    </Dropzone>
  );
}
