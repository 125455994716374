/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Table from '../../components/Table';
import { api } from '../../services/api';

export default function ExcluidosTable({ fetchTotal }) {
  const history = useHistory();
  const [excluidos, setExcluidos] = useState([]);

  async function fetchExcluidos() {
    try {
      const res = await api.get('cadastroapi/api/v1/Cadastros/excluidos');
      setExcluidos(res.data.result);
      fetchTotal(res.data.result.length, 'excluidos');
    } catch (err) {
      toast.error('Não foi possível carregar a lista de excluidos.');
    }
  }

  const redirecionar = (keyTitularDados) => {
    localStorage.setItem('@EPF2:keyTitular', keyTitularDados);
    history.push('/downloads');
  };

  useEffect(() => fetchExcluidos(), []);

  const columnsExcluidos = [
    {
      id: 'descricaoVinculo',
      Header: 'Vínculo',
      accessor: 'descricaoVinculo',
      disableFilters: true,
    },

    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          <Button
            variant="primary"
            size="sm"
            onClick={() => redirecionar(rows.row.original.keyTitularDados)}
          >
            Ver detalhes
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Table data={excluidos} columns={columnsExcluidos} />
    </>
  );
}
