/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Table from '../../components/Table';
import { api } from '../../services/api';

export default function EmPreenchimentoTable({ fetchTotal }) {
  const history = useHistory();

  const [emPreenchimento, setEmPreenchimento] = useState([]);
  const [modalExcluir, setModalExcluir] = useState(false);
  const [justificativa, setJustificativa] = useState('');
  const [loading, setLoading] = useState('');
  const [keyTitularDadosEscolhido, setKeyTitularDadosEscolhido] = useState('');

  async function fetchEmPreenchimento() {
    try {
      const res = await api.get('cadastroapi/api/v1/Cadastros/rascunhos');
      // const res = await api.get('');
      setEmPreenchimento(res.data.result);
      fetchTotal(res.data.result.length, 'emPreenchimento');
    } catch (err) {
      toast.error('Não foi possível carregar a lista de em preenchimento.');
    }
  }

  async function handleExcluir() {
    try {
      setLoading(true);
      await api.put(
        `cadastroapi/api/v1/Cadastros/${keyTitularDadosEscolhido}`,
        {
          keyTitularDados: keyTitularDadosEscolhido,
          justificativa,
        }
      );
      toast.success('Cadastro excluído com sucesso.');
      setLoading(false);
      setModalExcluir(false);
      fetchEmPreenchimento();
    } catch (err) {
      setLoading(false);
      toast.error('Não foi possível excluir.');
    }
  }

  const redirecionar = (keyTitularDados) => {
    localStorage.setItem('@EPF2:keyTitular', keyTitularDados);
    history.push('/dadosBasicos');
  };

  useEffect(() => fetchEmPreenchimento(), []);

  const columnsEmPreenchimento = [
    {
      id: 'descricaoVinculo',
      Header: 'Vínculo',
      accessor: 'descricaoVinculo',
      disableFilters: true,
    },
    {
      id: 'dataUltimaAtualizacao',
      Header: 'Última atualização',
      accessor: 'dataUltimaAtualizacao',
      disableFilters: true,
    },
    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          <Button
            variant="primary"
            size="sm"
            onClick={() => redirecionar(rows.row.original.keyTitularDados)}
          >
            Continuar
          </Button>

          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              setModalExcluir(true);
              setKeyTitularDadosEscolhido(rows.row.original.keyTitularDados);
            }}
          >
            Excluir
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Table data={emPreenchimento} columns={columnsEmPreenchimento} />

      <Modal show={modalExcluir} onHide={() => setModalExcluir(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Excluir Cadastro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label column className="no-padding">
            Justificativa
          </Form.Label>
          <Form.Control
            name="justificativa"
            as="textarea"
            rows={5}
            onChange={(e) => setJustificativa(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setModalExcluir(false)}>
            Fechar
          </Button>
          <Button
            variant="danger"
            disabled={loading}
            onClick={() => handleExcluir()}
          >
            {loading ? 'Carregando' : 'Excluir'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
