import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../../../../services/api';
import InformacoesProjeto from '../../../../components/informacoesProjeto';
import Table from '../../../../components/Table';
import { useVinculo } from '../../../../contexts/vinculo';
import Confirm from '../../../../components/Confirm';
import AvisoCadastro from '../../../../components/AvisoCadastro';
import LoadingPage from '../../../../components/LoadingPage';

export default function Cancelamento() {
  const { reset, register, errors } = useForm({
    mode: 'onBlur',
  });

  const key = localStorage.getItem('@EPF2:keyTitular');
  const { keySolicitacao } = useParams();
  const history = useHistory();
  const { state: acao } = useLocation();
  const { vinculo } = useVinculo();
  const [loadingPage, setLoadingPage] = useState(false);

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  const [detalhesCancelamento, setDetalhesCancelamento] = useState([]);
  const [dataCancelamento, setDataCancelamento] = useState([]);
  const [dataEscolhida, setDataEscolhida] = useState('');
  const [justificativa, setJustificativa] = useState('');
  const [readOnly, setReadOnly] = useState(false);
  const [historico, setHistorico] = useState([]);

  async function fetchCancelamento() {
    setLoadingPage(true);
    const res = await api.get(
      `/ConsultaAPI/cancelamentos-bolsas/${keySolicitacao}/detalhes`
    );
    setDetalhesCancelamento(res.data.result[0]);
    setHistorico(res.data.result[0].historico);
    setLoadingPage(false);
  }

  async function fetchDatasCancelamento() {
    const res = await api.get(
      `/ConsultaAPI/consultas/cancelamentos-bolsas/${key}/${detalhesCancelamento.keyContrato}/datas`
    );
    setDataCancelamento(res.data.result);
  }

  async function handleSalvar(enviaAprovacao) {
    if (!justificativa || justificativa === '') {
      return toast.error('Digite a Justificativa.');
    }

    const dadosCancelamento = {
      idTitular: vinculo.id,
      keySolicitacao,
      keyTitularDados: key,
      dataCancelamento: dataEscolhida,
      justificativa,
      enviaAprovacao,
      idContrato: detalhesCancelamento.idContrato,
    };

    if (enviaAprovacao) {
      const result = await Confirm.show({
        title: 'Confirmação',
        message:
          'Tem certeza que deseja enviar o cancelamento? Após o envio você não poderá alterar até o retorno da análise.',
      });

      if (result) {
        try {
          await api.put(
            '/solicitacaoAPI/api/cancelamentos-bolsas/atualizar',
            dadosCancelamento
          );

          toast.success('Cancelamento salvo e enviado.');
          return history.push('../historico');
        } catch (err) {
          return toast.error('Não foi possível salvar e enviar.');
        }
      }
    } else {
      try {
        await api.put(
          '/solicitacaoAPI/api/cancelamentos-bolsas/atualizar',
          dadosCancelamento
        );

        fetchCancelamento();
        toast.success('Cancelamento salvo como rascunho.');
      } catch (err) {
        return toast.error('Não foi possível salvar.');
      }
    }

    return '';
  }

  useEffect(() => {
    fetchCancelamento();
  }, []);

  useEffect(() => {
    if (detalhesCancelamento.keyContrato) {
      fetchDatasCancelamento();
    }
  }, [detalhesCancelamento]);

  useEffect(() => {
    reset({
      justificativaCancelamento: detalhesCancelamento.justificativa,
    });
  }, [detalhesCancelamento]);

  useEffect(() => {
    reset({
      dataCancelamento: detalhesCancelamento.inicioCancelamento,
    });
    console.log(detalhesCancelamento.inicioCancelamento);
  }, [dataCancelamento]);

  useEffect(() => {
    if (!acao) {
      throw new Error('Ação inválida');
    }

    if (acao === 'Visualizar') {
      setReadOnly(true);
    }

    return '';
  }, []);

  const columnsHistoricoSolicitacao = [
    {
      id: 'usuario',
      Header: 'Usuário',
      accessor: 'usuario',
    },
    {
      id: 'dataStatus',
      Header: 'Data',
      accessor: 'dataStatus',
    },
    {
      id: 'status',
      Header: 'Status do Documento',
      accessor: 'status',
    },
    {
      id: 'observacao',
      Header: 'Observação',
      accessor: 'observacao',
    },
  ];

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Cancelamento de Bolsas</h3>

      <p>
        <Form.Label>Solicitação</Form.Label>:
        {detalhesCancelamento.numeroSolicitacao}
      </p>

      <InformacoesProjeto keyContrato={detalhesCancelamento.keyContrato} />

      <Row>
        <Col md="6">
          <Form.Label>Cancelamento realizado a partir de</Form.Label>
          <Form.Control
            name="dataCancelamento"
            disabled={readOnly}
            ref={register({})}
            as="select"
            onChange={(e) => setDataEscolhida(e.target.value)}
          >
            <option aria-label="Escolha" />
            {dataCancelamento.map((data) => (
              <option key={data.descricao} value={data.data}>
                {data.descricao}
              </option>
            ))}
          </Form.Control>

          <br />

          {dataEscolhida && (
            <Alert variant="danger">
              Atenção! Não haverá pagamento para a data selecionada.
            </Alert>
          )}
        </Col>
      </Row>
      <br />
      <Row>
        <Col md="12">
          <Form.Label>Justificativa do cancelamento</Form.Label>
          <Form.Control
            name="justificativaCancelamento"
            readOnly={readOnly}
            ref={register({ required: true })}
            onChange={(e) => setJustificativa(e.target.value)}
            maxLength="4000"
            as="textarea"
            rows={5}
          />
          <span className="errorValidation">
            {errors.justificativaCancelamento && 'Informe a Justificativa.'}
          </span>
        </Col>
      </Row>
      <br />

      <h5>Histórico</h5>
      <Table data={historico} columns={columnsHistoricoSolicitacao} />

      <br />
      {!readOnly && (
        <Row>
          <Button variant="primary" onClick={() => handleSalvar(false)}>
            Salvar como Rascunho
          </Button>

          <Button variant="success" onClick={() => handleSalvar(true)}>
            Salvar e Enviar
          </Button>
        </Row>
      )}
    </>
  );
}
