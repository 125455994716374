/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Table, Row, Form, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { api } from '../../services/api';
import LoadingPage from '../../components/LoadingPage';
import AvisoCadastro from '../../components/AvisoCadastro';

export default function Agenda() {
  const key = localStorage.getItem('@EPF2:keyTitular');

  const [listaCalendario, setListaCalendario] = useState([]);
  const [listaAnos, setListaAnos] = useState([]);
  const [anoSelecionado, setAnoSelecionado] = useState('');
  const [loadingPage, setLoadingPage] = useState(false);

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  function agruparPorAnoMes(objetoArray) {
    return objetoArray.reduce((acc, obj) => {
      const key = `${obj.ano}`;
      const key2 = `${obj.mes}`;

      const elemento = acc.find(
        (item) => item.ano === key && item.mes === key2
      );

      if (!elemento) {
        acc.push({
          ano: key,
          mes: key2,
          atividades: [],
        });
      }

      for (let i = 0; i < acc.length; i++) {
        if (acc[i].ano === key && acc[i].mes === key2) {
          obj.atividades.map((atividade) => acc[i].atividades.push(atividade));
        }
      }

      return acc;
    }, []);
  }

  async function fetchListaCalendario() {
    try {
      const res = await api.get(`consultaAPI/agenda/${key}`);

      // Removendo objetos Null que vem da API
      const removendoNull = res.data.result.filter((data) => data);
      // Lista de Anos para exibir no Select
      const listaAnos = removendoNull.map((item) => item.ano);
      // Lista de Anos sem Duplicidade
      const listaAnosSemDuplicidade = [...new Set(listaAnos)];
      setListaAnos(listaAnosSemDuplicidade);

      // Preparando json pra exibir o calendário
      const agrupamentoPorAnoMes = agruparPorAnoMes(removendoNull);
      const agrupamentoPorAnoMesOrdenado = agrupamentoPorAnoMes.sort(
        (a, b) => a.mes - b.mes
      );

      setListaCalendario(agrupamentoPorAnoMesOrdenado);
    } catch (err) {
      console.log(err);
      toast.error('Não foi possível carregar a agenda.');
    }
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchListaCalendario();
    setLoadingPage(false);
  }, []);

  function nomedoMes(mes) {
    if (mes === '1') return 'Jan';
    if (mes === '2') return 'Fev';
    if (mes === '3') return 'Mar';
    if (mes === '4') return 'Abr';
    if (mes === '5') return 'Mai';
    if (mes === '6') return 'Jun';
    if (mes === '7') return 'Jul';
    if (mes === '8') return 'Ago';
    if (mes === '9') return 'Set';
    if (mes === '10') return 'Out';
    if (mes === '11') return 'Nov';
    if (mes === '12') return 'Dez';
  }

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Agenda</h3>
      <p>
        Consulte aqui a programação de pagamentos e as datas limites para a
        assinatura e envio de relatórios.
      </p>
      <br />
      {listaCalendario.length >= 1 ? (
        <Row>
          <Col md="2">
            <Form.Label>Ano</Form.Label>
            <Form.Control
              name="ano"
              as="select"
              onChange={(e) => {
                setAnoSelecionado(e.target.value);
              }}
            >
              <option aria-label="Selecione" />
              {listaAnos
                .sort((a, b) => b - a)
                .map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
            </Form.Control>
          </Col>
        </Row>
      ) : (
        <p>Nenhuma atividade encontrada.</p>
      )}
      <br />
      {anoSelecionado && (
        <Tabs>
          {listaCalendario.map((item) =>
            item.ano === anoSelecionado ? (
              <Tab eventKey={nomedoMes(item.mes)} title={nomedoMes(item.mes)}>
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Dia</th>
                      <th>Evento/Atividade</th>
                      <th>Categoria</th>
                      <th>Projeto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.atividades
                      .sort((a, b) => a.dia - b.dia)
                      .map((atividade) => (
                        <>
                          <tr>
                            <td>{atividade.dia}</td>
                            <td>{atividade.descricao}</td>
                            <td>{atividade.categoria}</td>
                            <td>{atividade.projeto}</td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </Table>
              </Tab>
            ) : (
              <p>Sem programação para esse mês.</p>
            )
          )}
        </Tabs>
      )}
    </>
  );
}
