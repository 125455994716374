/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { api } from '../../../services/api';
import { isFieldAvailable, onlyNumber } from '../../../utils';
import Anexos from '../../../components/Anexos';

import AvisoCadastro from '../../../components/AvisoCadastro';
import LoadingPage from '../../../components/LoadingPage';

import { useParametrizacao } from '../../../contexts/parametrizacao';
import { useVinculo } from '../../../contexts/vinculo';
import { useEtapasVinculo } from '../../../contexts/etapasVinculo';

export default function DadosBancarios() {
  const [camposParametrizados, setCamposParametrizados] = useState([]);

  const [listaBanco, setListaBanco] = useState([]);
  const [listaTipoConta, setListaTipoConta] = useState([]);
  const [dadosBancarios, setDadosBancarios] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSalvarCadastro, setLoadingSalvarCadastro] = useState(false);
  const [camposDesabilitados, setCamposDesabilitados] = useState(false);

  const { handleSubmit, register, reset, errors } = useForm({
    mode: 'onBlur',
  });

  const key = localStorage.getItem('@EPF2:keyTitular');
  const { parametrizacao } = useParametrizacao();
  const { vinculo } = useVinculo();
  const { etapasVinculo } = useEtapasVinculo();

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchListaBancos() {
    try {
      const res = await api.get(`dicionarioapi/api/v1/Dicionarios/tipos-banco`);
      setListaBanco(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível carregar a lista de bancos');
    }

    return '';
  }

  async function fetchListaTipoConta() {
    try {
      const res = await api.get(
        `dicionarioapi/api/v1/Dicionarios/tipos-contas-bancaria`
      );
      setListaTipoConta(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível os tipos de contatos.');
    }

    return '';
  }

  async function fetchDadosBancarios() {
    try {
      const res = await api.get(
        `cadastroapi/api/v1/BancosContas/${key}/detalhes`
      );
      setDadosBancarios(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível carregar as informações.');
    }

    return '';
  }

  async function handleSalvarDadosBancario(data) {
    try {
      setLoadingSalvarCadastro(true);
      await api.put(`cadastroapi/api/v1/BancosContas`, {
        keyTitularDados: key,
        idTipoConta: Number(data.tipoConta),
        idTipoBanco: Number(data.banco),
        numeroAgencia: data.numeroAgencia,
        numeroAgenciaDigito: data.numeroAgenciaDigito,
        numeroConta: data.numeroConta,
        numeroDigitoConta: data.numeroDigitoConta,
        numeroVariacao: data.numeroVariacao,
      });

      setLoadingSalvarCadastro(false);
      toast.success('Cadastro salvo.');
      return fetchDadosBancarios();
    } catch (err) {
      setLoadingSalvarCadastro(false);
      return toast.error('Não foi possível salvar os dados bancários.');
    }
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchListaBancos();
    await fetchListaTipoConta();
    await fetchDadosBancarios();
    setLoadingPage(false);
  }, []);

  useEffect(() => {
    setCamposParametrizados(parametrizacao[8]?.campos);
    const campos = parametrizacao[8]?.campos.every(
      (field) => field.isObrigatorio === false
    );
    setCamposDesabilitados(campos);
  }, [parametrizacao]);

  useEffect(() => {
    if (dadosBancarios) {
      reset({
        tipoConta: String(dadosBancarios.idTipoConta),
        banco: String(dadosBancarios.idTipoBanco),
        numeroAgencia: dadosBancarios.numeroAgencia,
        numeroAgenciaDigito: dadosBancarios.numeroAgenciaDigito,
        numeroConta: dadosBancarios.numeroConta,
        numeroDigitoConta: dadosBancarios.numeroDigitoConta,
        numeroVariacao: dadosBancarios.numeroVariacao,
        idTipoConta: dadosBancarios.idTipoConta,
      });
    }
  }, [dadosBancarios]);

  if (vinculo.idCadastroStatus === 6) {
    return (
      <AvisoCadastro msg="Esse vínculo foi excluído, por esse motivo essa etapa não pode ser acessada." />
    );
  }

  if (etapasVinculo?.blocos?.[6].isAtivo && camposDesabilitados) {
    return <AvisoCadastro msg="Nenhum campo habilitado para preenchimento." />;
  }

  if (etapasVinculo?.blocos && !etapasVinculo?.blocos?.[6].isAtivo) {
    return (
      <AvisoCadastro msg="Essa etapa está desabilitada para esse vínculo." />
    );
  }

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Dados Bancários</h3>

      {vinculo.mensagemEdicaoCadastro && (
        <AvisoCadastro msg={vinculo.mensagemEdicaoCadastro} vinculo={vinculo} />
      )}

      <Form onSubmit={handleSubmit(handleSalvarDadosBancario)}>
        <Form.Group>
          <Row>
            {isFieldAvailable(
              'BancoConta_IdTipoConta',
              camposParametrizados
            ) && (
              <Col md="4">
                {listaTipoConta.map((tipoConta) => (
                  <Form.Check
                    name="tipoConta"
                    ref={register({})}
                    type="radio"
                    key={tipoConta.id}
                    value={tipoConta.id}
                    label={tipoConta.descricao}
                    disabled={!vinculo.habilitaEditarCadastro}
                  />
                ))}

                <span className="errorValidation">
                  {errors.tipoConta && 'Preencha este campo'}
                </span>
              </Col>
            )}
          </Row>
          <br />
          <Row>
            {isFieldAvailable(
              'BancoConta_NumeroBanco',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>Banco</Form.Label>
                <Form.Control
                  name="banco"
                  ref={register({})}
                  as="select"
                  disabled={!vinculo.habilitaEditarCadastro}
                >
                  <option aria-label="Escolha" />
                  {listaBanco.map((banco) => (
                    <option key={banco.id} value={banco.id}>
                      {banco.nome}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            )}
            {isFieldAvailable('BancoConta_Agencia', camposParametrizados) && (
              <Col md="4">
                <Form.Label>Agência</Form.Label>
                <Form.Control
                  name="numeroAgencia"
                  ref={register({ required: true })}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                  onKeyDown={(e) => onlyNumber(e)}
                />
                <span className="errorValidation">
                  {errors.numeroAgencia && 'Informe o numero da agência'}
                </span>
              </Col>
            )}
            {isFieldAvailable(
              'BancoConta_AgenciaDigito',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>Dígito</Form.Label>
                <Form.Control
                  name="numeroAgenciaDigito"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
          </Row>
          <br />
          <Row>
            {isFieldAvailable('BancoConta_Conta', camposParametrizados) && (
              <Col md="4">
                <Form.Label>Número da Conta</Form.Label>
                <Form.Control
                  name="numeroConta"
                  ref={register({})}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
            {isFieldAvailable(
              'BancoConta_ContaDigito',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>Dígito</Form.Label>
                <Form.Control
                  name="numeroDigitoConta"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
            {isFieldAvailable('BancoConta_Variacao', camposParametrizados) && (
              <Col md="4">
                <Form.Label>Variação</Form.Label>
                <Form.Control
                  name="numeroVariacao"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
          </Row>

          <br />
          <br />
          <h5>Anexos</h5>
          <Anexos idBlocoDados={7} />
          <br />
          <br />

          <Row>
            <Col md="4">
              <Button
                type="submit"
                variant="success"
                disabled={
                  !vinculo.habilitaEditarCadastro || loadingSalvarCadastro
                }
              >
                {loadingSalvarCadastro ? 'Aguarde...' : 'Salvar Cadastro'}
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </>
  );
}
