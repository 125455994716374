import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;

  .menu {
    border-right: 1px solid #eee;
    padding-top: 20px;
  }
  .content {
    padding: 20px 30px;
  }

  .inativo {
    margin-right: 10px;
    border-radius: 45px;
    border: 2px solid transparent;
  }

  .ativo {
    margin-right: 10px;
    border-radius: 45px;
    border: 2px solid rgb(0, 105, 217);
  }
`;

export const Profile = styled.section`
  position: relative;
  -webkit-box-pack: end !important;
  justify-content: flex-end !important;

  &:hover {
    cursor: pointer;
  }
`;

export const SectionContent = styled.section`
  height: 100%;
  min-height: 450px;
  padding-bottom: 50px;
`;

export const Footer = styled.footer`
  height: 100px;
  padding: 25px 0;
  border-top: 1px solid #ececec;
  ul {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    li a {
      color: #999;
    }
  }
`;
