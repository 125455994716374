/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { api } from '../../services/api';

import fiotec from '../../images/logo.png';
import AvisoCadastro from '../../components/AvisoCadastro';

export default function CartaBradesco() {
  const [modal, setModal] = useState(false);
  const [dados, setDados] = useState(null);

  const key = localStorage.getItem('@EPF2:keyTitular');
  const user = localStorage.getItem('@EPF2Auth:user');

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchDadosCarta() {
    try {
      const res = await api.get('cadastroapi/api/v1/Cadastros/carta-bradesco', {
        params: {
          cpf: JSON.parse(user).CPF.replaceAll('.', '').replace('-', ''),
        },
      });
      setDados(res.data.result);
    } catch (error) {
      console.log(error);
      toast.error(
        `Não foi possível carregar os dados da carta. ${error.response?.data?.error[0]}`
      );
    }
  }

  function printDiv(divName) {
    const printContents = document.getElementById(divName).innerHTML;
    const w = window.open();
    w.document.write(printContents);
    w.print();
    w.close();
  }

  const dataAtual = format(new Date(), 'dd/MM/yyyy');

  useEffect(() => {
    fetchDadosCarta();
  }, []);

  return (
    <>
      <h3>Carta Bradesco</h3>

      <Alert variant="info">
        <p>
          <BsFillExclamationTriangleFill style={{ marginRight: '10px' }} />{' '}
          {dados === null
            ? 'Não é possível gerar a carta.'
            : 'Antes de imprimir, confira se todos os campos preenchidos automaticamente na carta estão corretos.'}
          <br />
          <br />
          <Button onClick={() => setModal(true)} disabled={dados === null}>
            <strong>Abrir Carta Bradesco</strong>
          </Button>
        </p>
      </Alert>

      <Modal
        show={modal}
        onHide={() => setModal(false)}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Carta Bradesc</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div id="divTermoBradescoImpressao">
            <img src={fiotec} className="logo" alt="Logo Fiotec" />

            <div>
              <span style={{ float: 'right' }}>
                Rio de Janeiro, {dataAtual}
              </span>
              <p style={{ fontWeight: 'bold' }}>
                <br /> Ao
                <br /> Banco Bradesco
                <br /> Att. Gerente
                <br />
                Assunto: Abertura de Conta Movimento para Crédito de Bolsa
                <br />
              </p>
              <br />
              <div>
                <p>
                  <b>Prezado (a) Senhor (a),</b>
                  <br /> <br />
                  Apresentamos o(a) Sr.(a), {dados?.nomeCompleto}, portador(a)
                  do R.G. nº {dados?.documento} e CPF nº {dados?.cpf}.
                  <br />
                  <br />
                  <b>
                    Valor da Bolsa: R$ {dados?.valorBolsa} <br />
                    <b>
                      Data da Concessão da Bolsa: {dados?.vigenciaInicio} <br />
                      <br />
                      Sem mais, agradecemos.
                      <br />
                      <br />
                      Atenciosamente,
                      <br />
                      <br />
                      <img src="http://epf.fiotec.org.br/static/img/assinatura_raquel.png" />
                      <br />
                      <span style={{ fontWeight: 'bold' }}>
                        Raquel Raad de Oliveira
                      </span>
                      <br />
                      <span style={{ fontWeight: 'bold' }}>
                        Gerente de Recursos Humanos
                      </span>
                      <br />
                      <span style={{ fontWeight: 'bold' }}>
                        Matrícula: 7360
                      </span>
                      <br />
                      <br />
                    </b>
                  </b>
                </p>
              </div>
              <span style={{ fontWeight: 'bold' }}>
                FIOTEC – FUNDAÇÃO PARA O DESENVOLVIMENTO CIENTÍFICO E
                TECNOLÓGICO EM SAÚDE
              </span>
              <br />
              <span style={{ fontWeight: 'bold' }}>
                CNPJ: 002.385.669/0001-74 – AGÊNCIA 3002
              </span>
              <br />
              <br />
              <span
                style={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  backgroundColor: 'lightgray',
                }}
              >
                Para uso do Banco Bradesco: Sr(a). Gerente Bradesco
              </span>
              <br />
              <br />
              <p style={{ fontSize: '10px' }}>
                Os <b>BOLSISTAS</b> da <b>FIOTEC</b> já possuem aprovado um{' '}
                <b>
                  Pacote de Benefícios exclusivos para a conta movimento, com
                  direito a tarifas diferenciadas (isenção 100% nas Cestas
                  Classic1, Exclusive1 e Prime Clássica 2.0 + isenção da 1a
                  Anuidade do Cartão (Bandeiras Elo e Visa) – enviar planilha
                  para isenção do cartão
                </b>
                . Necessitam da abertura da conta movimento, não recebem via
                conta salário. Por gentileza, apresente os benefícios e
                condições da proposta firmada com a Fundação e realize o
                atendimento. Material com as condições e benefícios no endereço
                a seguir:
                <a href="https://portalcorporativo.net.bradesco.com.br/sites/4160/publico/Paginas/Conquista-de-Clientes.aspx">
                  https://portalcorporativo.net.bradesco.com.br/sites/4160/publico/Paginas/Conquista-de-Clientes.aspx
                </a>
                <br />
                <span
                  style={{
                    fontSize: '10px',
                    fontWeight: 'bold',
                    backgroundColor: 'lightgray',
                  }}
                >
                  <b>A “Ag 3002 – PRAÇA PIO X – RJ”</b>
                  detém o relacionamento. Pedimos que, se necessário, contatar
                  para eventuais dúvidas. <b>Telefone: 21 3003-8182”</b>.
                </span>
                <br />
              </p>
              <hr />
              <p>
                <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                  ATENÇÃO AGÊNCIA: INFORMAR OS DADOS DA CONTA MOVIMENTO E
                  DEVOLVER AO CLIENTE RETORNAR NO RH/FIOTEC
                </span>
                <br />
                <br />
                <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                  Nome:
                  __________________________________________________________________
                  CPF: ______________________________
                </span>
                <br />
                <br /> <br />
                <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                  Atendente Bradesco: __________________________AGÊNCIA:
                  _______________CONTA MOVIMENTO: _____________________
                </span>
                <br />
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModal(false)}>
            Fechar
          </Button>
          <Button
            variant="primary"
            onClick={() => printDiv('divTermoBradescoImpressao')}
          >
            Imprimir
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
