import styled from 'styled-components';

export const Container = styled.section`
  height: 270px;
  width: 250px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: 75px;
  z-index: 99999;
  text-align: center !important;
  border: 1px solid #cecece;
  p {
    margin-bottom: 5px;
  }
  small {
    color: #999;
    font-size: 70%;
  }

  img {
    width: 90px;
    margin-bottom: 20px;
    border-radius: 90px;
  }
`;
