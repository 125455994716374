/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { parseISO, format } from 'date-fns';
import Table from '../../../components/Table';
import { api } from '../../../services/api';
import LoadingPage from '../../../components/LoadingPage';
import { useVinculo } from '../../../contexts/vinculo';

import Confirm from '../../../components/Confirm';
import AvisoCadastro from '../../../components/AvisoCadastro';

export default function ConsultaCancelamentos() {
  const [listaBolsasCanceladas, setlistaBolsasCanceladas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const { vinculo } = useVinculo();
  const key = localStorage.getItem('@EPF2:keyTitular');
  const history = useHistory();

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchListarBolsasCanceladas() {
    try {
      const res = await api.get(
        `/ConsultaAPI/consultas/cancelamentos-bolsas/${key}`
      );
      return setlistaBolsasCanceladas(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível carregar a lista');
    }
  }
  function handleRedirecionar(acao, keySolicitacao) {
    return history.push({
      pathname: `/bolsas/cancelamentos/solicitacao/${keySolicitacao}`,
      state: acao,
    });
  }

  async function handleExcluir(keySolicitacao, idContrato) {
    const result = await Confirm.show({
      title: 'Confirmação',
      message: 'Tem certeza que deseja excluír o cancelamento?',
    });

    if (result) {
      setLoading(true);
      try {
        await api.put('/solicitacaoAPI/api/cancelamentos-bolsas/excluir', {
          idTitular: vinculo.id,
          keySolicitacao,
          idContrato,
        });
        setLoading(false);
        return fetchListarBolsasCanceladas();
      } catch (err) {
        setLoading(false);
        return toast.error('Não foi possível excluir');
      }
    }

    return '';
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchListarBolsasCanceladas();
    setLoadingPage(false);
  }, []);

  const columnsBolsasCanceladas = [
    {
      id: 'Solicitação',
      Header: 'Solicitação',
      accessor: 'numeroSolicitacao',
    },
    {
      id: 'numeroContrato',
      Header: 'Bolsa',
      accessor: 'numeroContrato',
    },
    {
      id: 'projeto',
      Header: 'Projeto',
      accessor: 'projeto',
    },
    {
      id: 'subProjeto',
      Header: 'SubProjeto',
      accessor: 'subProjeto',
    },
    {
      id: 'dataCadastro',
      Header: 'Data Do Cadastro',
      accessor: 'dataInclusao',
      Cell: (rows) =>
        format(parseISO(rows.row.original.dataInclusao), 'dd/MM/yyyy HH:mm', {
          timeZone: 'America/Sao_Paulo',
        }),
    },
    {
      id: 'inicioCancelamento',
      Header: 'A Partir De',
      accessor: 'inicioCancelamento',
      Cell: (rows) =>
        rows.row.original.inicioCancelamento &&
        format(parseISO(rows.row.original.inicioCancelamento), 'dd/MM/yyyy', {
          timeZone: 'America/Sao_Paulo',
        }),
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          {rows.row.original.habilitaVisualizar ? (
            <Button
              variant="primary"
              size="sm"
              onClick={() =>
                handleRedirecionar(
                  'Visualizar',
                  rows.row.original.keySolicitacao,
                  rows.row.original.keyContrato,
                  rows.row.original.idContrato
                )
              }
            >
              Visualizar
            </Button>
          ) : (
            ''
          )}
          {rows.row.original.habilitaEditar && (
            <>
              <Button
                variant="primary"
                size="sm"
                onClick={() =>
                  handleRedirecionar(
                    'Editar',
                    rows.row.original.keySolicitacao,
                    rows.row.original.keyContrato,
                    rows.row.original.idContrato
                  )
                }
              >
                Editar
              </Button>
            </>
          )}

          {rows.row.original.habilitaExcluir && (
            <Button
              variant="danger"
              size="sm"
              onClick={() =>
                handleExcluir(
                  rows.row.original.keySolicitacao,
                  rows.row.original.idContrato
                )
              }
              disabled={loading}
            >
              {loading ? 'Aguarde...' : 'Excluir'}
            </Button>
          )}
        </>
      ),
    },
  ];

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Cancelamentos de Bolsas</h3>
      <Table data={listaBolsasCanceladas} columns={columnsBolsasCanceladas} />
    </>
  );
}
