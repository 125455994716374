import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { api } from '../../../services/api';
import Anexos from '../../../components/Anexos';
import { isFieldAvailable, mascara, removerCaracter } from '../../../utils';

import AvisoCadastro from '../../../components/AvisoCadastro';
import LoadingPage from '../../../components/LoadingPage';

import { useParametrizacao } from '../../../contexts/parametrizacao';
import { useVinculo } from '../../../contexts/vinculo';
import { useEtapasVinculo } from '../../../contexts/etapasVinculo';

export default function LocalizacaoGeografica() {
  const { handleSubmit, register, reset, setValue } = useForm({
    mode: 'onBlur',
  });

  const key = localStorage.getItem('@EPF2:keyTitular');

  const [camposParametrizados, setCamposParametrizados] = useState([]);
  const [getLocalizacaoGeografica, setGetLocalizacaoGeografica] = useState([]);
  const [listaUf, setListaUf] = useState([]);
  const [listaMunicipio, setListaMunicipio] = useState([]);
  const [listaTipoLogradouro, setListaTipoLogradouro] = useState([]);
  const [valueTextMunicipio, setValueTextMunicipio] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSalvarCadastro, setLoadingSalvarCadastro] = useState(false);
  const [camposDesabilitados, setCamposDesabilitados] = useState(false);

  const { parametrizacao } = useParametrizacao();
  const { etapasVinculo } = useEtapasVinculo();
  const { vinculo } = useVinculo();

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchListaMunicipio(uf) {
    try {
      const res = await api.get(
        `dicionarioapi/api/v1/Dicionarios/tipo-municipio/${uf}`
      );
      setListaMunicipio(res.data.result);
    } catch (error) {
      return toast.error('Não foi possível carregar a lista de municípios.');
    }
    return '';
  }

  async function fetchLocalizacaoGeografica() {
    try {
      const res = await api.get(
        `cadastroapi/api/v1/Localizacao/${key}/detalhes`
      );

      return setGetLocalizacaoGeografica(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível carregar a localização geográfica.');
    }
  }

  async function fetchListaTipoLogradouro() {
    try {
      const res = await api.get(
        'dicionarioapi/api/v1/Dicionarios/tipos-logradouro'
      );

      setListaTipoLogradouro(res.data.result);
    } catch (error) {
      return toast.error('Não foi possível carregar a lista de logradouros.');
    }

    return '';
  }

  async function fetchListaUf() {
    try {
      const res = await api.get('dicionarioapi/api/v1/Dicionarios/tipos-uf');
      setListaUf(res.data.result);
    } catch (error) {
      return toast.error('Não foi possível carregar a lista de UFs.');
    }

    return '';
  }

  const selectedValueTextMunicipio = (e) => {
    const index = e.nativeEvent.target.selectedIndex;
    const label = e.nativeEvent.target[index].text;
    const { value } = e.target;
    setValueTextMunicipio({ type: value, label });
  };

  async function handleSubmitSalvarLocalizacao(data) {
    const dadosLocalizao = {
      keyTitularDados: key,
      cep: removerCaracter(data.cep),
      idTipoLogradouro: Number(data.tipoLogradouro),
      logradouro: data.logradouro,
      numero: data.numero,
      complemento: data.complemento,
      bairro: data.bairro,
      idUf: data.uf,
      idMunicipio: Number(data.municipio),
      municipio: valueTextMunicipio.label,
    };

    try {
      setLoadingSalvarCadastro(true);
      await api.put('cadastroapi/api/v1/Localizacao', dadosLocalizao, {
        parse: true,
      });

      setLoadingSalvarCadastro(false);
      fetchLocalizacaoGeografica();
      return toast.success('Cadastro salvo.');
    } catch (err) {
      setLoadingSalvarCadastro(false);
      return toast.error(err.response.data.error);
    }
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchListaUf();
    await fetchListaTipoLogradouro();
    await fetchLocalizacaoGeografica();
    setLoadingPage(false);
  }, []);

  useEffect(() => {
    fetchListaMunicipio(getLocalizacaoGeografica?.idUf);
  }, [getLocalizacaoGeografica?.idUf]);

  useEffect(() => {
    // código para imprimir na combo municipio o nome do municipio(atraves do id) que vem do banco de dados.
    // antes, ao acessar um cadastro com municipio preenchido, a combo ficava sem preenchimento.
    setValue('municipio', getLocalizacaoGeografica?.idMunicipio);
    setValueTextMunicipio({
      type: getLocalizacaoGeografica?.idMunicipio,
      label: getLocalizacaoGeografica?.localizacaoMunicipioDescricao,
    });
  }, [listaMunicipio]);

  useEffect(() => {
    if (getLocalizacaoGeografica) {
      reset({
        cep: getLocalizacaoGeografica.cep,
        tipoLogradouro: getLocalizacaoGeografica.idTipoLogradouro,
        logradouro: getLocalizacaoGeografica.logradouro,
        numero: getLocalizacaoGeografica.numero,
        complemento: getLocalizacaoGeografica.complemento,
        bairro: getLocalizacaoGeografica.bairro,
        uf: getLocalizacaoGeografica.idUf,
      });
    } else {
      reset({});
    }
  }, [getLocalizacaoGeografica]);

  useEffect(() => {
    setCamposParametrizados(parametrizacao[2]?.campos);
    const campos = parametrizacao[2]?.campos.every(
      (field) => field.isObrigatorio === false
    );
    setCamposDesabilitados(campos);
  }, [parametrizacao]);

  if (vinculo.idCadastroStatus === 6) {
    return (
      <AvisoCadastro msg="Esse vínculo foi excluído, por esse motivo essa etapa não pode ser acessada." />
    );
  }

  if (etapasVinculo?.blocos?.[1].isAtivo && camposDesabilitados) {
    return <AvisoCadastro msg="Nenhum campo habilitado para preenchimento." />;
  }

  if (etapasVinculo?.blocos && !etapasVinculo?.blocos?.[1].isAtivo) {
    return (
      <AvisoCadastro msg="Essa etapa está desabilitada para esse vínculo." />
    );
  }

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Localização Geográfica</h3>

      {vinculo.mensagemEdicaoCadastro && (
        <AvisoCadastro msg={vinculo.mensagemEdicaoCadastro} vinculo={vinculo} />
      )}

      <Form onSubmit={handleSubmit(handleSubmitSalvarLocalizacao)}>
        <Form.Group>
          <Row>
            {isFieldAvailable('Localizacao_CEP', camposParametrizados) && (
              <Col md="4">
                <Form.Label>CEP</Form.Label>
                <Form.Control
                  name="cep"
                  ref={register()}
                  placeholder="_____-___"
                  onInput={(e) => mascara(e.target, 'cep')}
                  disabled={!vinculo.habilitaEditarCadastro}
                />
              </Col>
            )}
            {isFieldAvailable(
              'Localizacao_IdTipoLogradouro',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>Tipo Logradouro</Form.Label>
                <Form.Control
                  name="tipoLogradouro"
                  as="select"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                >
                  <option aria-label="Selecione" />

                  {listaTipoLogradouro.map((tl) => (
                    <option key={tl.id} value={tl.id}>
                      {tl.descricao}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            )}
            {isFieldAvailable(
              'Localizacao_Logradouro',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>Logradouro</Form.Label>
                <Form.Control
                  name="logradouro"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
          </Row>
          <br />
          <Row>
            {isFieldAvailable('Localizacao_Numero', camposParametrizados) && (
              <Col md="4">
                <Form.Label>Número</Form.Label>
                <Form.Control
                  name="numero"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
            {isFieldAvailable(
              'Localizacao_Complemento',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>Complemento</Form.Label>
                <Form.Control
                  name="complemento"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
            {isFieldAvailable('Localizacao_Bairro', camposParametrizados) && (
              <Col md="4">
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  name="bairro"
                  ref={register()}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
          </Row>
          <br />
          <Row>
            {isFieldAvailable('Localizacao_UF', camposParametrizados) && (
              <Col md="4">
                <Form.Label>UF</Form.Label>
                <Form.Control
                  name="uf"
                  as="select"
                  ref={register()}
                  onChange={(e) => {
                    fetchListaMunicipio(e.target.value);
                  }}
                  disabled={!vinculo.habilitaEditarCadastro}
                >
                  <option aria-label="Selecione" />

                  {listaUf.map((uf) => (
                    <option key={uf.id} value={uf.uf}>
                      {uf.descricao}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            )}
            {isFieldAvailable(
              'Localizacao_Municipio',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>Município</Form.Label>
                <Form.Control
                  as="select"
                  ref={register()}
                  name="municipio"
                  onChange={(e) => {
                    selectedValueTextMunicipio(e);
                  }}
                  disabled={!vinculo.habilitaEditarCadastro}
                >
                  <option aria-label="Selecione" />
                  {listaMunicipio.map((m) => (
                    <option key={m.id} value={m.id}>
                      {m.descricao}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            )}
          </Row>
          <br />
          <br />
          <h5>Anexos</h5>

          <Anexos idBlocoDados={2} />

          <br />
          <br />
          <br />

          <Row>
            <Col md="4">
              <Button
                type="submit"
                variant="success"
                disabled={
                  !vinculo.habilitaEditarCadastro || loadingSalvarCadastro
                }
              >
                {loadingSalvarCadastro ? 'Aguarde...' : 'Salvar Cadastro'}
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </>
  );
}
