/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { createContext, useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { apiSeg } from '../services/api';
import { getAccessToken } from '../utils';

const AuthContext = createContext({
  signed: false,
});

export function AuthProvider({ children }) {
  const [userLogado, setUserLogado] = useState(null);

  const history = useHistory();

  function signOut() {
    setUserLogado(null);
    localStorage.removeItem('@EPF2Auth:user');
    localStorage.removeItem('@EPF2Auth:token');
    localStorage.removeItem('@EPF2Auth:perfis');

    return window.location.reload();
  }

  const signIn = async (login, senha, force) => {
    let JWT;
    let User;
    let Perfis;
    let jwtverify;

    /* Verifica se o usuário e a senha estão corretas */
    try {
      let response;

      if (force && process.env.REACT_APP_CONTEXT !== 'production') {
        response = await apiSeg.post(`login/forceauth`, {
          login,
        });
      } else {
        response = await apiSeg.post(`login/auth`, {
          login,
          senha,
        });
      }

      if (!response.data.JWT || !response.data.User) {
        return toast.error('Erro ao efetuar seu login');
      }

      JWT = response.data.JWT;
      User = response.data.User;
    } catch (err) {
      return toast.error('Login ou senha inválido');
    }

    /* Verifica se o usuário possui autorização para acessar esse sistema */
    try {
      const response = await apiSeg.post(
        `access/verify`,
        {
          IdUsuario: User.IdUsuario,
          IdSistema: Number(process.env.REACT_APP_ID_SISTEMA),
        },
        {
          headers: {
            Authorization: `Bearer ${JWT}`,
          },
        }
      );

      if (!response.data.Perfis || response.data.Perfis.length <= 0) {
        return toast.error('Nenhum perfil associado ao seu usuário.');
      }

      Perfis = response.data.Perfis;
      jwtverify = response.data.JWT;
    } catch (err) {
      return toast.error('Erro ao carregar seus perfis');
    }

    localStorage.setItem('@EPF2Auth:user', JSON.stringify(User));
    localStorage.setItem('@EPF2Auth:token', jwtverify);
    localStorage.setItem('@EPF2Auth:perfis', JSON.stringify(Perfis));

    /* Adiciona os dados do usuário no contexto para toda aplicação */
    setUserLogado(User);

    return history.push('/inicio');
  };

  async function renewToken() {
    try {
      const response = await apiSeg.post(`/login/refresh`, {
        token: getAccessToken(),
      });

      const { JWT } = response.data;

      localStorage.removeItem('@EPF2Auth:token');
      localStorage.setItem('@EPF2Auth:token', JWT);

      return JWT;
    } catch (err) {
      signOut();
      return '';
    }
  }

  // Recarregar os valores do context caso o usuário de um reload na página.
  useEffect(() => {
    async function loadStorageData() {
      const storagedUser = await localStorage.getItem('@EPF2Auth:user');
      const storagedToken = await localStorage.getItem('@EPF2Auth:token');
      const storagedPermissoes = await localStorage.getItem('@EPF2Auth:perfis');

      if (storagedUser && storagedToken && storagedPermissoes) {
        setUserLogado(JSON.parse(storagedUser));
      }
    }

    loadStorageData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: userLogado,
        signIn,
        signOut,
        renewToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export default { AuthProvider, useAuth };
