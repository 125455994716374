/* eslint-disable react/prop-types */
import React from 'react';

import { FaFilePdf } from 'react-icons/fa';

import { Container, FileInfo } from './styles';

export default function FileList({ files, onDelete }) {
  function downloadFile(indexDownload) {
    const file = files[indexDownload];
    const url = window.URL.createObjectURL(new Blob([file]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <Container>
      {files.map((file, index) => (
        <li key={file.id}>
          <FileInfo>
            <div>
              <a href="#download" onClick={() => downloadFile(index)}>
                <FaFilePdf /> {file.name}
              </a>
              <span>
                {file.readableSize}{' '}
                <button type="button" onClick={() => onDelete(file.id)}>
                  Excluir
                </button>
              </span>
            </div>
          </FileInfo>
        </li>
      ))}
    </Container>
  );
}
