import styled from 'styled-components';

export const Nav = styled.nav`
  margin-top: 15px;

  #menu-mobile {
    position: relative;
    padding-left: 1.25em;
    display: block;
    background: #2067ca;
    padding: 10px;
    text-align: center;
    color: #fff;

      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  .menu-title {
    display: none;

    @media (min-width: 768px) {
        display: block;
      }
  }

  ul {
    display: none;
    margin-top: 10px;

    @media (min-width: 768px) {
        display: block;
        margin-top: 0px;
    }

    li {
      font-weight: normal;
      list-style: none;
      padding: 3px;

      button,
      a {
        display: block;
        width: 100%;
        padding: 6px;
        text-align: left;
        margin-bottom: 0;
        font-size: 15px;

        &:hover {
              text-decoration: none;
              border-radius: 4px;
              background: #efefef;
              display: block;
            }
      }

      ul {
        margin-top: 10px;
        display: block;

        li {
          margin: 0 0 0 10px;
          padding: 0;

          a {
            width: 100%;
            display: block;
            padding: 5px;
            border-radius: 4px;
            color: #0069d9;
            font-size: 14px !important;

            &:hover {
              text-decoration: none;
              background: #efefef;
            }
          }
        }
      }
    }
  }
`;
