/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { api } from '../../services/api';

export default function AvisoCadastro({ msg, vinculo }) {
  const [loading, setLoading] = useState(false);

  async function handleEditar() {
    try {
      setLoading(true);
      await api.patch(
        `cadastroapi/api/v1/Cadastros/${vinculo.key}/status-rascunho`
      );
      setLoading(false);

      window.location.reload();
    } catch (err) {
      setLoading(false);
      toast.error('Não foi possível entrar no cadastro.');
    }
  }

  return (
    <>
      <>
        <Alert
          variant="warning"
          style={{ marginTop: '20px', marginBottom: '20px' }}
        >
          <p>
            <BsFillExclamationTriangleFill style={{ marginRight: '10px' }} />
            {msg}

            {vinculo?.habilitarBotaoEditarCadastro && (
              <>
                <br />
                <br />

                <Button
                  onClick={() => handleEditar()}
                  disabled={loading}
                  size="sm"
                >
                  {loading ? 'Aguarde...' : 'Editar Cadastro'}
                </Button>
              </>
            )}
          </p>
        </Alert>
      </>
    </>
  );
}
