/* eslint-disable react/prop-types */
import React from 'react';

export default function NotFound() {
  return (
    <>
      <br />
      <br />
      <h3>O endereço que você acessou não foi encontrado.</h3>
    </>
  );
}
