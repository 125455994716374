/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { parseISO, format } from 'date-fns';
import Table from '../../../components/Table';
import { api } from '../../../services/api';
import LoadingPage from '../../../components/LoadingPage';

import Confirm from '../../../components/Confirm';
import AvisoCadastro from '../../../components/AvisoCadastro';

export default function tabelaHistoricoSolicitacoes() {
  const history = useHistory();
  const key = localStorage.getItem('@EPF2:keyTitular');

  const [listaRelatorios, setlistaRelatorios] = useState([]);
  const [loadingExcluir, setLoadingExcluir] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchListarHistoricoRelatorio() {
    try {
      const res = await api.get(`/ConsultaAPI/relatorios-bolsas/${key}`);
      return setlistaRelatorios(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível carregar a lista');
    }
  }

  async function handleExcluir(codigoSolicitacao, keySolicitacao) {
    const result = await Confirm.show({
      title: 'Confirmação',
      message: 'Tem certeza que deseja excluír o relatório?',
    });

    if (result) {
      try {
        setLoadingExcluir(true);
        await api.put(`/solicitacaoAPI/relatorios-bolsas/excluir`, {
          keySolicitacao,
        });
        setLoadingExcluir(false);
        toast.success(`Relatório ${codigoSolicitacao} excluído com sucesso.`);
        return fetchListarHistoricoRelatorio();
      } catch (err) {
        setLoadingExcluir(false);
        return toast.error('Não foi possível excluir');
      }
    }

    return '';
  }

  function handleRedirecionar(acao, keySolicitacao) {
    return history.push({
      pathname: `/bolsas/relatorios/solicitacao/${keySolicitacao}`,
      state: acao,
    });
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchListarHistoricoRelatorio();
    setLoadingPage(false);
  }, []);

  const columnsRA = [
    {
      id: 'codigoSolicitacao',
      Header: 'Solicitação',
      accessor: 'numeroSolicitacao',
    },
    {
      id: 'numeroBolsa',
      Header: 'Bolsa',
      accessor: 'numeroBolsa',
    },

    {
      id: 'projeto',
      Header: 'Projeto',
      accessor: 'projeto',
    },
    {
      id: 'subProjeto',
      Header: 'SubProjeto',
      accessor: 'subProjeto',
    },
    {
      id: 'dataCadastro',
      Header: 'Data Do Cadastro',
      accessor: 'dataInclusao',
      Cell: (rows) =>
        format(parseISO(rows.row.original.dataInclusao), 'dd/MM/yyyy HH:mm', {
          timeZone: 'America/Sao_Paulo',
        }),
    },
    {
      id: 'finalidade',
      Header: 'Finalidade',
      accessor: 'finalidade',
    },
    {
      id: 'parcela',
      Header: 'Relatório',
      accessor: 'parcela',
    },
    {
      id: 'statusSolicitacao',
      Header: 'Status',
      accessor: 'statusSolicitacao',
    },
    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          {rows.row.original.habilitaVisualizar ? (
            <Button
              variant="primary"
              size="sm"
              onClick={() =>
                handleRedirecionar(
                  'Visualizar',
                  rows.row.original.keySolicitacao
                )
              }
            >
              Visualizar
            </Button>
          ) : (
            ''
          )}
          {rows.row.original.habilitaEditar && (
            <>
              <Button
                variant="primary"
                size="sm"
                onClick={() =>
                  handleRedirecionar('Editar', rows.row.original.keySolicitacao)
                }
              >
                Editar
              </Button>
            </>
          )}

          {rows.row.original.habilitaExcluir && (
            <Button
              variant="danger"
              size="sm"
              onClick={() =>
                handleExcluir(
                  rows.row.original.codigoSolicitacao,
                  rows.row.original.keySolicitacao
                )
              }
              disabled={loadingExcluir}
            >
              {loadingExcluir ? 'Aguarde...' : 'Excluir'}
            </Button>
          )}
        </>
      ),
    },
  ];

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Relatórios de Bolsas</h3>
      <Table data={listaRelatorios} columns={columnsRA} />
    </>
  );
}
