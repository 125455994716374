/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Table from '../../../components/Table';
import { api } from '../../../services/api';
import { useVinculo } from '../../../contexts/vinculo';
import LoadingPage from '../../../components/LoadingPage';
import AvisoCadastro from '../../../components/AvisoCadastro';

export default function TabelaBolsasSolicitarRelatorio() {
  const history = useHistory();
  const { vinculo } = useVinculo();

  const [
    listaBolsasTitularRelatorio,
    setListaBolsasTitularRelatorio,
  ] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loading, setLoading] = useState(false);

  const key = localStorage.getItem('@EPF2:keyTitular');

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchListaBolsasTitularRelatorio() {
    try {
      const res = await api.get(
        `/ConsultaAPI/consultas/contratos/${key}/relatorio-bolsa`
      );
      setListaBolsasTitularRelatorio(res.data.result);
    } catch (err) {
      toast.error('Não foi possível carregar a lista de contratos.');
    }
  }

  async function handleIniciarRelatorio(keyContrato, idContrato) {
    try {
      setLoading(true);
      const res = await api.post('/solicitacaoapi/relatorios-bolsas', {
        idTitular: vinculo.id,
        idContrato,
        keyContrato,
      });

      const { keySolicitacao } = res.data.result;

      if (keySolicitacao) {
        toast.success('Relatório criado, redirecionando...', {
          autoClose: 1400,
          onClose: () =>
            history.push({
              pathname: `/bolsas/relatorios/solicitacao/${keySolicitacao}`,
              state: 'editar',
            }),
        });
      } else {
        toast.error(`Não foi possível criar.`);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(`Erro ao iniciar processo.`);
    }
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchListaBolsasTitularRelatorio();
    setLoadingPage(false);
  }, []);

  const columnsListaBolsasTitularRelatorio = [
    {
      id: 'numeroContrato',
      Header: 'Bolsa',
      accessor: 'numeroContrato',
    },
    {
      id: 'projeto',
      Header: 'Projeto',
      accessor: 'projeto',
    },
    {
      id: 'vigencia',
      Header: 'Vigência',
      accessor: 'vigencia',
    },
    {
      id: 'valor',
      Header: 'Valor',
      accessor: 'valor',
    },
    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          {rows.row.original.habilitarCadastro === true && (
            <Button
              variant="primary"
              size="sm"
              disabled={loading}
              onClick={() =>
                handleIniciarRelatorio(
                  rows.row.original.keyContrato,
                  rows.row.original.idContrato
                )
              }
            >
              {loading ? 'Aguarde...' : 'Cadastrar'}
            </Button>
          )}
        </>
      ),
    },
  ];

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Cadastrar Relatório de Bolsa</h3>

      <br />
      <Table
        columns={columnsListaBolsasTitularRelatorio}
        data={listaBolsasTitularRelatorio}
      />
    </>
  );
}
