/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';

import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Anexos from '../../../components/Anexos';
import { api } from '../../../services/api';
import {
  isFieldAvailable,
  mascara,
  isValidDate,
  stringToDate,
  dateValid,
} from '../../../utils';

import AvisoCadastro from '../../../components/AvisoCadastro';
import LoadingPage from '../../../components/LoadingPage';

import { useVinculo } from '../../../contexts/vinculo';
import { useParametrizacao } from '../../../contexts/parametrizacao';
import { useEtapasVinculo } from '../../../contexts/etapasVinculo';

export default function DadosBasicos() {
  const [camposParametrizados, setCamposParametrizados] = useState([]);
  const [dadosBasicos, setDadosBasicos] = useState([]);
  const [listaRacaCor, setListaRacaCor] = useState([]);
  const [listaEstadoCivil, setListaEstadoCivil] = useState([]);
  const [listaGrauInstrucao, setListaGrauInstrucao] = useState([]);
  const [listaDeficiencias, setListaDeficiencias] = useState([]);
  const [listaPaises, setListaPaises] = useState([]);
  const [possuiDeficiencia, setPossuiDeficiencia] = useState();
  const [estrangeiro, setEstrangeiro] = useState(false);
  const [naturalizado, setNaturalizado] = useState(false);
  const [listaGeneros, setListaGeneros] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSalvarCadastro, setLoadingSalvarCadastro] = useState(false);
  const [camposDesabilitados, setCamposDesabilitados] = useState(false);

  const { vinculo } = useVinculo();

  const { parametrizacao } = useParametrizacao();
  const { etapasVinculo } = useEtapasVinculo();
  const { handleSubmit, register, reset, getValues } = useForm({
    mode: 'onBlur',
  });

  const key = localStorage.getItem('@EPF2:keyTitular');

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchListarGrauInstrucao() {
    try {
      const res = await api.get(
        'dicionarioapi/api/v1/Dicionarios/graus-instrucao'
      );
      setListaGrauInstrucao(res.data.result);
    } catch (err) {
      return toast.error(
        'Não foi possível carregar a lista de graus de instrução.'
      );
    }

    return '';
  }

  async function fetchListarGeneros() {
    try {
      const res = await api.get('dicionarioapi/api/v1/Dicionarios/generos');
      setListaGeneros(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível carregar a lista de gêneros.');
    }

    return '';
  }

  async function fetchListaRacaCor() {
    try {
      const res = await api.get('dicionarioapi/api/v1/Dicionarios/racas-cores');
      setListaRacaCor(res.data.result);
    } catch (error) {
      return toast.error('Não foi possível carregar a lista de raça e cor.');
    }

    return '';
  }

  async function fetchListaEstadoCivil() {
    try {
      const res = await api.get(
        'dicionarioapi/api/v1/Dicionarios/estados-civis'
      );
      setListaEstadoCivil(res.data.result);
    } catch (error) {
      return toast.error('Não foi possível carregar a lista de estado civil.');
    }

    return '';
  }

  async function fetchListaDeficiencias() {
    try {
      const res = await api.get(
        'dicionarioapi/api/v1/Dicionarios/deficiencias'
      );
      setListaDeficiencias(res.data.result);
    } catch (error) {
      return toast.error('Não foi possível carregar a lista de deficiências.');
    }

    return '';
  }

  async function fetchListapaises() {
    try {
      const res = await api.get('dicionarioapi/api/v1/Dicionarios/tipos-pais');
      setListaPaises(res.data.result);
    } catch (error) {
      return toast.error('Não foi possível carregar a lista de países.');
    }

    return '';
  }

  async function fetchDadosBasicos() {
    try {
      const res = await api.get(
        `cadastroapi/api/v1/DadosBasicos/${key}/detalhes`
      );

      if (res.data.result.length >= 1) {
        setDadosBasicos(res.data.result[0]);
      }
    } catch (err) {
      return toast.error('Não foi possível carregar as informações.');
    }

    return '';
  }

  const limparTipoDeficiencia = () => {
    const remover = {};
    remover.idTipoDeficiencia = null;
    if (remover) {
      reset({ ...getValues(), ...remover });
    }
  };

  async function handleSubmitSalvarDadosBasicos(data) {
    if (!dateValid) {
      return toast.error('Data preenchida inválida.');
    }

    let bodyRequest = {
      keyTitularDados: key,
      nacionalidade: data.nacionalidade,
      nomeCompleto: data.nomeCompleto,
      nomeSocialCompleto: data.nomeSocialCompleto,
      dataNascimentoTitularDados: stringToDate(data.dataNascimentoTitularDados),
      idGenero: Number(data.idGenero),
      idRacaCor: Number(data.idRacaCor),
      idEstadoCivil: Number(data.idEstadoCivil),
      idNivelInstrucao: Number(data.idNivelInstrucao),
      idTipoDeficiencia: Number(data.idTipoDeficiencia),
      quantidadeFilhos: Number(data.quantidadeFilhos),
      nomeMae: data.nomeMae,
      dataNascimentoMaeTitularDados: stringToDate(
        data.dataNascimentoMaeTitularDados
      ),
      nomePai: data.nomePai,
      dataNascimentoPaiTitularDados: stringToDate(
        data.dataNascimentoPaiTitularDados
      ),
      aposentado: data.aposentado === 'true',
      isEstrangeiro: estrangeiro,
      possuiDeficiencia,
    };

    if (estrangeiro) {
      bodyRequest = {
        ...bodyRequest,
        dataChegadaEstrangeiroTitularDados: stringToDate(
          data.dataChegadaEstrangeiroTitularDados
        ),

        dataNaturalizacaoTitularDados:
          naturalizado === true
            ? stringToDate(data.dataNaturalizacaoTitularDados)
            : null,

        possuiFilhoBrasileiro: data.possuiFilhoBrasileiro === 'true',
        possuiConjugeBrasileiro: data.possuiConjugeBrasileiro === 'true',
        naturalizado,
        idPaisNascimento: Number(data.idPaisNascimento),
      };
    }

    try {
      setLoadingSalvarCadastro(true);
      await api.put('cadastroapi/api/v1/DadosBasicos', bodyRequest);
      setLoadingSalvarCadastro(false);
      fetchDadosBasicos();
      return toast.success('Cadastro salvo.');
    } catch (err) {
      setLoadingSalvarCadastro(false);
      return toast.error('Não foi possivel salvar.');
    }
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchListarGrauInstrucao();
    await fetchListarGeneros();
    await fetchListaEstadoCivil();
    await fetchListaRacaCor();
    await fetchListaDeficiencias();
    await fetchListapaises();
    await fetchDadosBasicos();
    setLoadingPage(false);
  }, []);

  useEffect(() => {
    reset({
      dataNascimentoTitularDados: dadosBasicos.dataNascimento,
      dataNascimentoMaeTitularDados: dadosBasicos.dataNascimentoMae,
      dataNascimentoPaiTitularDados: dadosBasicos.dataNascimentoPai,
      dataChegadaEstrangeiroTitularDados: dadosBasicos.dataChegadaEstrangeiro,
      dataNaturalizacaoTitularDados: dadosBasicos.dataNaturalizacao,
      nomeCompleto: dadosBasicos.nomeCompleto,
      nomeSocialCompleto: dadosBasicos.nomeSocialCompleto,
      idGenero: String(dadosBasicos.idGenero),
      idRacaCor: dadosBasicos.idRacaCor,
      idEstadoCivil: dadosBasicos.idEstadoCivil,
      idNivelInstrucao: dadosBasicos.idNivelInstrucao,
      idTipoDeficiencia: dadosBasicos.idTipoDeficiencia,
      quantidadeFilhos: dadosBasicos.quantidadeFilhos,
      nomeMae: dadosBasicos.nomeMae,
      nomePai: dadosBasicos.nomePai,
      aposentado: dadosBasicos.aposentado,
      possuiFilhoBrasileiro: dadosBasicos.possuiFilhoBrasileiro,
      possuiConjugeBrasileiro: dadosBasicos.possuiConjugeBrasileiro,
      idPaisNascimento: dadosBasicos.idPaisNascimento,
    });
    setEstrangeiro(dadosBasicos.estrangeiro);
    setNaturalizado(dadosBasicos.naturalizado);
    setPossuiDeficiencia(dadosBasicos.possuiDeficiencia);
  }, [dadosBasicos]);

  useEffect(() => {
    setCamposParametrizados(parametrizacao[0]?.campos);
    const campos = parametrizacao[0]?.campos.every(
      (field) => field.isObrigatorio === false
    );
    setCamposDesabilitados(campos);
  }, [parametrizacao]);

  if (vinculo.idCadastroStatus === 6) {
    return (
      <AvisoCadastro msg="Esse vínculo foi excluído, por esse motivo essa etapa não pode ser acessada." />
    );
  }

  if (etapasVinculo?.blocos?.[0].isAtivo && camposDesabilitados) {
    return <AvisoCadastro msg="Nenhum campo habilitado para preenchimento." />;
  }

  if (etapasVinculo?.blocos && !etapasVinculo?.blocos?.[0].isAtivo) {
    return (
      <AvisoCadastro msg="Essa etapa está desabilitada para esse vínculo." />
    );
  }

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Dados Básicos</h3>
      {vinculo.mensagemEdicaoCadastro && (
        <AvisoCadastro msg={vinculo.mensagemEdicaoCadastro} vinculo={vinculo} />
      )}

      <Form onSubmit={handleSubmit(handleSubmitSalvarDadosBasicos)}>
        <br />
        <Form.Group>
          {isFieldAvailable(
            'TitularDados_Nacionalidade',
            camposParametrizados
          ) && (
            <Row>
              <Col md="2">
                <Form.Check
                  type="radio"
                  name="nacionalidade"
                  onChange={() => {
                    setEstrangeiro(false);
                  }}
                  checked={!estrangeiro}
                  label="Brasileiro"
                  disabled={!vinculo.habilitaEditarCadastro}
                />
              </Col>
              <Col md="2">
                <Form.Check
                  type="radio"
                  name="nacionalidade"
                  onChange={() => {
                    setEstrangeiro(true);
                  }}
                  checked={estrangeiro}
                  label="Estrangeiro"
                  disabled={!vinculo.habilitaEditarCadastro}
                />
              </Col>
            </Row>
          )}
          <br />
          <hr />
          <br />
          <Row>
            {isFieldAvailable(
              'TitularDados_NomeCompleto',
              camposParametrizados
            ) && (
              <Col md="6">
                <Form.Label>Nome Completo</Form.Label>
                <Form.Control
                  ref={register()}
                  name="nomeCompleto"
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}

            {isFieldAvailable(
              'TitularDados_NomeSocialCompleto',
              camposParametrizados
            ) && (
              <Col md="6">
                <Form.Label>
                  Nome Social{' '}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        Decreto Nº 8.727, de 28 de abril de 2016, Art. 1º inciso
                        I designação pela qual a pessoa travesti ou transexual
                        se identifica e é socialmente reconhecida.
                      </Tooltip>
                    }
                  >
                    <FaInfoCircle />
                  </OverlayTrigger>
                </Form.Label>

                <Form.Control
                  ref={register()}
                  name="nomeSocialCompleto"
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
          </Row>

          <Row>
            {isFieldAvailable(
              'TitularDados_IdGenero',
              camposParametrizados
            ) && (
              <Col md="6">
                <br />
                <Form.Label>Sexo</Form.Label>
                <br />
                {listaGeneros.map((g) => (
                  <Form.Check
                    ref={register()}
                    type="radio"
                    name="idGenero"
                    key={g.id}
                    value={g.id}
                    label={g.descricao}
                    style={{ float: 'left', marginRight: '20px' }}
                    disabled={!vinculo.habilitaEditarCadastro}
                  />
                ))}
              </Col>
            )}
            {isFieldAvailable(
              'TitularDados_IdRacaCor',
              camposParametrizados
            ) && (
              <Col md="6">
                <br />

                <Form.Label>Raça/Cor</Form.Label>
                <Form.Control
                  ref={register()}
                  name="idRacaCor"
                  as="select"
                  disabled={!vinculo.habilitaEditarCadastro}
                >
                  <option aria-label="Escolha" />
                  {listaRacaCor.map((raca) => (
                    <option key={raca.id} value={raca.id}>
                      {raca.descricao}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            )}
          </Row>
          <br />

          <Row>
            {isFieldAvailable(
              'TitularDados_DataNascimento',
              camposParametrizados
            ) && (
              <Col md="6">
                <Form.Label column md="12" className="no-padding">
                  Data de Nascimento
                </Form.Label>
                <Form.Control
                  name="dataNascimentoTitularDados"
                  className="data"
                  ref={register()}
                  placeholder="__/__/____"
                  onChange={(e) => mascara(e.target, 'data')}
                  onBlur={(e) => isValidDate(e.target)}
                  disabled={!vinculo.habilitaEditarCadastro}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                />
              </Col>
            )}
            {isFieldAvailable(
              'TitularDados_IdEstadoCivil',
              camposParametrizados
            ) && (
              <Col md="6">
                <Form.Label column md="5" className="no-padding">
                  Estado Civil
                </Form.Label>
                <Form.Control
                  ref={register()}
                  name="idEstadoCivil"
                  as="select"
                  disabled={!vinculo.habilitaEditarCadastro}
                >
                  <option aria-label="Escolha" />
                  {listaEstadoCivil.map((estado) => (
                    <option key={estado.id} value={estado.id}>
                      {estado.descricao}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            )}
          </Row>

          <br />
          <Row>
            {isFieldAvailable(
              'TitularDados_QuantidadeFilhos',
              camposParametrizados
            ) && (
              <Col md="6">
                <Form.Label column md="5" className="no-padding">
                  Número de Filhos
                </Form.Label>
                <Form.Control
                  ref={register()}
                  type="number"
                  min="0"
                  name="quantidadeFilhos"
                  disabled={!vinculo.habilitaEditarCadastro}
                />
              </Col>
            )}
            {isFieldAvailable(
              'TitularDados_IdGrauInstrucao',
              camposParametrizados
            ) && (
              <Col md="6">
                <Form.Label column md="5" className="no-padding">
                  Nível de instrução
                </Form.Label>
                <Form.Control
                  ref={register()}
                  name="idNivelInstrucao"
                  as="select"
                  disabled={!vinculo.habilitaEditarCadastro}
                >
                  <option aria-label="Escolha" />
                  {listaGrauInstrucao.map((instrucao) => (
                    <option key={instrucao.id} value={instrucao.id}>
                      {instrucao.descricao}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            )}
          </Row>
          <br />

          <Row>
            {isFieldAvailable(
              'TitularDados_IsAposentado',
              camposParametrizados
            ) && (
              <Col md="6">
                <Form.Label column className="no-padding">
                  Trabalhador Aposentado
                </Form.Label>
                <Form.Control
                  ref={register()}
                  name="aposentado"
                  as="select"
                  disabled={!vinculo.habilitaEditarCadastro}
                >
                  <option aria-label="isAposentado" />
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </Form.Control>
              </Col>
            )}
            {isFieldAvailable(
              'TitularDados_IdDeficiencia',
              camposParametrizados
            ) && (
              <>
                <Col md="6">
                  <Form.Label column md="5" className="no-padding">
                    Pessoa com Deficiência
                  </Form.Label>
                  <br />
                  <Form.Check
                    type="radio"
                    name="idPossuiDeficiencia"
                    onChange={() => {
                      setPossuiDeficiencia(true);
                    }}
                    checked={possuiDeficiencia}
                    label="Sim"
                    style={{ float: 'left', margin: '7px 20px 0 0' }}
                    disabled={!vinculo.habilitaEditarCadastro}
                  />
                  <Form.Check
                    type="radio"
                    name="idPossuiDeficiencia"
                    onChange={() => {
                      setPossuiDeficiencia(false);
                      limparTipoDeficiencia();
                    }}
                    checked={possuiDeficiencia === false}
                    label="Não"
                    style={{ float: 'left', margin: '7px 20px 0 0' }}
                    disabled={!vinculo.habilitaEditarCadastro}
                  />
                </Col>
                {possuiDeficiencia && (
                  <Col md="6">
                    <Form.Label column md="5" className="no-padding">
                      Tipo de Deficiência
                    </Form.Label>
                    <Form.Control
                      ref={register()}
                      name="idTipoDeficiencia"
                      as="select"
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="Escolha" />
                      {listaDeficiencias.map((deficiencia) => (
                        <option key={deficiencia.id} value={deficiencia.id}>
                          {deficiencia.descricao}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                )}
              </>
            )}
          </Row>
          <br />

          {isFieldAvailable('TitularDados_NomeMae', camposParametrizados) ||
          isFieldAvailable(
            'TitularDados_DataNascimentoMae',
            camposParametrizados
          ) ? (
            <>
              <Row>
                {isFieldAvailable(
                  'TitularDados_NomeMae',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label column md="12" className="no-padding">
                      Nome da Mãe
                    </Form.Label>
                    <Form.Control
                      ref={register()}
                      name="nomeMae"
                      disabled={!vinculo.habilitaEditarCadastro}
                      maxLength="100"
                    />
                  </Col>
                )}
                {isFieldAvailable(
                  'TitularDados_DataNascimentoMae',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label column md="12" className="no-padding">
                      Data de Nascimento Mãe
                    </Form.Label>
                    <Form.Control
                      name="dataNascimentoMaeTitularDados"
                      ref={register()}
                      placeholder="__/__/____"
                      onInput={(e) => mascara(e.target, 'data')}
                      onBlur={(e) => isValidDate(e.target)}
                      disabled={!vinculo.habilitaEditarCadastro}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </Col>
                )}
              </Row>
              <br />
            </>
          ) : (
            ''
          )}

          {isFieldAvailable('TitularDados_NomePai', camposParametrizados) ||
          isFieldAvailable(
            'TitularDados_DataNascimentoPai',
            camposParametrizados
          ) ? (
            <>
              <Row>
                {isFieldAvailable(
                  'TitularDados_NomePai',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label column md="12" className="no-padding">
                      Nome do Pai
                    </Form.Label>
                    <Form.Control
                      ref={register()}
                      name="nomePai"
                      disabled={!vinculo.habilitaEditarCadastro}
                      maxLength="100"
                    />
                  </Col>
                )}
                {isFieldAvailable(
                  'TitularDados_DataNascimentoPai',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label column md="12" className="no-padding">
                      Data de Nascimento Pai
                    </Form.Label>

                    <Form.Control
                      name="dataNascimentoPaiTitularDados"
                      ref={register()}
                      placeholder="__/__/____"
                      onInput={(e) => mascara(e.target, 'data')}
                      onBlur={(e) => isValidDate(e.target)}
                      disabled={!vinculo.habilitaEditarCadastro}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </Col>
                )}
              </Row>

              <br />
            </>
          ) : (
            ''
          )}

          {estrangeiro && (
            <>
              <Row>
                <br />

                {isFieldAvailable(
                  'TitularDados_IsNaturalizado',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>Naturalizado</Form.Label>
                    <br />
                    <Form.Check
                      ref={register()}
                      type="radio"
                      name="naturalizado"
                      onChange={() => {
                        setNaturalizado(true);
                      }}
                      checked={naturalizado}
                      label="Sim"
                      style={{ float: 'left', marginRight: '20px' }}
                      disabled={!vinculo.habilitaEditarCadastro}
                    />
                    <Form.Check
                      ref={register()}
                      type="radio"
                      name="naturalizado"
                      onChange={() => {
                        setNaturalizado(false);
                      }}
                      checked={!naturalizado}
                      label="Não"
                      style={{ float: 'left', marginRight: '20px' }}
                      disabled={!vinculo.habilitaEditarCadastro}
                    />
                  </Col>
                )}
                {naturalizado && (
                  <>
                    {isFieldAvailable(
                      'TitularDados_DataNaturalizacao',
                      camposParametrizados
                    ) && (
                      <>
                        <Col md="6">
                          <Form.Label column md="12" className="no-padding">
                            Data da naturalização
                          </Form.Label>
                          <Form.Control
                            name="dataNaturalizacaoTitularDados"
                            ref={register()}
                            placeholder="__/__/____"
                            onInput={(e) => mascara(e.target, 'data')}
                            onBlur={(e) => isValidDate(e.target)}
                            disabled={!vinculo.habilitaEditarCadastro}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </Col>
                        <br />
                        <br />
                      </>
                    )}
                  </>
                )}
              </Row>

              <br />

              <Row>
                <br />

                {isFieldAvailable(
                  'TitularDados_DataChegadaEstrangeiro',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label column md="12" className="no-padding">
                      Data da chegada Estrangeiro
                    </Form.Label>
                    <Form.Control
                      name="dataChegadaEstrangeiroTitularDados"
                      className="data"
                      ref={register()}
                      placeholder="__/__/____"
                      onInput={(e) => mascara(e.target, 'data')}
                      onBlur={(e) => isValidDate(e.target)}
                      disabled={!vinculo.habilitaEditarCadastro}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </Col>
                )}

                {isFieldAvailable(
                  'TitularDados_IsPossuiConjugeBrasileiro',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label column md="12" className="no-padding">
                      Casado(a) com brasileiro(a)
                    </Form.Label>
                    <Form.Control
                      ref={register()}
                      name="possuiConjugeBrasileiro"
                      as="select"
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="isPossuiConjugeBrasileiro" />
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </Form.Control>
                  </Col>
                )}
              </Row>
              <br />
              <Row>
                {isFieldAvailable(
                  'TitularDados_IsPossuiFilhoBrasileiro',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label column md="5" className="no-padding">
                      Filhos brasileiros
                    </Form.Label>
                    <Form.Control
                      ref={register()}
                      name="possuiFilhoBrasileiro"
                      as="select"
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="isPossuiFilhoBrasileiro" />
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </Form.Control>
                  </Col>
                )}
                {isFieldAvailable(
                  'TitularDados_IdPaisNascimento',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label column md="5" className="no-padding">
                      Nacionalidade
                    </Form.Label>
                    <Form.Control
                      ref={register()}
                      name="idPaisNascimento"
                      as="select"
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="Escolha" />
                      {listaPaises.map((pais) => (
                        <option key={pais.id} value={pais.id}>
                          {pais.descricao}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                )}
              </Row>
              <br />
            </>
          )}

          <h5>Anexos</h5>

          <Anexos idBlocoDados={1} />

          <Row>
            <Col md="4">
              <Button
                type="submit"
                variant="success"
                disabled={
                  !vinculo.habilitaEditarCadastro || loadingSalvarCadastro
                }
              >
                {loadingSalvarCadastro ? 'Aguarde...' : 'Salvar Cadastro'}
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </>
  );
}
