/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { formatCountDown, timeToken, getAccessToken } from '../../utils/index';
import { useAuth } from '../../contexts/auth';

export default function CountDown({ show, hide }) {
  const { signOut, renewToken } = useAuth();
  const [tempoRestando, setTempoRestando] = useState(
    timeToken(getAccessToken()) * 60
  );
  const [desconectado, setDesconectado] = useState(false);

  useEffect(() => {
    const myInterval = setInterval(() => {
      setTempoRestando(timeToken(getAccessToken()) * 60);
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (tempoRestando <= 0) {
      setDesconectado(true);
    }
  }, [tempoRestando]);

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Sessão</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col style={{ textAlign: 'center' }}>
            {desconectado ? (
              'Sua sessão expirou. Faça o login novamente.'
            ) : (
              <>
                <p>
                  Você será <span style={{ color: 'red' }}>desconectado</span>{' '}
                  dentro de:
                  <h3>
                    <strong>{formatCountDown(tempoRestando)}</strong>
                  </h3>
                </p>

                <p>
                  Clique em <strong>Renovar Sessão</strong>, caso queira
                  continuar logado.
                </p>
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {desconectado ? (
          <Button variant="primary" onClick={() => signOut()}>
            Fazer Login
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              renewToken();
              hide(false);
            }}
          >
            Renovar Sessão
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
