/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { datadogRum } from '@datadog/browser-rum';
import Routes from './routes';
import Global from './styles';

import { AuthProvider } from './contexts/auth';
import { VinculoProvider } from './contexts/vinculo';
import { ParametrizacaoProvider } from './contexts/parametrizacao';
import { EtapasVinculoProvider } from './contexts/etapasVinculo';

import { timeToken, getAccessToken } from './utils/index';
import CountDown from './components/CountDown';

function App() {
  const [modalSessao, setModalSessao] = useState(false);
  const tempoRestandoDeSessao = 2;

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (
        getAccessToken() &&
        timeToken(getAccessToken()) < tempoRestandoDeSessao &&
        !modalSessao
      ) {
        setModalSessao(true);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [modalSessao]);

  datadogRum.init({
    applicationId: '630a6ea3-0579-4367-81d0-56e1f6844d33',
    clientToken: 'pub9bf8b00a5d56f73035e402a0fb29e1b0',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'us3.datadoghq.com',
    service: 'epf2',
    env: 'production',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    allowedTracingUrls: [
      'https://epf2.fiotec.org.br',
      /https:\/\/.*\.my-api-domain\.com/,
      (url) => url.startsWith('https://epf2.fiotec.org.br'),
    ],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  return (
    <Router>
      <AuthProvider>
        <VinculoProvider>
          <ParametrizacaoProvider>
            <EtapasVinculoProvider>
              <Routes />
              {modalSessao && (
                <CountDown
                  show={modalSessao}
                  hide={() => setModalSessao(false)}
                />
              )}
            </EtapasVinculoProvider>
          </ParametrizacaoProvider>
        </VinculoProvider>
      </AuthProvider>
      <Global />
      <ToastContainer />
    </Router>
  );
}

export default App;
