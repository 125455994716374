/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Table from '../../components/Table';

import { api } from '../../services/api';

export default function NovosTable({ fetchTotal }) {
  const [novos, setNovos] = useState([]);
  const [desabilitarIniciar, setDesabilitarIniciar] = useState(false);
  const history = useHistory();

  async function fetchNovos() {
    try {
      const res = await api.get('/conviteApi/api/v1/Convites/novos');
      setNovos(res.data.result);
      fetchTotal(res.data.result.length, 'novos');
    } catch (err) {
      toast.error('Não foi possível carregar a lista de novos convites.');
    }
  }

  useEffect(() => fetchNovos(), []);

  async function handleIniciar(keyDoEmail) {
    setDesabilitarIniciar(true);
    try {
      const res = await api.post('/cadastroapi/api/v1/Cadastros/inicia', {
        keyEmail: keyDoEmail,
      });

      const { key } = res.data.result;

      if (key) {
        localStorage.setItem('@EPF2:keyTitular', key);
        toast.success('Processo iniciado com sucesso! Redirecionando...', {
          autoClose: 1750,
          onClose: () => history.push('/dadosBasicos'),
        });
      }
    } catch (error) {
      toast.error(`Erro ao iniciar processo.`);
      setDesabilitarIniciar(false);
    }
  }

  const columnsNovos = [
    {
      id: 'descricao',
      Header: 'Vínculo',
      accessor: 'descricao',
      disableFilters: true,
    },

    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          <Button
            onClick={() => handleIniciar(rows.row.original.key)}
            variant="primary"
            size="sm"
            disabled={desabilitarIniciar}
          >
            Iniciar
          </Button>
        </>
      ),
    },
  ];
  return (
    <>
      <Table data={novos} columns={columnsNovos} />
    </>
  );
}
