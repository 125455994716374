import { Col, Form, Row, Dropdown } from 'react-bootstrap';
import React from 'react';
import AvisoCadastro from '../../components/AvisoCadastro';

export default function PortabilidadeCadastro() {
  const key = localStorage.getItem('@EPF2:keyTitular');

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  return (
    <>
      <h3>Portabilidade</h3>

      <br />
      <Row>
        <Col md="3">
          <Form.Label>Tipo de Dado</Form.Label>
          <Form.Control name="formatoDocumento" as="select" disabled>
            <option value="cadastroCompleto">Cadastro Completo</option>
          </Form.Control>
        </Col>

        <Dropdown style={{ display: 'inline', marginTop: '23px' }}>
          <Dropdown.Toggle variant="primary">Download</Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              href={`${process.env.REACT_APP_API_SISTEMA}cadastroapi/api/portabilidades/${key}/formatos/PDF`}
              target="_blank"
            >
              Formato PDF
            </Dropdown.Item>

            <Dropdown.Item
              href={`${process.env.REACT_APP_API_SISTEMA}cadastroapi/api/portabilidades/${key}/formatos/XML`}
              target="_blank"
            >
              Formato XML
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Row>
    </>
  );
}
