import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { api } from '../../../services/api';
import Table from '../../../components/Table';
import Anexos from '../../../components/Anexos';
import {
  isFieldAvailable,
  mascara,
  isValidDate,
  stringToDate,
  dateValid,
} from '../../../utils/index';

import AvisoCadastro from '../../../components/AvisoCadastro';
import LoadingPage from '../../../components/LoadingPage';

import { useVinculo } from '../../../contexts/vinculo';
import { useParametrizacao } from '../../../contexts/parametrizacao';
import { useEtapasVinculo } from '../../../contexts/etapasVinculo';

export default function Formacao() {
  const [camposParametrizados, setCamposParametrizados] = useState([]);

  const [formacoes, setFormacoes] = useState([]);
  const [niveisEscolaridade, setNiveisEscolaridade] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [situacoesFormacao, setSituacoesFormacao] = useState([]);
  const [paises, setPaises] = useState([]);
  const [nomeCurso, setNomeCurso] = useState();
  const [formacaoEdicao, setFormacaoEdicao] = useState({});
  const [modalFormacao, setModalFormacao] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSalvarCadastro, setLoadingSalvarCadastro] = useState(false);
  const [camposDesabilitados, setCamposDesabilitados] = useState(false);

  const { handleSubmit, register, reset, errors } = useForm({
    mode: 'onBlur',
  });

  const key = localStorage.getItem('@EPF2:keyTitular');
  const { parametrizacao } = useParametrizacao();
  const { vinculo } = useVinculo();
  const { etapasVinculo } = useEtapasVinculo();

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchFormacoes() {
    try {
      const res = await api.get(`cadastroapi/api/v1/Formacoes/${key}`);
      return setFormacoes(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível exibir a lista de formações.');
    }
  }

  async function fetchNiveisEscolaridade() {
    try {
      const res = await api.get(
        `dicionarioapi/api/v1/Dicionarios/niveis-escolaridade`
      );
      return setNiveisEscolaridade(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível exibir a lista de escolaridade.');
    }
  }

  async function fetchCursos() {
    try {
      const res = await api.get(`dicionarioapi/api/v1/Dicionarios/tipos-curso`);
      return setCursos(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível exibir a lista de cursos.');
    }
  }

  async function fetchSituacaoFormacao() {
    try {
      const res = await api.get(
        `dicionarioapi/api/v1/Dicionarios/situacoes-formacao`
      );
      return setSituacoesFormacao(res.data.result);
    } catch (err) {
      return toast.error(
        'Não foi possível exibir a lista de vinculos públicos.'
      );
    }
  }

  async function fetchPaises() {
    try {
      const res = await api.get(`dicionarioapi/api/v1/Dicionarios/tipos-pais`);
      return setPaises(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível exibir a lista de países.');
    }
  }

  async function fetchFormacaoDetalhes(idFormacao) {
    try {
      const res = await api.get(
        `cadastroapi/api/v1/Formacoes/${key}/${idFormacao}/detalhes`
      );
      return setFormacaoEdicao(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível exibir os detalhes da formação.');
    }
  }

  async function handleAdicionarFormacao(data) {
    if (!dateValid) {
      return toast.error('Data preenchida inválida.');
    }

    const formacao = {
      keyTitularDados: key,
      idCurso: Number(data.curso),
      curso: nomeCurso,
      idNivelEscolaridade: Number(data.nivelEscolaridade),
      idSituacaoFormacao: Number(data.situacao),
      idPais: Number(data.pais),
      instituicao: data.instituicao,
      dataInicio: stringToDate(data.dataInicio),
      dataTermino: stringToDate(data.dataTermino),
    };

    try {
      if (formacaoEdicao.id) {
        const formacaoPut = {
          id: formacaoEdicao.id,
          ...formacao,
        };
        setLoadingSalvarCadastro(true);
        await api.put('cadastroapi/api/v1/Formacoes', formacaoPut);
        setLoadingSalvarCadastro(false);
        toast.success('Cadastro salvo.');
      } else {
        setLoadingSalvarCadastro(true);
        await api.post('cadastroapi/api/v1/Formacoes', formacao);
        setLoadingSalvarCadastro(false);
        toast.success('Cadastro salvo.');
      }

      setNomeCurso();
      setModalFormacao(false);
      return fetchFormacoes(key);
    } catch (err) {
      setLoadingSalvarCadastro(false);

      if (typeof err.response.data.error === 'object') {
        return err.response.data.error.map((item) => toast.error(item));
      }
      return toast.error(err.response.data.error);
    }
  }

  async function handlerRemover(id) {
    try {
      await api.delete(`cadastroapi/api/v1/Formacoes/${key}/${id}`);
      toast.success('Deletado com sucesso');
      return fetchFormacoes(key);
    } catch (err) {
      return toast.error('Não foi possível deletar.');
    }
  }

  const selectNomeCurso = (e) => {
    const nome = cursos.find(
      (course) => course.id === parseInt(e.target.value, 10)
    )?.descricao;
    setNomeCurso(nome);
  };

  useEffect(async () => {
    setLoadingPage(true);
    await fetchFormacoes();
    await fetchNiveisEscolaridade();
    await fetchCursos();
    await fetchSituacaoFormacao();
    await fetchPaises();
    setLoadingPage(false);
  }, []);

  useEffect(() => {
    setCamposParametrizados(parametrizacao[6]?.campos);
    const campos = parametrizacao[6]?.campos.every(
      (field) => field.isObrigatorio === false
    );
    setCamposDesabilitados(campos);
  }, [parametrizacao]);

  useEffect(() => {
    if (formacaoEdicao.id) {
      reset({
        curso: formacaoEdicao.idCurso,
        nivelEscolaridade: formacaoEdicao.idNivelEscolaridade,
        situacao: String(formacaoEdicao.idSituacaoFormacao),
        pais: formacaoEdicao.idPais,
        instituicao: formacaoEdicao.instituicao,
        dataInicio: formacaoEdicao.dataInicio,
        dataTermino: formacaoEdicao.dataTermino,
      });
      setNomeCurso(formacaoEdicao.curso);
    } else {
      reset({});
    }
  }, [formacaoEdicao]);

  const columnsFormacoes = [
    {
      id: 'curso',
      Header: 'Curso',
      accessor: 'formacaoTipoCursoDescricao',
    },
    {
      id: 'instituicao',
      Header: 'Instituição',
      accessor: 'instituicao',
    },

    {
      id: 'situacao',
      Header: 'Situação',
      accessor: 'formacaoSituacaoDescricao',
    },
    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              fetchFormacaoDetalhes(rows.row.original.id);
              setModalFormacao(true);
            }}
          >
            {!vinculo.habilitaEditarCadastro ? 'Visualizar' : 'Editar'}
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => handlerRemover(rows.row.original.id)}
            disabled={!vinculo.habilitaEditarCadastro}
          >
            Remover
          </Button>
        </>
      ),
    },
  ];

  if (vinculo.idCadastroStatus === 6) {
    return (
      <AvisoCadastro msg="Esse vínculo foi excluído, por esse motivo essa etapa não pode ser acessada." />
    );
  }

  if (etapasVinculo?.blocos?.[5].isAtivo && camposDesabilitados) {
    return <AvisoCadastro msg="Nenhum campo habilitado para preenchimento." />;
  }

  if (etapasVinculo?.blocos && !etapasVinculo?.blocos?.[5].isAtivo) {
    return (
      <AvisoCadastro msg="Essa etapa está desabilitada para esse vínculo." />
    );
  }

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Formação</h3>

      {vinculo.mensagemEdicaoCadastro && (
        <AvisoCadastro msg={vinculo.mensagemEdicaoCadastro} vinculo={vinculo} />
      )}

      <Row>
        <Col md="12" style={{ textAlign: 'right' }}>
          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              setModalFormacao(true);
              setFormacaoEdicao({});
            }}
            disabled={!vinculo.habilitaEditarCadastro}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <br />
      <br />
      <Table columns={columnsFormacoes} data={formacoes} />
      <br />
      <br />
      <h5>Anexos</h5>
      <Anexos idBlocoDados={6} />
      <br />
      <br />
      <Modal
        show={modalFormacao}
        onHide={() => {
          setNomeCurso();
          setModalFormacao(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Formação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              {isFieldAvailable(
                'Formacao_IdNivelEscolaridade',
                camposParametrizados
              ) && (
                <Row>
                  <Col md="12">
                    <Form.Label>Nível de escolaridade</Form.Label>
                    <Form.Control
                      name="nivelEscolaridade"
                      ref={register()}
                      as="select"
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="Selecione" />
                      {niveisEscolaridade.map((nivel) => (
                        <option key={nivel.id} value={nivel.id}>
                          {nivel.descricao}
                        </option>
                      ))}
                    </Form.Control>
                    <span className="errorValidation">
                      {errors.nivelEscolaridade && 'Preencha este campo'}
                    </span>
                  </Col>
                </Row>
              )}
              <br />

              {isFieldAvailable(
                'Formacao_Instituicao',
                camposParametrizados
              ) && (
                <Row>
                  <Col md="12">
                    <Form.Label>Instituição</Form.Label>
                    <Form.Control
                      name="instituicao"
                      ref={register()}
                      disabled={!vinculo.habilitaEditarCadastro}
                      maxLength="100"
                    />
                  </Col>
                </Row>
              )}

              <br />

              {isFieldAvailable('Formacao_Curso', camposParametrizados) && (
                <Row>
                  <Col md="12">
                    <Form.Label>Curso</Form.Label>
                    <Form.Control
                      name="curso"
                      ref={register()}
                      as="select"
                      onChange={(e) => {
                        selectNomeCurso(e);
                      }}
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="Selecione" />
                      {cursos.map((curso) => (
                        <option key={curso.id} value={curso.id}>
                          {curso.descricao}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              )}

              <br />

              {isFieldAvailable('Formacao_IdPais', camposParametrizados) && (
                <Row>
                  <Col md="12">
                    <Form.Label>País onde estudou</Form.Label>
                    <Form.Control
                      name="pais"
                      as="select"
                      ref={register()}
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="Selecione" />
                      {paises.map((pais) => (
                        <option key={pais.id} value={pais.id}>
                          {pais.descricao}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              )}

              <br />

              <Row>
                {isFieldAvailable(
                  'Formacao_DataInicio',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>Data Início</Form.Label>
                    <Form.Control
                      name="dataInicio"
                      ref={register()}
                      placeholder="__/__/____"
                      onInput={(e) => mascara(e.target, 'data')}
                      onBlur={(e) => isValidDate(e.target)}
                      disabled={!vinculo.habilitaEditarCadastro}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </Col>
                )}

                {isFieldAvailable(
                  'Formacao_DataTermino',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>Data Término</Form.Label>
                    <Form.Control
                      name="dataTermino"
                      ref={register()}
                      placeholder="__/__/____"
                      onInput={(e) => mascara(e.target, 'data')}
                      onBlur={(e) => isValidDate(e.target)}
                      disabled={!vinculo.habilitaEditarCadastro}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </Col>
                )}
              </Row>
              <br />
              <br />

              {isFieldAvailable(
                'Formacao_IdSituacaoFormacao',
                camposParametrizados
              ) && (
                <Row>
                  <Col md="12">
                    <Form.Label>Situação</Form.Label>

                    {situacoesFormacao.map((situacao) => (
                      <Form.Check
                        name="situacao"
                        ref={register()}
                        type="radio"
                        key={situacao.id}
                        value={situacao.id}
                        label={situacao.descricao}
                        disabled={!vinculo.habilitaEditarCadastro}
                      />
                    ))}
                  </Col>
                </Row>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setNomeCurso();
              setModalFormacao(false);
            }}
          >
            Fechar
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit(handleAdicionarFormacao)}
            disabled={!vinculo.habilitaEditarCadastro || loadingSalvarCadastro}
          >
            {loadingSalvarCadastro ? 'Aguarde...' : 'Salvar'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
