/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { toast } from 'react-toastify';
import { api } from '../../../services/api';
import Table from '../../../components/Table';
import Anexos from '../../../components/Anexos';

import AvisoCadastro from '../../../components/AvisoCadastro';
import LoadingPage from '../../../components/LoadingPage';

import { useParametrizacao } from '../../../contexts/parametrizacao';
import { useEtapasVinculo } from '../../../contexts/etapasVinculo';

import {
  isFieldAvailable,
  mascara,
  isValidDate,
  stringToDate,
  maskCpf,
  dateValid,
} from '../../../utils/index';
import { useVinculo } from '../../../contexts/vinculo';

export default function Dependentes() {
  const [camposParametrizados, setCamposParametrizados] = useState([]);
  const [dependentes, setDependentes] = useState([]);
  const [generos, setGeneros] = useState([]);
  const [paises, setPaises] = useState([]);
  const [tipoDependentes, setTipoDependentes] = useState([]);
  const [cpf, setCpf] = useState('');
  const [modalDependente, setModalDependente] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSalvarCadastro, setLoadingSalvarCadastro] = useState(false);
  const [dependenteEdicao, setDependenteEdicao] = useState({});
  const [camposDesabilitados, setCamposDesabilitados] = useState(false);

  const key = localStorage.getItem('@EPF2:keyTitular');
  const { parametrizacao } = useParametrizacao();

  const { handleSubmit, register, reset, errors } = useForm({
    mode: 'onBlur',
  });
  const { vinculo } = useVinculo();
  const { etapasVinculo } = useEtapasVinculo();

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchDependentes() {
    try {
      const res = await api.get(`cadastroapi/api/v1/Dependentes/${key}`);
      return setDependentes(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível exibir a lista de dependentes.');
    }
  }

  async function fetchGenero() {
    try {
      const res = await api.get(`dicionarioapi/api/v1/Dicionarios/generos`);
      return setGeneros(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível exibir a lista de gêneros.');
    }
  }

  async function fetchPaises() {
    try {
      const res = await api.get(`dicionarioapi/api/v1/Dicionarios/tipos-pais`);
      return setPaises(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível exibir a lista de países.');
    }
  }

  async function fetchTiposDependente() {
    try {
      const res = await api.get(
        `dicionarioapi/api/v1/Dicionarios/tipos-dependente`
      );
      return setTipoDependentes(res.data.result);
    } catch (err) {
      return toast.error(
        'Não foi possível exibir a lista de tipos de dependentes.'
      );
    }
  }

  async function fetchDependenteDetalhes(idDependente) {
    try {
      const res = await api.get(
        `cadastroapi/api/v1/Dependentes/${key}/${idDependente}/detalhes`
      );
      return setDependenteEdicao(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível exibir os detalhes do dependente.');
    }
  }

  async function handleAdicionarDependente(data) {
    if (!dateValid) {
      return toast.error('Data preenchida inválida.');
    }

    const dependente = {
      keyTitularDados: key,
      nomeCompleto: data.nomeDependente,
      idTipoDependente: Number(data.tipoDependente),
      dependenteIRRF: data.dependenteIrpf
        ? JSON.parse(data.dependenteIrpf)
        : null,
      pensaoAlimenticia: data.pensaoAlimenticia
        ? JSON.parse(data.pensaoAlimenticia)
        : null,
      cpfNumero: data.cpf.replace(/\D/g, ''),
      dataNascimento: stringToDate(data.dataNascimento),
      idGenero: Number(data.sexo),
      idPaisNascimento: Number(data.paisDeNascimento),
      nomeMae: data.nomeMaeDependente,
    };

    try {
      if (dependenteEdicao.id) {
        const dependentePut = {
          id: dependenteEdicao.id,
          ...dependente,
        };
        setLoadingSalvarCadastro(true);
        await api.put('cadastroapi/api/v1/Dependentes', dependentePut);
        setLoadingSalvarCadastro(false);
        toast.success('Cadastro salvo.');
      } else {
        setLoadingSalvarCadastro(true);
        await api.post('cadastroapi/api/v1/Dependentes', dependente);
        setLoadingSalvarCadastro(false);
        toast.success('Cadastro salvo.');
      }

      setModalDependente(false);
      return fetchDependentes(key);
    } catch (err) {
      setLoadingSalvarCadastro(false);
      if (typeof err.response.data.error === 'object') {
        return err.response.data.error.map((item) => toast.error(item));
      }
      return toast.error(err.response.data.error);
    }
  }

  async function handlerRemover(id) {
    try {
      await api.delete(`cadastroapi/api/v1/Dependentes/${key}/${id}`);
      toast.success('Deletado com sucesso');
      return fetchDependentes(key);
    } catch (err) {
      return toast.error('Não foi possível deletar');
    }
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchGenero();
    await fetchPaises();
    await fetchTiposDependente();
    await fetchDependentes();
    setLoadingPage(false);
  }, []);

  useEffect(() => {
    setCamposParametrizados(parametrizacao[5]?.campos);
    const campos = parametrizacao[5]?.campos.every(
      (field) => field.isObrigatorio === false
    );
    setCamposDesabilitados(campos);
  }, [parametrizacao]);

  useEffect(() => {
    if (dependenteEdicao.id) {
      reset({
        nomeDependente: dependenteEdicao.nomeCompleto,
        tipoDependente: dependenteEdicao.idTipoDependente,
        sexo: dependenteEdicao.idGenero,
        cpf: maskCpf(dependenteEdicao.cpfNumero),
        paisDeNascimento: dependenteEdicao.idPaisNascimento,
        nomeMaeDependente: dependenteEdicao.nomeMae,
        dependenteIrpf: dependenteEdicao.dependenteIRRF,
        pensaoAlimenticia: dependenteEdicao.pensaoAlimenticia,
        dataNascimento: dependenteEdicao.dataNascimento,
      });
    } else {
      reset({});
      setCpf('');
    }
  }, [dependenteEdicao]);

  useEffect(() => {
    console.log(dependenteEdicao);
  }, [dependenteEdicao]);

  const columnsDependentes = [
    {
      id: 'nomeCompleto',
      Header: 'Nome',
      accessor: 'nomeCompleto',
    },
    {
      id: 'dependenteDescricao',
      Header: 'Tipo',
      accessor: 'dependenteDescricao',
    },
    {
      id: 'dependentePaisNascimentoDescricao',
      Header: 'País de Nascimento',
      accessor: 'dependentePaisNascimentoDescricao',
    },
    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              fetchDependenteDetalhes(rows.row.original.id);
              setModalDependente(true);
            }}
          >
            {!vinculo.habilitaEditarCadastro ? 'Visualizar' : 'Editar'}
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => handlerRemover(rows.row.original.id)}
            disabled={!vinculo.habilitaEditarCadastro}
          >
            Remover
          </Button>
        </>
      ),
    },
  ];

  if (vinculo.idCadastroStatus === 6) {
    return (
      <AvisoCadastro msg="Esse vínculo foi excluído, por esse motivo essa etapa não pode ser acessada." />
    );
  }

  if (etapasVinculo?.blocos?.[4].isAtivo && camposDesabilitados) {
    return <AvisoCadastro msg="Nenhum campo habilitado para preenchimento." />;
  }

  if (etapasVinculo?.blocos && !etapasVinculo?.blocos?.[4].isAtivo) {
    return (
      <AvisoCadastro msg="Essa etapa está desabilitada para esse vínculo." />
    );
  }

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Dependentes</h3>

      {vinculo.mensagemEdicaoCadastro && (
        <AvisoCadastro msg={vinculo.mensagemEdicaoCadastro} vinculo={vinculo} />
      )}

      <Row>
        <Col md="12" style={{ textAlign: 'right' }}>
          <Button
            variant="primary"
            onClick={() => {
              setModalDependente(true);
              setDependenteEdicao({});
            }}
            disabled={!vinculo.habilitaEditarCadastro}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <br />
      <br />
      <Table columns={columnsDependentes} data={dependentes} />
      <br />
      <br />
      <h5>Anexos</h5>
      <Anexos idBlocoDados={5} />
      <br />
      <br />
      <Modal show={modalDependente} onHide={() => setModalDependente(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Dependente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(handleAdicionarDependente)}>
            <Form.Group>
              <Row>
                {isFieldAvailable(
                  'Dependente_NomeCompleto',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      name="nomeDependente"
                      ref={register()}
                      disabled={!vinculo.habilitaEditarCadastro}
                      maxLength="100"
                    />

                    <span className="errorValidation">
                      {errors.nomeDependente && 'Preencha este campo'}
                    </span>
                  </Col>
                )}

                {isFieldAvailable(
                  'Dependente_IdTipoDependente',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>Tipo</Form.Label>
                    <Form.Control
                      name="tipoDependente"
                      ref={register()}
                      as="select"
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="Selecione" />
                      {tipoDependentes.map((tipo) => (
                        <option key={tipo.id} value={tipo.id}>
                          {tipo.descricao}
                        </option>
                      ))}
                    </Form.Control>

                    <span className="errorValidation">
                      {errors.tipoDependente && 'Preencha este campo'}
                    </span>
                  </Col>
                )}
              </Row>

              <br />

              <Row>
                {isFieldAvailable(
                  'Dependente_IdGenero',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>Sexo</Form.Label>
                    <Form.Control
                      name="sexo"
                      ref={register()}
                      as="select"
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="Selecione" />
                      {generos.map((genero) => (
                        <option key={genero.id} value={genero.id}>
                          {genero.descricao}
                        </option>
                      ))}
                    </Form.Control>

                    <span className="errorValidation">
                      {errors.sexo && 'Preencha este campo'}
                    </span>
                  </Col>
                )}

                {isFieldAvailable('Dependente_CPF', camposParametrizados) && (
                  <Col md="6">
                    <Form.Label>CPF</Form.Label>
                    <Form.Control
                      name="cpf"
                      ref={register()}
                      onChange={(e) => setCpf(maskCpf(e.target.value))}
                      value={cpf || dependenteEdicao.cpf}
                      disabled={!vinculo.habilitaEditarCadastro}
                      maxLength="100"
                    />
                    <span className="errorValidation">
                      {errors.cpf && 'Preencha este campo'}
                    </span>
                  </Col>
                )}
              </Row>

              <br />

              <Row>
                {isFieldAvailable(
                  'Dependente_DataNascimento',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label column md="12" className="no-padding">
                      Data de Nascimento
                    </Form.Label>
                    <Form.Control
                      name="dataNascimento"
                      ref={register()}
                      placeholder="__/__/____"
                      onInput={(e) => mascara(e.target, 'data')}
                      onBlur={(e) => isValidDate(e.target)}
                      disabled={!vinculo.habilitaEditarCadastro}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </Col>
                )}

                {isFieldAvailable(
                  'Dependente_IdPaisNascimento',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>País de Nascimento</Form.Label>
                    <Form.Control
                      name="paisDeNascimento"
                      ref={register()}
                      as="select"
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="Selecione" />
                      {paises.map((pais) => (
                        <option key={pais.id} value={pais.id}>
                          {pais.descricao}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                )}
              </Row>

              <br />
              {isFieldAvailable('Dependente_NomeMae', camposParametrizados) && (
                <Row>
                  <Col md="12">
                    <Form.Label>Nome da Mãe </Form.Label>
                    <Form.Control
                      name="nomeMaeDependente"
                      ref={register()}
                      disabled={!vinculo.habilitaEditarCadastro}
                      maxLength="100"
                    />
                  </Col>
                </Row>
              )}

              <br />

              <Row>
                {isFieldAvailable(
                  'Dependente_IsDependenteIRRF',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>Dependente de IRPF?</Form.Label>
                    <Form.Control
                      name="dependenteIrpf"
                      ref={register()}
                      as="select"
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="Selecione" />
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </Form.Control>
                  </Col>
                )}

                {isFieldAvailable(
                  'Dependente_IsPensaoAlimenticia',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>Paga Pensão Alimentícia?</Form.Label>
                    <Form.Control
                      name="pensaoAlimenticia"
                      ref={register()}
                      as="select"
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="Selecione" />
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </Form.Control>
                  </Col>
                )}
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalDependente(false)}>
            Fechar
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit(handleAdicionarDependente)}
            disabled={!vinculo.habilitaEditarCadastro || loadingSalvarCadastro}
          >
            {loadingSalvarCadastro ? 'Aguarde...' : 'Salvar'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
