/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Table from '../../components/Table';
import { api } from '../../services/api';

export default function HistoricoTratamentoDados() {
  const [listaTratamentoDados, setListaTratamentoDados] = useState([]);
  const { key } = useParams();

  // async function fetchListaTratamentoDados() {
  //   try {
  //     const res = await api.get(`cadastro/tratamentoDados/historico/${key}`);
  //     return setListaTratamentoDados(res.data.result);
  //   } catch (error) {
  //     return toast.error('Não foi possível exibir a lista de históricos');
  //   }
  // }

  // useEffect(() => {
  //   fetchListaTratamentoDados();
  // }, []);

  const columsListaTratamentoDados = [
    {
      id: 'data',
      Header: 'DATA',
      accessor: 'data',
    },
    {
      id: 'tipo',
      Header: 'Tipo de tratamento',
      accessor: 'tipo',
    },
    {
      id: 'descricao',
      Header: 'Descrição',
      accessor: 'descricao',
    },
  ];
  return (
    <Form>
      <Form.Group>
        <h3>Histórico de Tratamento de Dados</h3>
        <br />

        <Table
          columns={columsListaTratamentoDados}
          data={listaTratamentoDados}
        />
      </Form.Group>
    </Form>
  );
}
