/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';

import LoginLayout from '../layouts/login';
import DefaultLayout from '../layouts/default';

import {
  getAccessToken,
  renovaTokenAutomaticamente,
  timeToken,
} from '../utils/index';

import { useAuth } from '../contexts/auth';

import { api, apiBolsas } from '../services/api';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isLayout,
  ...rest
}) {
  const { renewToken, signOut } = useAuth();

  const signed = localStorage.getItem('@EPF2Auth:user');

  if (!signed && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/inicio" />;
  }

  const Layout = signed ? DefaultLayout : LoginLayout;

  api.interceptors.request.use(
    async (config) => {
      if (
        getAccessToken() &&
        !renovaTokenAutomaticamente(getAccessToken(), 20)
      ) {
        config.headers.Authorization = `Bearer ${getAccessToken()}`;
      }

      if (
        getAccessToken() &&
        renovaTokenAutomaticamente(getAccessToken(), 20)
      ) {
        await renewToken();
        config.headers.Authorization = `Bearer ${getAccessToken()}`;
      }

      if (getAccessToken() && timeToken(getAccessToken()) <= 0) {
        signOut();
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  apiBolsas.interceptors.request.use(
    (config) => {
      if (getAccessToken()) {
        config.headers.Authorization = `Bearer ${getAccessToken()}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isLayout: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};

RouteWrapper.defaultProps = {
  isLayout: false,
};
