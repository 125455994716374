/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import { Container, Row, Form, Col, Button, Alert } from 'react-bootstrap';

import { equals, isStrongPassword, isEmail } from 'validator';

import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Wrapper, Footer } from './styles';

import fiotec from '../../images/logo-fiotec.svg';
import { api } from '../../services/api';

export default function RedefinirSenha() {
  const { key } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingDadosTitular, setLoadingDadosTitular] = useState(true);
  const [dadosTitular, setDadosTitular] = useState({});

  const { handleSubmit, register, errors, reset } = useForm({
    mode: 'onBlur',
  });

  async function fetchDadosTitular() {
    try {
      const res = await api.get(
        'conviteapi/api/Acesso/verifica-recuperacao-email/',
        {
          params: {
            chave: key,
          },
        }
      );
      setLoadingDadosTitular(false);
      return setDadosTitular(res.data.result);
    } catch (err) {
      setLoadingDadosTitular(false);
      return '';
    }
  }

  function validarForcaSenha(senha) {
    if (
      isStrongPassword(senha, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      })
    ) {
      return true;
    }

    toast.error(
      'A senha precisa conter no mínimo 8 caracteres com pelo menos uma letra maiúscula, uma minúscula e um número.'
    );

    return false;
  }

  function validarRepeticaoSenha(senha, senhaConfirmacao) {
    if (equals(senha, senhaConfirmacao)) {
      return true;
    }

    toast.error('As senhas precisam ser iguais');

    return false;
  }

  function emailValido(email) {
    if (isEmail(email)) {
      return true;
    }

    toast.error('Informe um e-mail válido');

    return false;
  }

  async function handleCadastrar(data) {
    const model = {
      chave: key,
      email: dadosTitular.email,
      novaSenha: data.senha,
      confirmacaoNovaSenha: data.senhaConfirmacao,
    };

    if (
      emailValido(dadosTitular.email) &&
      validarForcaSenha(data.senha) &&
      validarRepeticaoSenha(data.senha, data.senhaConfirmacao)
    ) {
      try {
        setLoading(true);
        await api.patch(`conviteapi/api/Acesso/redefinicao-senha/`, model);
        return toast.success('Senha alterada. Redirecionando para o login...', {
          autoClose: 3000,
          onClose: () => history.push(`/`),
        });
      } catch (err) {
        setLoading(false);
        return toast.error('Falha ao realizar a alteração.');
      }
    }

    return '';
  }

  useEffect(() => {
    fetchDadosTitular();
  }, []);

  useEffect(() => {
    reset({
      nome: dadosTitular.nomeDestinatario,
      email: dadosTitular.email,
      cpf: dadosTitular.cpf,
    });
  }, [dadosTitular]);

  return (
    <>
      <Container className="login">
        <Row>
          <Wrapper>
            <div className="logo">
              <img src={fiotec} className="logoFiotec" alt="Logo Fiotec" />
              <br />
              {/* <img src={pacto} alt="Logo Pacto" /> */}
              <h1>EPF2</h1>
              <h5>Redefinir Senha</h5>
            </div>

            {!loadingDadosTitular && (
              <>
                {!dadosTitular.cpf || dadosTitular.isUtilizado ? (
                  <Alert
                    variant="danger"
                    style={{
                      marginTop: '30px',
                      lineHeight: '20px',
                      textAlign: 'center',
                    }}
                  >
                    <>
                      {!dadosTitular.cpf && <p>Link inválido.</p>}

                      {dadosTitular.cpf && dadosTitular.isUtilizado && (
                        <p>
                          O link é inválido pois já foi utilizado antes.{' '}
                          <a href="/">Clique aqui</a> para fazer a sua
                          autenticação. Se o processo de redefinição de senha
                          não foi realizado, solicite um novo link através da
                          página de login.
                        </p>
                      )}
                    </>
                  </Alert>
                ) : (
                  <Form sm="6" onSubmit={handleSubmit(handleCadastrar)}>
                    <Form.Group>
                      <Form.Label column sm="12">
                        Nome
                      </Form.Label>
                      <Col sm="12">
                        <Form.Control
                          type="text"
                          name="nome"
                          ref={register()}
                          disabled
                        />
                        <span className="errorValidation">
                          {errors.nome && 'Informe o nome'}
                        </span>
                      </Col>

                      <Form.Label column sm="12">
                        E-mail
                      </Form.Label>
                      <Col sm="12">
                        <Form.Control
                          type="text"
                          name="email"
                          ref={register()}
                          disabled
                        />
                      </Col>

                      <Form.Label column sm="12">
                        CPF
                      </Form.Label>
                      <Col sm="12">
                        <Form.Control
                          type="text"
                          name="cpf"
                          ref={register()}
                          disabled
                        />
                      </Col>

                      <Form.Label column sm="12">
                        Senha
                      </Form.Label>
                      <Col sm="12">
                        <Form.Control
                          type="password"
                          name="senha"
                          ref={register({ required: true })}
                        />
                        <span className="errorValidation">
                          {errors.senha && 'Informe a senha'}
                        </span>
                      </Col>

                      <Form.Label column sm="12">
                        Confirme sua senha
                      </Form.Label>
                      <Col sm="12">
                        <Form.Control
                          type="password"
                          name="senhaConfirmacao"
                          ref={register({ required: true })}
                        />
                        <span className="errorValidation">
                          {errors.senhaConfirmacao &&
                            'Informe a confirmação de senha'}
                        </span>
                      </Col>

                      <br />
                      <br />

                      <Col sm="12">
                        <Button
                          variant="primary"
                          type="submit"
                          block
                          disabled={loading}
                        >
                          {loading ? 'Aguarde...' : 'Alterar'}
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                )}
              </>
            )}
          </Wrapper>
        </Row>

        <Footer>
          <p>Fundação de apoio à Fiocruz - Fiotec</p>
          <p>Rio de Janeiro, Avenida Brasil, 4036, Manguinhos</p>
        </Footer>
      </Container>
    </>
  );
}
