import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  border-radius: 4px;
  background: #fff;
  padding: 20px;
  width: 340px;
  margin: 100px auto;
`;

export const Footer = styled.footer`
  margin-top: -50px;
  margin-bottom: 50px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  p {
    margin-bottom: 10px;
  }
`;
