/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { api } from '../../services/api';

export default function InformacoesProjeto({ keyContrato }) {
  const [informacoesProjeto, setInformacoesProjeto] = useState({});

  useEffect(async () => {
    if (keyContrato) {
      const res = await api.get(
        `/ConsultaAPI/consultas/contratos/${keyContrato}/detalhes`
      );
      setInformacoesProjeto(res.data.result);
    }
  }, [keyContrato]);

  return (
    <>
      <Form.Group>
        <Row>
          <Col md="4">
            <Form.Label>Nº da Bolsa</Form.Label>
            <Form.Control
              name="numeroBolsa"
              readOnly
              defaultValue={informacoesProjeto?.numeroContrato}
            />
          </Col>
          <Col md="4">
            <Form.Label>Projeto</Form.Label>
            <Form.Control
              name="projeto"
              readOnly
              defaultValue={informacoesProjeto?.projeto}
            />
          </Col>
          <Col md="4">
            <Form.Label>Subprojeto</Form.Label>
            <Form.Control
              name="subprojeto"
              readOnly
              defaultValue={informacoesProjeto?.subProjeto}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="12">
            <Form.Label>Título do Projeto</Form.Label>
            <Form.Control
              name="tituloProjeto"
              readOnly
              defaultValue={informacoesProjeto?.tituloProjeto}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="4">
            <Form.Label>Coordenador</Form.Label>
            <Form.Control
              name="coordenador"
              readOnly
              defaultValue={informacoesProjeto?.coordenador}
            />
          </Col>
          <Col md="4">
            <Form.Label>PEP</Form.Label>
            <Form.Control
              name="pep"
              readOnly
              defaultValue={informacoesProjeto?.pep}
            />
          </Col>
          <Col md="4">
            <Form.Label>Vigência da Bolsa</Form.Label>
            <Form.Control
              name="vigencia"
              readOnly
              defaultValue={informacoesProjeto?.vigencia}
            />
          </Col>
        </Row>

        <br />
        <Row>
          <Col md="6">
            <Form.Label>Nome do Bolsista</Form.Label>
            <Form.Control
              name="nomeTitularDados"
              readOnly
              defaultValue={informacoesProjeto?.nomeTitularDados}
            />
          </Col>
          <Col md="6">
            <Form.Label>CPF</Form.Label>
            <Form.Control
              name="cpf"
              readOnly
              defaultValue={informacoesProjeto?.cpf}
            />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
}
