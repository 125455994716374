/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import { FaExclamationTriangle } from 'react-icons/fa';

let resolve;

const Confirm = ({ refShow }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirmProps, setShowConfirmProps] = useState({});

  const defaultProps = {
    title: 'Confirmação',
    message: 'Deseja realizar essa operação?',
  };

  const body = document.querySelector('body');

  const handleClose = () => {
    setIsOpen(false);
    body.classList.remove('modal-open');
  };

  const handleCancel = () => {
    setIsOpen(false);
    body.classList.remove('modal-open');
    resolve(false);
  };

  const handleConfirm = () => {
    setIsOpen(false);
    body.classList.remove('modal-open');
    resolve(true);
  };

  const showCb = (props) => {
    setShowConfirmProps({ ...props });
    body.classList.add('modal-open');

    setIsOpen(true);
    return new Promise((res) => (resolve = res));
  };

  useEffect(() => {
    refShow(showCb);
  }, []);

  return (
    <>
      <div className={!isOpen ? 'modal confirm' : 'modal confirm is-active'}>
        <div className="modal-background" />

        <div className="modal-card">
          <OutsideClickHandler onOutsideClick={handleClose}>
            <header className="modal-card-head">
              <FaExclamationTriangle />
              <h3 className="modal-card-title">
                {showConfirmProps.title || defaultProps.title}
              </h3>
            </header>
            <section className="modal-card-body">
              <p className="modal-card-title">
                {showConfirmProps.message || defaultProps.message}
              </p>
            </section>
            <footer className="modal-card-foot">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={handleConfirm}
              >
                Continuar
              </button>
              <button
                type="button"
                className="btn btn-default btn-sm"
                onClick={handleCancel}
              >
                Cancelar
              </button>
            </footer>
          </OutsideClickHandler>
        </div>
      </div>
    </>
  );
};

Confirm.create = (props = {}) => {
  const containerElement = document.createElement('div');
  document.body.appendChild(containerElement);

  const ref = { show: null };
  render(
    <Confirm refShow={(el) => (ref.show = el)} createConfirmProps={props} />,
    containerElement
  );

  return ref;
};

export default Confirm;
