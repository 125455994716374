/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Form,
  Col,
  Button,
  Modal,
  Alert,
} from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';

import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/auth';

import { Wrapper, Footer } from './styles';

import fiotec from '../../images/logo-fiotec.svg';
import epf from '../../images/logo-epf2.png';
import { maskCpf } from '../../utils/index';
import { api } from '../../services/api';

export default function Login() {
  const { signIn } = useAuth();
  const [modalPerfis, setModalPerfis] = useState(false);

  const [ambienteTeste, setAmbienteTeste] = useState(false);

  const [emailLogin, setEmailLogin] = useState('');
  const [emailRecuperar, setEmailRecuperar] = useState('');
  const [loginForce, setLoginForce] = useState('');

  const [senha, setSenha] = useState('');
  const [cpf, setCpf] = useState('');

  const [modalRecuperar, setModalRecuperar] = useState(0);
  const [cadastroEncontrado, setCadastroEncontrado] = useState(false);

  const [loadingRecuperar, setLoadingRecuperar] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleLogin() {
    if (emailLogin.length <= 0 || senha.length <= 0) {
      return toast.error('Digite o e-mail e a senha');
    }

    setLoading(true);
    await signIn(emailLogin, senha);
    return setLoading(false);
  }

  async function handleloginForce() {
    setLoading(true);
    await signIn(loginForce, '', true);
    setLoading(false);
  }

  async function handleRecuperarEmail() {
    try {
      setLoadingRecuperar(true);
      const res = await api.get(
        `ConviteAPI/api/Acesso/${cpf}/recuperacao-email`
      );
      setLoadingRecuperar(false);
      return setCadastroEncontrado(res.data.result);
    } catch (error) {
      setLoadingRecuperar(false);
      setCadastroEncontrado(false);
      return toast.error('Nenhum cadastro encontrado para o CPF informado.');
    }
  }

  async function handleRecuperarSenha() {
    try {
      setLoadingRecuperar(true);
      await api.post(`ConviteAPI/api/Acesso/solicitacao-recuperacao-senha`, {
        email: emailRecuperar,
      });
      setLoadingRecuperar(false);
      setModalRecuperar(false);
      return toast.success(
        'Siga as instruções do e-mail enviado para recuperação de senha. '
      );
    } catch (error) {
      setLoadingRecuperar(false);
      return toast.error('Não foi possível enviar o e-mail.');
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_CONTEXT !== 'production') {
      setAmbienteTeste(true);
    }
  }, []);

  return (
    <>
      <Container className="login">
        <Row>
          <Wrapper>
            <div className="logo">
              <img src={fiotec} className="logoFiotec" alt="Logo Fiotec" />
              <br />
              <img src={epf} alt="Logo EPF 2" />
            </div>

            {/* <Alert variant="warning">
              No momento, o servidor está fora. Em breve o sistema estará
              novamente disponível.
            </Alert> */}

            <Form sm="6" autoComplete="on">
              <Form.Group>
                <Form.Label column sm="12">
                  E-mail
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={(e) => setEmailLogin(e.target.value)}
                  />
                </Col>

                <Form.Label column sm="12">
                  Senha
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    type="password"
                    name="senha"
                    onChange={(e) => setSenha(e.target.value)}
                  />
                </Col>

                <br />
                <br />
                <Col sm="12">
                  {ambienteTeste && (
                    <Button block onClick={() => setModalPerfis(true)}>
                      {' '}
                      Login Force
                    </Button>
                  )}

                  <Button
                    block
                    variant="success"
                    disabled={loading}
                    onClick={() => handleLogin()}
                  >
                    {loading ? 'Autenticando..' : 'Acessar'}
                  </Button>
                </Col>
                <br />
                <Col sm="12">
                  <a
                    href="#recuperarSenha"
                    onClick={() => {
                      setModalRecuperar(2);
                      setCadastroEncontrado(false);
                    }}
                  >
                    Recuperar e-mail
                  </a>
                </Col>
                <Col sm="12">
                  <a
                    href="#recuperarEmail"
                    onClick={() => setModalRecuperar(1)}
                  >
                    Recuperar senha
                  </a>
                </Col>
              </Form.Group>
            </Form>
          </Wrapper>
        </Row>

        <Footer>
          <p>Fundação de apoio à Fiocruz - Fiotec</p>
          <p>Rio de Janeiro, Avenida Brasil, 4036, Manguinhos</p>
        </Footer>
      </Container>

      <Modal
        show={modalRecuperar > 0}
        onHide={() => {
          setCpf('');
          setEmailRecuperar('');
          setModalRecuperar(0);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalRecuperar === 1 ? 'Recuperar senha' : 'Recuperar e-mail'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalRecuperar === 1 && (
            <Row>
              <Col md="6">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  onChange={(e) => setEmailRecuperar(e.target.value)}
                  value={emailRecuperar}
                  type="email"
                  name="email"
                  maxLength="100"
                />
              </Col>
              <Col md="6">
                <Button
                  type="submit"
                  size="sm"
                  variant="primary"
                  onClick={() => handleRecuperarSenha()}
                  style={{ marginTop: '26px', padding: '6px 8px' }}
                  disabled={loadingRecuperar}
                >
                  {loadingRecuperar ? 'Aguarde ' : 'Recuperar senha'}
                </Button>
              </Col>
            </Row>
          )}

          {modalRecuperar === 2 && (
            <>
              <Row>
                <Col md="6">
                  <Form.Label>CPF</Form.Label>
                  <Form.Control
                    onChange={(e) => setCpf(maskCpf(e.target.value))}
                    value={cpf}
                    name="numeroCpf"
                    maxLength="100"
                  />
                </Col>
                <Col md="6">
                  <Button
                    type="submit"
                    size="sm"
                    variant="primary"
                    onClick={() => handleRecuperarEmail()}
                    style={{ marginTop: '26px', padding: '6px 8px' }}
                    disabled={loadingRecuperar}
                  >
                    {loadingRecuperar ? 'Aguarde ' : 'Recuperar e-mail'}
                  </Button>
                </Col>
              </Row>

              {cadastroEncontrado && (
                <>
                  <br />
                  <br />

                  <Row>
                    <Col md="12">
                      <Alert variant="success">
                        <p>
                          <FaInfoCircle /> <strong>Cadastro Encontrado</strong>
                        </p>
                        <p>{cadastroEncontrado}</p>
                      </Alert>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          <br />
        </Modal.Body>
      </Modal>

      {/*
        Modal perfis para testes
       */}
      <Modal show={modalPerfis} onHide={() => setModalPerfis(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Perfis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? <Alert variant="info">Logando, aguarde...</Alert> : ''}

          <Form.Label>Login</Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => setLoginForce(e.target.value)}
          />

          <br />

          <Button block onClick={() => handleloginForce()}>
            Acessar
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setModalPerfis(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
