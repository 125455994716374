/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Table from '../../../components/Table';
import { api } from '../../../services/api';
import { useVinculo } from '../../../contexts/vinculo';
import LoadingPage from '../../../components/LoadingPage';
import AvisoCadastro from '../../../components/AvisoCadastro';

export default function TabelaSolicitarCancelamento() {
  const history = useHistory();
  const [listaBolsasTitular, setListaBolsasTitular] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);

  const key = localStorage.getItem('@EPF2:keyTitular');
  const { vinculo } = useVinculo();

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchListaBolsasTitular() {
    try {
      const res = await api.get(`/ConsultaAPI/contratos/${key}`);
      setListaBolsasTitular(res.data.result);
    } catch (err) {
      toast.error('Não foi possível carregar a lista de contratos.');
    }
  }

  async function handleIniciarCancelamento(keyContrato, idContrato) {
    try {
      const res = await api.post('/solicitacaoAPI/api/cancelamentos-bolsas', {
        idTitular: vinculo.id,
        idContrato,
        keyContrato,
      });

      let keySolicitacao;

      if (res.data.result) {
        keySolicitacao = res.data.result.keySolicitacao;
      }

      if (keySolicitacao) {
        toast.success('Cancelamento iniciado com sucesso! Redirecionando...', {
          autoClose: 1750,
          onClose: () =>
            history.push({
              pathname: `/bolsas/cancelamentos/solicitacao/${keySolicitacao}`,
              state: { acao: 'Criar' },
            }),
        });
      } else {
        toast.error(`Não foi possível criar o cancelamento.`);
      }
    } catch (error) {
      console.log(error);
      toast.error(`Não foi possível criar o cancelamento.`);
    }
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchListaBolsasTitular();
    setLoadingPage(false);
  }, []);

  const columnsListaBolsasTitular = [
    {
      id: 'numeroContrato',
      Header: 'Bolsa',
      accessor: 'numeroContrato',
    },
    {
      id: 'projeto',
      Header: 'Projeto',
      accessor: 'projeto',
    },
    {
      id: 'vigencia',
      Header: 'Vigência',
      accessor: 'vigencia',
    },
    {
      id: 'valor',
      Header: 'Valor',
      accessor: 'valor',
    },
    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          {rows.row.original.permiteCancelamento === true && (
            <Button
              variant="primary"
              size="sm"
              onClick={() =>
                handleIniciarCancelamento(
                  rows.row.original.keyContrato,
                  rows.row.original.idContrato
                )
              }
            >
              Solicitar
            </Button>
          )}
        </>
      ),
    },
  ];

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Solicitar Cancelamento de Bolsa</h3>
      <br />
      <Table columns={columnsListaBolsasTitular} data={listaBolsasTitular} />
    </>
  );
}
