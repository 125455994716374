import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { api } from '../../../services/api';
import { useParametrizacao } from '../../../contexts/parametrizacao';
import { isFieldAvailable, mascara } from '../../../utils';

import AvisoCadastro from '../../../components/AvisoCadastro';
import LoadingPage from '../../../components/LoadingPage';

import { useVinculo } from '../../../contexts/vinculo';
import { useEtapasVinculo } from '../../../contexts/etapasVinculo';

export default function Contato() {
  const [camposParametrizados, setCamposParametrizados] = useState([]);
  const [contato, setContato] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSalvarCadastro, setLoadingSalvarCadastro] = useState(false);
  const [camposDesabilitados, setCamposDesabilitados] = useState(false);

  const { handleSubmit, register } = useForm({
    mode: 'onBlur',
  });

  const { parametrizacao } = useParametrizacao();
  const { etapasVinculo } = useEtapasVinculo();
  const { vinculo } = useVinculo();

  const key = localStorage.getItem('@EPF2:keyTitular');

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchContato() {
    try {
      const res = await api.get(`cadastroapi/api/v1/Contatos/${key}/detalhes`);
      setContato(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível carregar as informações.');
    }

    return '';
  }

  async function handleSalvarContato(data) {
    try {
      setLoadingSalvarCadastro(true);
      await api.put(`cadastroapi/api/v1/Contatos`, {
        keyTitularDados: key,
        telefoneFixo: data.telefonefixo,
        telefoneCelular: data.telefonecelular,
        email: data.email,
      });
      toast.success('Cadastro salvo.');
      fetchContato();
      setLoadingSalvarCadastro(false);
      return fetchContato();
    } catch (err) {
      setLoadingSalvarCadastro(false);
      return toast.error('Não foi possível salvar o contato.');
    }
  }

  useEffect(async () => {
    setLoadingPage(true);
    await fetchContato(key);
    setLoadingPage(false);
  }, []);

  useEffect(() => {
    setCamposParametrizados(parametrizacao[3]?.campos);
    const campos = parametrizacao[3]?.campos.every(
      (field) => field.isObrigatorio === false
    );
    setCamposDesabilitados(campos);
  }, [parametrizacao]);

  if (vinculo.idCadastroStatus === 6) {
    return (
      <AvisoCadastro msg="Esse vínculo foi excluído, por esse motivo essa etapa não pode ser acessada." />
    );
  }

  if (etapasVinculo?.blocos?.[2].isAtivo && camposDesabilitados) {
    return <AvisoCadastro msg="Nenhum campo habilitado para preenchimento." />;
  }

  if (etapasVinculo?.blocos && !etapasVinculo?.blocos?.[2].isAtivo) {
    return (
      <AvisoCadastro msg="Essa etapa está desabilitada para esse vínculo." />
    );
  }

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Contato</h3>

      {vinculo.mensagemEdicaoCadastro && (
        <AvisoCadastro msg={vinculo.mensagemEdicaoCadastro} vinculo={vinculo} />
      )}

      <Form onSubmit={handleSubmit(handleSalvarContato)}>
        <Form.Group>
          <Row>
            {isFieldAvailable('Contato_TelefoneFixo', camposParametrizados) && (
              <Col md="4">
                <Form.Label>Telefone Fixo</Form.Label>
                <Form.Control
                  name="telefonefixo"
                  ref={register()}
                  placeholder="()____-____"
                  onInput={(e) => mascara(e.target, 'tel')}
                  defaultValue={contato && contato.telefoneFixo}
                  disabled={!vinculo.habilitaEditarCadastro}
                />
              </Col>
            )}
            {isFieldAvailable(
              'Contato_TelefoneCelular',
              camposParametrizados
            ) && (
              <Col md="4">
                <Form.Label>Telefone Celular</Form.Label>
                <Form.Control
                  name="telefonecelular"
                  placeholder="()_____-____"
                  onInput={(e) => mascara(e.target, 'tel')}
                  ref={register()}
                  defaultValue={contato && contato.telefoneCelular}
                  disabled={!vinculo.habilitaEditarCadastro}
                />
              </Col>
            )}
            {isFieldAvailable('Contato_Email', camposParametrizados) && (
              <Col md="4">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  name="email"
                  ref={register()}
                  defaultValue={contato && contato.email}
                  disabled={!vinculo.habilitaEditarCadastro}
                  maxLength="100"
                />
              </Col>
            )}
          </Row>
          <br />
          <br />
          <Row>
            <Col md="4">
              <Button
                type="submit"
                variant="success"
                disabled={
                  !vinculo.habilitaEditarCadastro || loadingSalvarCadastro
                }
              >
                {loadingSalvarCadastro ? 'Aguarde...' : 'Salvar Cadastro'}
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </>
  );
}
