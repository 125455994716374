/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';

import { Table, Row, Col, Button } from 'react-bootstrap';

import { Filter, DefaultColumnFilter } from './filters';

export default function ReactTable({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 10 },
    },

    useFilters,
    useSortBy,
    usePagination
  );

  const generateSortingIndicator = (column) =>
    column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';

  return (
    <>
      <Table bordered hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                  </div>
                  <Filter column={column} />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Row style={{ maxWidth: 1000, margin: '0 auto', textAlign: 'center' }}>
        <Col md={3} style={{ textAlign: 'left', padding: '0' }}>
          <Button
            onClick={previousPage}
            disabled={!canPreviousPage}
            variant={canPreviousPage ? 'link' : 'light'}
            size="sm"
          >
            Página Anterior
          </Button>
        </Col>
        <Col md={6} style={{ marginTop: 7 }}>
          Página{' '}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>
        </Col>

        <Col md={3} style={{ textAlign: 'right', padding: '0' }}>
          <Button
            onClick={nextPage}
            disabled={!canNextPage}
            variant={canNextPage ? 'link' : 'light'}
            size="sm"
          >
            Próxima Página
          </Button>
        </Col>
      </Row>

      <br />
      <br />
    </>
  );
}
