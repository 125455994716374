/* eslint-disable react/prop-types */
import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function LoadingPage() {
  return (
    <div>
      <Spinner animation="border" />
    </div>
  );
}
