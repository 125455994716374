import { format } from 'date-fns';

import React, { useState, useEffect } from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import fiotec from '../../../images/logo.png';
import { api } from '../../../services/api';
import Anexos from '../../../components/Anexos';

import { useVinculo } from '../../../contexts/vinculo';

import AvisoCadastro from '../../../components/AvisoCadastro';
import LoadingPage from '../../../components/LoadingPage';

export default function termoCompromisso() {
  const [dadosBasicos, setDadosBasicos] = useState({});
  const [localizacaoGeografica, setLocalizacaoGeografica] = useState({});
  const [documentoIdentificacao, setDocumentoIdentificacao] = useState({});
  const [contato, setContato] = useState({});
  const [modalTermo, setModalTermo] = useState(false);
  const [bloqueio, setBloqueio] = useState(true);
  const [avisoBrasileiro, setAvisoBrasileiro] = useState(false);
  const [avisoEstrangeiro, setAvisoEstrangeiro] = useState(false);
  const [avisoNacionalidade, setAvisoNacionalidade] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const { vinculo } = useVinculo();

  const key = localStorage.getItem('@EPF2:keyTitular');

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchDadosCompleto() {
    try {
      const res = await api.get(
        `cadastroapi/api/v1/Cadastros/${key}/dados-completos`
      );
      setDadosBasicos(res.data.result.dadosBasicos);
      setLocalizacaoGeografica(res.data.result.localizacaoGeografica);
      setDocumentoIdentificacao(res.data.result.documentoIdentificacao);
      setContato(res.data.result.contato);
    } catch (error) {
      return toast.error(
        'Não foi possível carregar seus dados para gerar o termo.'
      );
    }
    return '';
  }

  function printDiv(divName) {
    const printContents = document.getElementById(divName).innerHTML;
    const w = window.open();
    w.document.write(printContents);
    w.print();
    w.close();
  }

  const dataAtual = format(new Date(), 'dd/MM/yyyy');

  useEffect(async () => {
    setLoadingPage(true);
    await fetchDadosCompleto();
    setLoadingPage(false);
  }, []);

  useEffect(() => {
    if (dadosBasicos?.nacionalidade === null) {
      setAvisoNacionalidade(true);
      return setBloqueio(true);
    }

    if (dadosBasicos?.nacionalidade === 'Brasileiro') {
      if (
        !dadosBasicos?.nomeCompleto ||
        !dadosBasicos?.nacionalidade ||
        !dadosBasicos?.titularDadosEstadoCivil ||
        !localizacaoGeografica?.localizacaoTipoLogradouroDescricao ||
        !localizacaoGeografica?.logradouro ||
        !localizacaoGeografica?.numero ||
        !localizacaoGeografica?.bairro ||
        !localizacaoGeografica?.cep ||
        !localizacaoGeografica?.municipio ||
        !localizacaoGeografica?.uf ||
        !documentoIdentificacao?.cpfNumero ||
        // !documentoIdentificacao?.rgNumero ||
        !contato?.email
      ) {
        setAvisoBrasileiro(true);
        return setBloqueio(true);
      }
      return setBloqueio(false);
    }

    if (dadosBasicos?.nacionalidade === 'Estrangeiro') {
      if (
        !dadosBasicos?.nomeCompleto ||
        !dadosBasicos?.nacionalidade ||
        !dadosBasicos?.titularDadosEstadoCivil ||
        !localizacaoGeografica?.localizacaoTipoLogradouroDescricao ||
        !localizacaoGeografica?.logradouro ||
        !localizacaoGeografica?.numero ||
        !localizacaoGeografica?.bairro ||
        !localizacaoGeografica?.cep ||
        !localizacaoGeografica?.municipio ||
        !localizacaoGeografica?.uf ||
        !documentoIdentificacao?.cpfNumero ||
        !contato?.email
      ) {
        setAvisoEstrangeiro();
        return setBloqueio(true);
      }
      return setBloqueio(false);
    }

    return '';
  }, [dadosBasicos, localizacaoGeografica, documentoIdentificacao, contato]);

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      {vinculo.mensagemEdicaoCadastro && (
        <AvisoCadastro msg={vinculo.mensagemEdicaoCadastro} />
      )}

      <h3>Termo de compromisso</h3>

      {bloqueio ? (
        <Alert variant="info">
          <>
            <h5>
              <BsFillExclamationTriangleFill style={{ marginRight: '10px' }} />{' '}
              Atenção!
            </h5>
            <p>
              Você precisa preencher seu cadastro por completo antes de gerar o
              termo.
            </p>
            <br />
            {avisoNacionalidade && (
              <p>
                Campos obrigatórios de preenchimento antes de gerar o termo para
                a nacionalidade Brasileira: Nacionalidade.
              </p>
            )}
            {avisoBrasileiro && (
              <p>
                Campos obrigatórios de preenchimento antes de gerar o termo para
                a nacionalidade Brasileira: Nacionalidade, Nome Completo, Estado
                Civil, Localização Geográfica, CPF e E-mail.
              </p>
            )}
            {avisoEstrangeiro && (
              <p>
                Campos obrigatórios de preenchimento antes de gerar o termo:
                Nacionalidade, Nome Completo, Estado Civil, Localização
                Geográfica, CPF e E-mail.
              </p>
            )}
          </>
        </Alert>
      ) : (
        <>
          <Alert variant="info">
            <p>
              <BsFillExclamationTriangleFill style={{ marginRight: '10px' }} />{' '}
              Confira se todos os campos preenchidos automaticamente no termo
              estão corretos. Caso não estejam, verifique o preenchimento do seu
              cadastro.
            </p>
          </Alert>

          <p>
            <strong>
              É necessário
              <button
                type="button"
                className="btn btn-link"
                onClick={() => setModalTermo(true)}
                style={{ margin: '0' }}
              >
                <strong>imprimir o termo de compromisso</strong>
              </button>
              , assinar, digitalizar e anexar.
            </strong>
          </p>

          <h5>Anexar</h5>

          <Anexos idBlocoDados={10} />
        </>
      )}

      <Modal
        show={modalTermo}
        onHide={() => setModalTermo(false)}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Termo de Compromisso</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div id="termo">
            <img src={fiotec} className="logo" alt="Logo Fiotec" />
            <br />
            <b>TERMO DE COMPROMISSO – UTILIZAÇÃO DE ASSINATURA ELETRÔNICA</b>
            <br />
            <br />
            <p>
              {dadosBasicos?.nomeCompleto}, {dadosBasicos?.nacionalidade}, {}
              {dadosBasicos?.titularDadosEstadoCivil}, residente e
              domiciliado(a) na,{' '}
              {localizacaoGeografica?.localizacaoTipoLogradouroDescricao} {}
              {localizacaoGeografica?.logradouro},{' '}
              {localizacaoGeografica?.numero} - {localizacaoGeografica?.bairro}{' '}
              - {localizacaoGeografica?.cep} - {}
              {localizacaoGeografica?.municipio}/{localizacaoGeografica?.uf},{' '}
              inscrito(a) no CPF sob o nº {documentoIdentificacao?.cpfNumero},
              E-mail: {contato?.email}, doravante denominada{' '}
              <b>COMPROMISSADO.</b>
            </p>
            <br />
            <p>
              O <b>COMPROMISSADO</b> acima identificado aceita as condições do
              presente <b>TERMO DE COMPROMISSO</b> para a utilização do sistema
              de assinatura eletrônica da Fundação para o Desenvolvimento
              Científico e Tecnológico em Saúde - Fiotec, concordando em cumprir
              as normas abaixo estabelecidas e aquelas que vierem a ser
              publicadas para regulamentação do uso do sistema.
            </p>
            <br />O <b>COMPROMISSADO</b> se compromete e concorda que:
            <br />
            <ul>
              <li style={{ listStyle: 'initial', marginLeft: '15px' }}>
                O acesso ao sistema de assinatura eletrônica, a prática de atos,
                como assinatura e envio de documentos, por meio eletrônico,
                serão admitidos mediante uso de assinatura eletrônica, através
                de login e senha, sendo obrigatório o credenciamento prévio;
              </li>
              <br />
              <li style={{ listStyle: 'initial', marginLeft: '15px' }}>
                O credenciamento é ato pessoal, direto, intransferível e
                indelegável, sendo os atos praticados no sistema de pessoas
                físicas – EPF;
              </li>
              <br />
              <li style={{ listStyle: 'initial', marginLeft: '15px' }}>
                O credenciamento se dá através da assinatura do presente{' '}
                <b>TERMO DE COMPROMISSO</b>, em meio físico, e com a vinculação
                dos documentos de identificação no sistema EPF, estando, após a
                validação destes trâmites pela Fiotec, apto para a utilização do
                Sistema;
              </li>
              <br />
              <li style={{ listStyle: 'initial', marginLeft: '15px' }}>
                Os atos gerados no sistema de assinatura eletrônica serão
                registrados com a identificação do usuário, a data e o horário
                de sua realização;
              </li>
              <br />
              <li style={{ listStyle: 'initial', marginLeft: '15px' }}>
                Os documentos produzidos eletronicamente e juntados ao sistema
                de assinatura eletrônica e EPF através de assinatura eletrônica
                (login e senha), tem garantia da origem e de seu signatário,
                sendo considerados originais para todos os efeitos legais;
              </li>
              <br />
              <li style={{ listStyle: 'initial', marginLeft: '15px' }}>
                É da exclusiva responsabilidade do <b>COMPROMISSADO</b> a
                utilização de sua assinatura eletrônica (login e senha) para
                acesso e prática de atos no sistema, devendo adotar boas
                práticas de segurança da informação recomendadas pela TI da
                Fiotec para preservação da senha respectiva e respondendo por
                eventual uso indevido;
              </li>
              <br />
            </ul>
            <p>
              Local e data: Rio de Janeiro, {dataAtual}
              <br />
            </p>
            ___________________________________
            <br />
            Assinatura do compromissado
            <br />
            Fundação para o Desenvolvimento Científico e Tecnológico em Saúde
            (Fiotec)
            <br />
            Av. Brasil, 4036 - Manguinhos
            <br />
            21040-361 – Rio de Janeiro – RJ - Brasil
            <br />
            Telefone: (21) 2209-2600 - Fax: (21) 2209-2754
            <br />
            fiotec@fiotec.fiocruz.br - www.fiotec.fiocruz.br
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalTermo(false)}>
            Fechar
          </Button>
          <Button variant="primary" onClick={() => printDiv('termo')}>
            Imprimir
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
