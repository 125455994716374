/* eslint-disable react/prop-types */
import React from 'react';
import { Form } from 'react-bootstrap';

export const Filter = ({ column }) => (
  <div style={{ marginTop: 5 }}>
    {column.canFilter && column.render('Filter')}
  </div>
);

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => (
  <Form.Control
    value={filterValue || ''}
    onChange={(e) => {
      setFilter(e.target.value || undefined);
    }}
    placeholder={`buscar (${length}) ...`}
  />
);
