import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { api } from '../../../services/api';
import Table from '../../../components/Table';
import Anexos from '../../../components/Anexos';

import {
  isFieldAvailable,
  mascara,
  isValidDate,
  stringToDate,
  dateValid,
} from '../../../utils/index';

import AvisoCadastro from '../../../components/AvisoCadastro';
import LoadingPage from '../../../components/LoadingPage';

import { useParametrizacao } from '../../../contexts/parametrizacao';
import { useVinculo } from '../../../contexts/vinculo';
import { useEtapasVinculo } from '../../../contexts/etapasVinculo';

export default function HistoricoProfissional() {
  const [camposParametrizados, setCamposParametrizados] = useState([]);
  const [historicos, setHistoricos] = useState([]);
  const [vinculos, setVinculos] = useState([]);
  const [historicoEdicao, setHistoricoEdicao] = useState({});
  const [modalHistorico, setModalHistorico] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSalvarCadastro, setLoadingSalvarCadastro] = useState(false);
  const [camposDesabilitados, setCamposDesabilitados] = useState(false);

  const { handleSubmit, register, reset, watch, getValues } = useForm({
    mode: 'onBlur',
  });

  const key = localStorage.getItem('@EPF2:keyTitular');
  const { parametrizacao } = useParametrizacao();
  const { vinculo } = useVinculo();
  const { etapasVinculo } = useEtapasVinculo();

  if (!key) {
    return <AvisoCadastro msg="Chave inválida" />;
  }

  async function fetchHistoricos() {
    try {
      const res = await api.get(
        `cadastroapi/api/v1/HistoricosProfissionais/${key}`
      );
      return setHistoricos(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível exibir a lista de históricos.');
    }
  }

  async function fetchVinculosPublicos() {
    try {
      const res = await api.get(
        `dicionarioapi/api/v1/Dicionarios/vinculos-publico`
      );
      return setVinculos(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível exibir a lista de vínculos.');
    }
  }

  async function fetchHistoricoDetalhes(idHistorico) {
    try {
      const res = await api.get(
        `cadastroapi/api/v1/HistoricosProfissionais/${key}/${idHistorico}/detalhes`
      );
      return setHistoricoEdicao(res.data.result);
    } catch (err) {
      return toast.error('Não foi possível exibir os detalhes do histórico.');
    }
  }

  async function handleAdicionarHistorico(data) {
    if (!dateValid) {
      return toast.error('Data preenchida inválida.');
    }

    const historico = {
      keyTitularDados: key,
      idVinculoPublico: data.vinculo || null,
      orgao: data.orgao,
      empregador: data.empregador,
      dataAdmissao: stringToDate(data.dataAdmissao),
      dataDemissao: data.dataDemissao ? stringToDate(data.dataDemissao) : null,
      cargo: data.cargo,
      funcao: data.funcao,
      descricaoAtividades: data.descricao,
      atual: data.atual,
    };

    try {
      if (historicoEdicao.id) {
        const historicoPut = {
          id: historicoEdicao.id,
          ...historico,
        };
        setLoadingSalvarCadastro(true);
        await api.put(
          'cadastroapi/api/v1/HistoricosProfissionais',
          historicoPut
        );
        setLoadingSalvarCadastro(false);
        toast.success('Cadastro salvo.');
      } else {
        setLoadingSalvarCadastro(true);

        await api.post('cadastroapi/api/v1/HistoricosProfissionais', historico);
        setLoadingSalvarCadastro(false);
        toast.success('Cadastro salvo.');
      }

      setModalHistorico(false);
      return fetchHistoricos(key);
    } catch (err) {
      setLoadingSalvarCadastro(false);

      if (typeof err.response.data.error === 'object') {
        return err.response.data.error.map((item) => toast.error(item));
      }
      return toast.error(err.response.data.error);
    }
  }

  async function handlerRemover(id) {
    try {
      await api.delete(
        `cadastroapi/api/v1/HistoricosProfissionais/${key}/${id}`
      );
      toast.success('Deletado com sucesso');
      return fetchHistoricos(key);
    } catch (err) {
      return toast.error('Não foi possível deletar');
    }
  }

  const atualChecked = watch('atual');
  const valorVinculo = watch('vinculo');

  useEffect(() => {
    const remover = {};

    if (atualChecked) {
      remover.dataDemissao = '';
    }

    if (valorVinculo === '1') {
      remover.orgao = '';
    }

    if (remover) {
      reset({ ...getValues(), ...remover });
    }
  }, [atualChecked, valorVinculo]);

  useEffect(async () => {
    setLoadingPage(true);
    await fetchVinculosPublicos();
    await fetchHistoricos();
    setLoadingPage(false);
  }, []);

  useEffect(() => {
    setCamposParametrizados(parametrizacao[7]?.campos);
    const campos = parametrizacao[7]?.campos.every(
      (field) => field.isObrigatorio === false
    );
    setCamposDesabilitados(campos);
  }, [parametrizacao]);

  useEffect(() => {
    if (historicoEdicao.id) {
      reset({
        vinculo: historicoEdicao.idVinculoPublico,
        orgao: historicoEdicao.orgao,
        empregador: historicoEdicao.empregador,
        cargo: historicoEdicao.cargo,
        funcao: historicoEdicao.funcao,
        descricao: historicoEdicao.descricaoAtividades,
        atual: historicoEdicao.atual,
        dataAdmissao: historicoEdicao.dataAdmissao,
        dataDemissao: historicoEdicao.dataDemissao,
      });
    } else {
      reset({});
    }
  }, [historicoEdicao]);

  const columnsHistorico = [
    {
      id: 'empregador',
      Header: 'Empregador',
      accessor: 'empregador',
    },
    {
      id: 'cargo',
      Header: 'Cargo',
      accessor: 'cargo',
    },
    {
      id: 'funcao',
      Header: 'Função',
      accessor: 'funcao',
    },
    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              fetchHistoricoDetalhes(rows.row.original.id);
              setModalHistorico(true);
            }}
          >
            {!vinculo.habilitaEditarCadastro ? 'Visualizar' : 'Editar'}
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => handlerRemover(rows.row.original.id)}
            disabled={!vinculo.habilitaEditarCadastro}
          >
            Remover
          </Button>
        </>
      ),
    },
  ];

  if (vinculo.idCadastroStatus === 6) {
    return (
      <AvisoCadastro msg="Esse vínculo foi excluído, por esse motivo essa etapa não pode ser acessada." />
    );
  }

  if (etapasVinculo?.blocos?.[7].isAtivo && camposDesabilitados) {
    return <AvisoCadastro msg="Nenhum campo habilitado para preenchimento." />;
  }

  if (etapasVinculo?.blocos && !etapasVinculo?.blocos?.[7].isAtivo) {
    return (
      <AvisoCadastro msg="Essa etapa está desabilitada para esse vínculo." />
    );
  }

  if (loadingPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <h3>Histórico Profissional</h3>

      {vinculo.mensagemEdicaoCadastro && (
        <AvisoCadastro msg={vinculo.mensagemEdicaoCadastro} vinculo={vinculo} />
      )}

      <Row>
        <Col md="12" style={{ textAlign: 'right' }}>
          <Button
            variant="primary"
            onClick={() => {
              setHistoricoEdicao({});
              setModalHistorico(true);
            }}
            disabled={!vinculo.habilitaEditarCadastro}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <br />
      <br />
      <Table columns={columnsHistorico} data={historicos} />
      <h5>Anexos</h5>
      <Anexos idBlocoDados={8} />
      <br />
      <br />

      <Modal show={modalHistorico} onHide={() => setModalHistorico(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Histórico</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Row>
                {isFieldAvailable(
                  'HistoricoProfissional_IdVinculoPublico',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>Servidor Público</Form.Label>
                    <Form.Control
                      name="vinculo"
                      ref={register()}
                      as="select"
                      disabled={!vinculo.habilitaEditarCadastro}
                    >
                      <option aria-label="Selecione" />
                      {vinculos.map((vinculo) => (
                        <option key={vinculo.id} value={vinculo.id}>
                          {vinculo.descricao}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                )}

                {isFieldAvailable(
                  'HistoricoProfissional_Orgao',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>Órgão</Form.Label>
                    <Form.Control
                      name="orgao"
                      ref={register()}
                      disabled={
                        parseInt(valorVinculo, 10) === 1 ||
                        !vinculo.habilitaEditarCadastro
                      }
                      maxLength="100"
                    />
                  </Col>
                )}
              </Row>
              <br />

              <Row>
                {isFieldAvailable(
                  'HistoricoProfissional_Empregador',
                  camposParametrizados
                ) && (
                  <Col md="12">
                    <Form.Label>Empregador</Form.Label>
                    <Form.Control
                      name="empregador"
                      ref={register()}
                      disabled={!vinculo.habilitaEditarCadastro}
                      maxLength="100"
                    />
                  </Col>
                )}
              </Row>

              <br />

              <Row>
                {isFieldAvailable(
                  'HistoricoProfissional_Cargo',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>Cargo</Form.Label>
                    <Form.Control
                      name="cargo"
                      ref={register()}
                      disabled={!vinculo.habilitaEditarCadastro}
                      maxLength="100"
                    />
                  </Col>
                )}

                {isFieldAvailable(
                  'HistoricoProfissional_Funcao',
                  camposParametrizados
                ) && (
                  <Col md="6">
                    <Form.Label>Função</Form.Label>
                    <Form.Control
                      name="funcao"
                      ref={register()}
                      disabled={!vinculo.habilitaEditarCadastro}
                      maxLength="100"
                    />
                  </Col>
                )}
              </Row>
              <br />
              {isFieldAvailable(
                'HistoricoProfissional_DescricaoAtividades',
                camposParametrizados
              ) && (
                <Row>
                  <Col md="12">
                    <Form.Label>Descrição das atividades</Form.Label>
                    <Form.Control
                      name="descricao"
                      ref={register()}
                      as="textarea"
                      rows={5}
                      disabled={!vinculo.habilitaEditarCadastro}
                    />
                  </Col>
                </Row>
              )}

              <br />

              <Row>
                {isFieldAvailable(
                  'HistoricoProfissional_DataAdmissao',
                  camposParametrizados
                ) && (
                  <Col md="4">
                    <Form.Label>Data de admissão</Form.Label>
                    <Form.Control
                      name="dataAdmissao"
                      ref={register()}
                      placeholder="__/__/____"
                      onInput={(e) => mascara(e.target, 'data')}
                      onBlur={(e) => isValidDate(e.target)}
                      disabled={!vinculo.habilitaEditarCadastro}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </Col>
                )}

                {isFieldAvailable(
                  'HistoricoProfissional_DataDemissao',
                  camposParametrizados
                ) && (
                  <Col md="4">
                    <Form.Label>Data de demissão</Form.Label>
                    <Form.Control
                      name="dataDemissao"
                      ref={register()}
                      placeholder="__/__/____"
                      onInput={(e) => mascara(e.target, 'data')}
                      onBlur={(e) => isValidDate(e.target)}
                      disabled={atualChecked || !vinculo.habilitaEditarCadastro}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </Col>
                )}

                <Col md="4">
                  <Form.Check
                    name="atual"
                    ref={register()}
                    type="checkbox"
                    label="Atualmente"
                    style={{ marginTop: '48px' }}
                    disabled={!vinculo.habilitaEditarCadastro}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalHistorico(false)}>
            Fechar
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit(handleAdicionarHistorico)}
            disabled={!vinculo.habilitaEditarCadastro || loadingSalvarCadastro}
          >
            {loadingSalvarCadastro ? 'Aguarde...' : 'Salvar'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
