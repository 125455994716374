/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import Table from '../../components/Table';
import { api } from '../../services/api';

export default function EmValidacaoTable({ fetchTotal }) {
  const history = useHistory();

  const [emValidacao, setEmValidacao] = useState([]);

  async function fetchValidacao() {
    try {
      const res = await api.get('cadastroapi/api/v1/Cadastros/concluidos');
      setEmValidacao(res.data.result);
      fetchTotal(res.data.result.length, 'emValidacao');
    } catch (err) {
      toast.error('Não foi possível carregar a lista de em validação.');
    }
  }

  const redirecionar = (keyTitularDados) => {
    localStorage.setItem('@EPF2:keyTitular', keyTitularDados);
    history.push('/dadosBasicos');
  };

  useEffect(() => fetchValidacao(), []);

  const columnsEmValidacao = [
    {
      id: 'descricaoVinculo',
      Header: 'Vínculo',
      accessor: 'descricaoVinculo',
      disableFilters: true,
    },
    {
      id: 'dataUltimaAtualizacao',
      Header: 'Última atualização',
      accessor: 'dataUltimaAtualizacao',
      disableFilters: true,
    },

    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          <Button
            variant="primary"
            size="sm"
            onClick={() => redirecionar(rows.row.original.keyTitularDados)}
          >
            Visualizar
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Table data={emValidacao} columns={columnsEmValidacao} />
    </>
  );
}
