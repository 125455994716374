/* eslint-disable react/button-has-type */
import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/login';
import RedefinirSenha from '../pages/redefinirSenha';

import Inicio from '../pages/inicio';
import EnviarValidacao from '../pages/cadastro/enviarValidacao';
import DadosBasicos from '../pages/cadastro/dadosBasicos';
import HistoricoProfissional from '../pages/cadastro/historicoProfissional';
import LocalizacaoGeografica from '../pages/cadastro/localizacaoGeografica';
import Contato from '../pages/cadastro/contato';
import DocumentosIdentificacao from '../pages/cadastro/documentosIdentificacao';
import Dependentes from '../pages/cadastro/dependentes';
import Formacao from '../pages/cadastro/formacao';
import InformacoesComplementares from '../pages/cadastro/informacoesComplementares';
import DadosBancarios from '../pages/cadastro/dadosBancarios';
import Portabilidade from '../pages/portabilidade';
import contratos from '../pages/contratos';
import Downloads from '../pages/downloads';
import TratamentoDados from '../pages/tratamentoDados/configurar';
import TermoCompromisso from '../pages/cadastro/termoCompromisso';
import PrimeiroAcesso from '../pages/primeiroAcesso';
import Agenda from '../pages/agenda';
import HistoricoTratamentoDados from '../pages/tratamentoDados/historico';

import DocumentosSolicitarDeclaracoes from '../pages/documentos/declaracoes/solicitacao';
import DocumentosHistorico from '../pages/documentos/declaracoes/historico/tabelaHistorico';

import RelatoriosCadastroTabela from '../pages/bolsas/relatorios/tabelaSolicitar';
import RelatoriosCadastro from '../pages/bolsas/relatorios/solicitacao';
import RelatoriosHistorico from '../pages/bolsas/relatorios/tabelaHistoricoCadastro';

import CancelamentosSolicitarTabela from '../pages/bolsas/cancelamentos/tabelaSolicitar';
import CancelamentosSolicitar from '../pages/bolsas/cancelamentos/solicitacao';
import CancelamentosHistorico from '../pages/bolsas/cancelamentos/tabelaHistoricoSolicitacoes';

import CartaBradesco from '../pages/carta-bradesco';

import NotFound from '../pages/notFound';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/primeiroacesso/:key" component={PrimeiroAcesso} />

      <Route exact path="/redefinir-senha/:key" component={RedefinirSenha} />

      <Route exact path="/error/" component={Error} isPrivate />

      <Route path="/inicio/" component={Inicio} isPrivate />
      <Route path="/enviarValidacao/" component={EnviarValidacao} isPrivate />

      <Route path="/tratamento-dados/" component={TratamentoDados} isPrivate />

      <Route path="/dadosBasicos/" component={DadosBasicos} isPrivate />
      <Route
        path="/historicoProfissional/"
        component={HistoricoProfissional}
        isPrivate
      />

      <Route
        path="/localizacaoGeografica/"
        component={LocalizacaoGeografica}
        isPrivate
      />
      <Route exact path="/contato/" component={Contato} isPrivate />
      <Route
        exact
        path="/documentosIdentificacao/"
        component={DocumentosIdentificacao}
        isPrivate
      />
      <Route exact path="/dependentes/" component={Dependentes} isPrivate />
      <Route exact path="/formacao/" component={Formacao} isPrivate />
      <Route
        exact
        path="/informacoesComplementares/"
        component={InformacoesComplementares}
        isPrivate
      />
      <Route
        exact
        path="/dadosBancarios/"
        component={DadosBancarios}
        isPrivate
      />
      <Route exact path="/portabilidade/" component={Portabilidade} isPrivate />
      <Route exact path="/contratos/" component={contratos} isPrivate />
      <Route
        exact
        path="/historicoTratamentoDados/"
        component={HistoricoTratamentoDados}
        isPrivate
      />
      <Route exact path="/downloads/" component={Downloads} isPrivate />
      <Route
        exact
        path="/termoCompromisso/"
        component={TermoCompromisso}
        isPrivate
      />

      <Route path="/agenda/" component={Agenda} isPrivate />

      <Route
        exact
        path="/documentos/solicitar-declaracao/"
        component={DocumentosSolicitarDeclaracoes}
        isPrivate
      />

      <Route
        exact
        path="/documentos/historico/"
        component={DocumentosHistorico}
        isPrivate
      />

      <Route
        exact
        path="/bolsas/relatorios/"
        component={RelatoriosCadastroTabela}
        isPrivate
      />

      <Route
        exact
        path="/bolsas/relatorios/historico/"
        component={RelatoriosHistorico}
        isPrivate
      />

      <Route
        exact
        path="/bolsas/relatorios/solicitacao/:keySolicitacao/"
        component={RelatoriosCadastro}
        isPrivate
      />

      <Route
        exact
        path="/bolsas/cancelamentos/"
        component={CancelamentosSolicitarTabela}
        isPrivate
      />

      <Route
        exact
        path="/bolsas/cancelamentos/historico/"
        component={CancelamentosHistorico}
        isPrivate
      />

      <Route
        exact
        path="/bolsas/cancelamentos/solicitacao/:keySolicitacao/"
        component={CancelamentosSolicitar}
        isPrivate
      />

      <Route exact path="/carta-bradesco" component={CartaBradesco} isPrivate />

      <Route component={NotFound} isPrivate />
    </Switch>
  );
}
