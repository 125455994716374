/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import uniqid from 'uniqid';
import filesize from 'filesize';
import { Row, Col, Button, Form, Alert, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { parseISO, format } from 'date-fns';
import declaracaoMoradia from '../../pages/cadastro/localizacaoGeografica/DECLARACAO_RESIDENCIA_(002).pdf';
import selfie from '../../images/selfie.png';

import Upload from '../Upload';
import FileList from '../FileList';
import Table from '../Table';
import { api } from '../../services/api';

import { useVinculo } from '../../contexts/vinculo';

export default function Anexos({ idBlocoDados }) {
  const key = localStorage.getItem('@EPF2:keyTitular');

  const [loading, setLoading] = useState(false);

  const [tipoDocumentosAnexo, setTipoDocumentosAnexo] = useState([]);
  const [anexosUpload, setAnexosUpload] = useState([]);
  const [listaAnexos, setListaAnexos] = useState([]);

  const [idTipoAnexos, setIdTipoAnexos] = useState([]);
  const [tiposDocumentosEscolhidos, setTiposDocumentosEscolhidos] = useState(
    []
  );
  const [descricaoAnexo, setDescricaoAnexo] = useState('');

  const [avisoIdentificacaoVisual, setAvisoIdentificacaoVisual] = useState(
    false
  );

  const [
    modalAvisoIdentificacaoVisual,
    setModalAvisoIdentificacaoVisual,
  ] = useState(false);

  const { vinculo } = useVinculo();

  async function fecthListaTipoAnexo() {
    const res = await api.get(
      `dicionarioapi/api/v1/Dicionarios/${idBlocoDados}/${vinculo.idTitularTipo}/tipo-anexo-etapa`
    );

    setTipoDocumentosAnexo(res.data.result);
  }

  async function fecthListaAnexos() {
    try {
      const res = await api.get(
        `cadastroapi/api/v1/Anexo/${key}/${idBlocoDados}/etapas`
      );
      setListaAnexos(res.data.result);
    } catch (error) {
      console.log(error);
    }
  }

  function handleUpload(files) {
    files.map((file) =>
      setAnexosUpload([
        {
          file,
          id: uniqid(),
          name: file.name,
          readableSize: filesize(file.size),
        },
      ])
    );
  }

  async function handleDeleteAnexo(fileId) {
    const newAnexos = anexosUpload.filter((anexo) => anexo.id !== fileId);

    setAnexosUpload(newAnexos);
  }

  async function handleSalvarAnexo() {
    const data = new FormData();
    const config = {
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    if (tiposDocumentosEscolhidos.length <= 0) {
      return toast.error('Selecione o(s) tipos(s) de documento(s).');
    }

    if (anexosUpload.length <= 0) {
      return toast.error(
        'Selecione o(s) arquivo(s) em seu computador antes de enviar.'
      );
    }

    data.append('KeyTitularDados', key);
    data.append('IdEtapaCadastro', JSON.stringify(idBlocoDados));
    data.append('Descricao', descricaoAnexo);
    idTipoAnexos.forEach((ta) => {
      data.append('IdTiposAnexo', ta);
    });

    anexosUpload.forEach((a) => {
      data.append('ArquivoAnexo', a.file);
    });

    try {
      setLoading(true);
      await api.post('cadastroapi/api/v1/Anexo', data, config);
      setLoading(false);
      fecthListaAnexos();
      setAnexosUpload([]);
      return toast.success('Anexo(s) salvo(s) com sucesso.');
    } catch (error) {
      setLoading(false);
      return toast.error('Erro ao enviar o(s) anexo(s).');
    }
  }

  async function handleExcluirArquivo(idAnexo) {
    try {
      await api.delete(`cadastroapi/api/v1/Anexo/${key}/${idAnexo}`);
      fecthListaAnexos();
      return toast.success('Arquivo excluido');
    } catch (error) {
      return toast.error('Não foi possível excluir o arquivo.');
    }
  }

  const columnsAnexosApi = [
    {
      Header: 'ARQUIVO',
      accessor: 'nomeArquivo',
      width: 200,
      Cell: (rows) => (
        <>
          <a
            href={`${process.env.REACT_APP_API_SISTEMA}cadastroapi/api/v1/Anexo/${rows.row.original.key}/download`}
          >
            {rows.row.original.nomeArquivo}
          </a>
        </>
      ),
      disableFilters: true,
    },
    {
      Header: 'TIPO ANEXO',
      accessor: 'tiposAnexo',
      width: 200,
      disableFilters: true,
      Cell: (rows) =>
        rows.row.original.tiposAnexo &&
        rows.row.original.tiposAnexo?.map((ta) => ta),
    },
    {
      Header: 'DATA',
      accessor: 'dataInclusao',
      width: 200,
      disableFilters: true,
      Cell: (rows) =>
        format(parseISO(rows.row.original.dataInclusao), 'dd/MM/yyyy HH:mm', {
          timeZone: 'America/Sao_Paulo',
        }),
    },
    {
      Header: 'AÇÃO',
      Cell: (rows) => (
        <>
          <Button
            variant="danger"
            size="sm"
            block
            onClick={() => {
              handleExcluirArquivo(rows.row.original.id);
            }}
            disabled={!vinculo.habilitaEditarCadastro}
          >
            Remover
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    fecthListaTipoAnexo();
    fecthListaAnexos();
  }, [vinculo]);

  useEffect(() => {
    const newLista = tiposDocumentosEscolhidos.map((d) => d.id);

    setIdTipoAnexos([...newLista]);
  }, [tiposDocumentosEscolhidos]);

  useEffect(() => {
    const documentoIdentificacao = tipoDocumentosAnexo.filter(
      (doc) => doc.id === 36
    );

    if (idBlocoDados === 4 && documentoIdentificacao.length === 1) {
      setAvisoIdentificacaoVisual(true);
    } else {
      setAvisoIdentificacaoVisual(false);
    }
  }, [listaAnexos]);

  return (
    <Row>
      {avisoIdentificacaoVisual && (
        <Col md="12">
          <Alert variant="info">
            <BsFillExclamationTriangleFill />
            <Button
              variant="link"
              style={{ marginLeft: '10px', color: '#000' }}
              onClick={() => setModalAvisoIdentificacaoVisual(true)}
            >
              Veja as instruções para anexar o Documento de Identificação Visual
            </Button>
          </Alert>
        </Col>
      )}

      {tipoDocumentosAnexo.length >= 1 ? (
        <Col md="12">
          {vinculo.habilitaEditarCadastro ? (
            <>
              {idBlocoDados === 2 && (
                // idBlocodados 2 é a etapa de localização geografica
                <>
                  <Alert variant="info">
                    <BsFillExclamationTriangleFill />
                    <a
                      style={{
                        marginLeft: '26px',
                        color: '#000',
                        fontSize: '16px',
                      }}
                      href={declaracaoMoradia}
                      download
                    >
                      Veja modelo da declaração de residência
                    </a>
                  </Alert>
                  <br />
                </>
              )}
              <Form.Label>
                Descrição (Preeenchimento não obrigatório)
              </Form.Label>
              <Form.Control
                name="descricaoAnexo"
                onChange={(e) => setDescricaoAnexo(e.target.value)}
                maxLength="100"
              />
              <br />
              <Select
                options={tipoDocumentosAnexo}
                isMulti
                getOptionLabel={(e) => e.descricao}
                getOptionValue={(e) => e.id}
                onChange={(e) => {
                  setTiposDocumentosEscolhidos(e);
                }}
                placeholder="Selecione o(s) tipo(s) de documento(s)"
                value={tiposDocumentosEscolhidos}
              />
              <br />
              <Upload onUpload={handleUpload} />
              <FileList files={anexosUpload} onDelete={handleDeleteAnexo} />
              <Button
                variant="primary"
                size="sm"
                onClick={handleSalvarAnexo}
                disabled={loading}
              >
                {loading ? 'Enviando...' : 'Salvar Anexos'}
              </Button>
              <br />
              <br />
              <br />
            </>
          ) : (
            <p>Não é possível adicionar anexos.</p>
          )}

          <Row>
            <Col>
              {listaAnexos?.length > 0 ? (
                <Table
                  data={listaAnexos}
                  columns={columnsAnexosApi}
                  showPageSizeOptions={false}
                  showPagination=""
                  defaultPageSize={5}
                  minRows={0}
                  pageText="Página"
                  loadingText="Carregando..."
                  previousText="Página anterior"
                  nextText="Próxima página"
                  ofText="de"
                  className="-highlight"
                  noDataText="Sem resultados"
                />
              ) : (
                <>
                  <br /> <br />
                </>
              )}
            </Col>
          </Row>
        </Col>
      ) : (
        <Col>
          <p>Nenhum anexo é necessário para essa etapa.</p>
        </Col>
      )}

      <Modal
        show={modalAvisoIdentificacaoVisual}
        onHide={() => setModalAvisoIdentificacaoVisual(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Documento de Identificação Visual</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <p>
              Precisamos de uma foto sua (selfie) segurando o documento, que
              pode ser o RG, CNH ou RNE.
            </p>
            <p>
              Tenha cuidado para não cobrir seu rosto com o documento e também
              não cobrir a foto do documento.
            </p>
            <p>Fique tranquilo, esta foto não será divulgada.</p>
            <p>
              <strong>Exemplo:</strong>
              <br />
              <img src={selfie} alt="Selfie" />
            </p>
          </>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => setModalAvisoIdentificacaoVisual(false)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
}
