import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {
  FaWrench,
  FaRegEdit,
  FaRegCopy,
  FaRegArrowAltCircleDown,
  FaRegEnvelope,
  FaRegShareSquare,
  FaRegIdBadge,
  FaRegListAlt,
  FaRegHandshake,
} from 'react-icons/fa';

import { HiOutlineHome } from 'react-icons/hi';

import { Nav } from './styles';
import { useVinculo } from '../../contexts/vinculo';
import { useParametrizacao } from '../../contexts/parametrizacao';
import { useEtapasVinculo } from '../../contexts/etapasVinculo';

export default function Menu() {
  const [submenu, setSubmenu] = useState('Cadastro');
  const key = localStorage.getItem('@EPF2:keyTitular');
  const history = useHistory();

  // Salva no contexto os dados do vinculo
  const { ContextTitularResumo, vinculo } = useVinculo();
  const { ContextParametrizacao } = useParametrizacao();
  const { ContextEtapasVinculo, etapasVinculo } = useEtapasVinculo();

  async function loadVinculo() {
    await ContextTitularResumo(key);
  }

  async function loadEtapasVinculo() {
    await ContextEtapasVinculo(vinculo?.idTitularTipo);
  }

  async function loadParametrizacao() {
    await ContextParametrizacao(vinculo?.idTitularTipo);
  }

  useEffect(() => {
    if (key) {
      loadVinculo();
    }
    const subMenu = localStorage.getItem('@EPF2:menu');

    if (subMenu) {
      setSubmenu(subMenu);
    }
  }, []);

  useEffect(() => {
    if (vinculo.idTitularTipo) {
      loadParametrizacao();
      loadEtapasVinculo();
    }
  }, [vinculo]);

  function toggleMenuMobile(e) {
    e.preventDefault();

    const menuUL = document.querySelector('.menu ul');

    if (menuUL.style.display === 'none' || menuUL.style.display === '') {
      menuUL.style.display = 'block';
    } else {
      menuUL.style.display = 'none';
    }
  }

  return (
    <Nav>
      <Button id="menu-mobile" onClick={(e) => toggleMenuMobile(e)} block>
        Menu
      </Button>

      {vinculo?.idCadastroStatus !== 6 ? (
        <ul>
          <li className="menu-title">
            <strong>Menu</strong>
          </li>
          <li>
            <Button
              variant="link"
              onClick={() => {
                localStorage.setItem('@GNatural:menu', '');
                setSubmenu('');
                history.push('/inicio');
              }}
            >
              <HiOutlineHome size="1.2em" /> Início
            </Button>
          </li>
          <li>
            <Button
              variant="link"
              onClick={() => {
                setSubmenu('Cadastro');
                localStorage.setItem('@EPF2:menu', 'Cadastro');
              }}
            >
              <FaRegEdit size="1.2em" /> Cadastro
            </Button>

            {submenu === 'Cadastro' && (
              <ul>
                {etapasVinculo?.blocos && etapasVinculo?.blocos[0]?.isAtivo && (
                  <li>
                    <NavLink exact to="/dadosBasicos">
                      Dados Básicos
                    </NavLink>
                  </li>
                )}
                {etapasVinculo?.blocos && etapasVinculo?.blocos[1].isAtivo && (
                  <li>
                    <NavLink exact to="/localizacaoGeografica">
                      Localização Geográfica
                    </NavLink>
                  </li>
                )}
                {etapasVinculo?.blocos && etapasVinculo?.blocos[2].isAtivo && (
                  <li>
                    <NavLink exact to="/contato">
                      Contato
                    </NavLink>
                  </li>
                )}
                {etapasVinculo?.blocos && etapasVinculo?.blocos[3].isAtivo && (
                  <li>
                    <NavLink exact to="/documentosIdentificacao">
                      Documentos de Identificação
                    </NavLink>
                  </li>
                )}
                {etapasVinculo?.blocos && etapasVinculo?.blocos[4].isAtivo && (
                  <li>
                    <NavLink exact to="/dependentes">
                      Dependentes
                    </NavLink>
                  </li>
                )}
                {etapasVinculo?.blocos && etapasVinculo?.blocos[5].isAtivo && (
                  <li>
                    <NavLink exact to="/formacao">
                      Formação
                    </NavLink>
                  </li>
                )}
                {etapasVinculo?.blocos && etapasVinculo?.blocos[7].isAtivo && (
                  <li>
                    <NavLink exact to="/historicoProfissional">
                      Histórico Profissional
                    </NavLink>
                  </li>
                )}
                {etapasVinculo?.blocos && etapasVinculo?.blocos[8].isAtivo && (
                  <li>
                    <NavLink exact to="/informacoesComplementares">
                      Informações Complementares
                    </NavLink>
                  </li>
                )}
                {etapasVinculo?.blocos && etapasVinculo?.blocos[6].isAtivo && (
                  <li>
                    <NavLink exact to="/dadosBancarios">
                      Dados Bancários
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink exact to="/termoCompromisso">
                    Termo de Compromisso
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/enviarValidacao">
                    Enviar para Validação
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          {vinculo.permissaoAcesso?.menuDownloads && (
            <li>
              <NavLink
                exact
                to="/downloads"
                onClick={() => {
                  setSubmenu('');
                  localStorage.setItem('@EPF2:menu', 'Downloads');
                }}
              >
                <FaRegArrowAltCircleDown size="1.2em" /> Downloads
              </NavLink>
            </li>
          )}
          {vinculo.permissaoAcesso?.menuCartaBradesco && (
            <li>
              <NavLink exact to="/carta-bradesco">
                <FaRegEnvelope size="1.2em" /> Carta Bradesco
              </NavLink>
            </li>
          )}
          {vinculo.permissaoAcesso?.menuAgenda && (
            <li>
              <NavLink
                exact
                to="/agenda"
                onClick={() => {
                  setSubmenu('');
                  localStorage.setItem('@EPF2:menu', 'Agenda');
                }}
              >
                <FaRegListAlt size="1.2em" /> Agenda
              </NavLink>
            </li>
          )}
          {vinculo.permissaoAcesso?.menuBolsas && (
            <li>
              <Button
                variant="link"
                onClick={() => {
                  setSubmenu('Bolsas');
                  localStorage.setItem('@EPF2:menu', 'Bolsas');
                }}
              >
                <FaRegIdBadge size="1.2em" /> Bolsas
              </Button>

              {submenu === 'Bolsas' && (
                <ul>
                  {vinculo.permissaoAcesso?.telaCadastrarRelatorio && (
                    <li>
                      <NavLink exact to="/bolsas/relatorios">
                        Cadastrar Relatório
                      </NavLink>
                    </li>
                  )}

                  {vinculo.permissaoAcesso?.telaHistoricoRelatorios && (
                    <li>
                      <NavLink exact to="/bolsas/relatorios/historico">
                        Histórico de Relatórios
                      </NavLink>
                    </li>
                  )}

                  {vinculo.permissaoAcesso?.telaSolicitarCancelamento && (
                    <li>
                      <NavLink exact to="/bolsas/cancelamentos">
                        Solicitar Cancelamento
                      </NavLink>
                    </li>
                  )}

                  {vinculo.permissaoAcesso?.telaHistoricoCancelamentos && (
                    <li>
                      <NavLink exact to="/bolsas/cancelamentos/historico">
                        Histórico de Cancelamentos
                      </NavLink>
                    </li>
                  )}
                </ul>
              )}
            </li>
          )}
          {vinculo.permissaoAcesso?.menuContratos && (
            <li>
              <Button
                variant="link"
                onClick={() => {
                  setSubmenu('Contratos');
                  localStorage.setItem('@EPF2:menu', 'Contratos');
                }}
              >
                <FaRegHandshake size="1.2em" /> Contratos
              </Button>

              {submenu === 'Contratos' && (
                <ul>
                  {vinculo.permissaoAcesso?.telaHistoricoContratos && (
                    <li>
                      <NavLink exact to="/contratos">
                        Histórico
                      </NavLink>
                    </li>
                  )}
                </ul>
              )}
            </li>
          )}
          {vinculo.permissaoAcesso?.menuDocumentos && (
            <li>
              <Button
                variant="link"
                onClick={() => {
                  setSubmenu('Documentos');
                  localStorage.setItem('@EPF2:menu', 'Documentos');
                }}
              >
                <FaRegCopy size="1.2em" /> Documentos
              </Button>

              {submenu === 'Documentos' && (
                <ul>
                  {vinculo.permissaoAcesso?.telaSolicitarDeclaracao && (
                    <li>
                      <NavLink exact to="/documentos/solicitar-declaracao">
                        Solicitar Declaração
                      </NavLink>
                    </li>
                  )}
                  {vinculo.permissaoAcesso?.telaHistoricoSolicitacoes && (
                    <li>
                      <NavLink exact to="/documentos/historico">
                        Histórico de Solicitações
                      </NavLink>
                    </li>
                  )}
                </ul>
              )}
            </li>
          )}
          <li>
            <NavLink
              exact
              to="/portabilidade"
              onClick={() => {
                setSubmenu('');
                localStorage.setItem('@EPF2:menu', 'Portabilidade');
              }}
            >
              <FaRegShareSquare size="1.2em" /> Portabilidade
            </NavLink>
          </li>
          <hr />
          <li>
            <strong>
              <FaWrench /> Configurações
            </strong>
          </li>
          <li>
            <Button
              variant="link"
              onClick={() => {
                setSubmenu('TratamentoDados');
                localStorage.setItem('@EPF2:menu', 'TratamentoDados');
              }}
            >
              Tratamento de Dados
            </Button>

            {submenu === 'TratamentoDados' && (
              <ul>
                <li>
                  <NavLink exact to="/tratamento-dados">
                    Configurar
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink exact to="/historicoTratamentoDados">
                    Histórico
                  </NavLink>
                </li> */}
              </ul>
            )}
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <strong>Menu</strong>
          </li>
          <li>
            <NavLink
              exact
              to="/downloads"
              onClick={() => {
                setSubmenu('');
                localStorage.setItem('@EPF2:menu', 'Downloads');
              }}
            >
              Downloads
            </NavLink>
          </li>
        </ul>
      )}
    </Nav>
  );
}
