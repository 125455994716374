/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Table from '../../components/Table';
import { api } from '../../services/api';

export default function VencidosTotal({ fetchTotal }) {
  const history = useHistory();
  const [vencidos, setVencidos] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetchVencidos() {
    try {
      const res = await api.get('cadastroapi/api/v1/Cadastros/vencidos');
      setVencidos(res.data.result);
      fetchTotal(res.data.result.length, 'vencidos');
    } catch (err) {
      toast.error('Não foi possível carregar a lista de vencidos.');
    }
  }

  async function redirecionar(keyTitularDados) {
    try {
      setLoading(true);
      await api.patch(
        `cadastroapi/api/v1/Cadastros/${keyTitularDados}/status-rascunho`
      );
      setLoading(false);

      localStorage.setItem('@EPF2:keyTitular', keyTitularDados);
      history.push('/dadosBasicos');
    } catch (err) {
      toast.error('Não foi possível entrar no cadastro.');
    }
  }

  useEffect(() => fetchVencidos(), []);

  const columnsExcluidos = [
    {
      id: 'descricaoVinculo',
      Header: 'Vínculo',
      accessor: 'descricaoVinculo',
      disableFilters: true,
    },

    {
      Header: 'Ações',
      Cell: (rows) => (
        <>
          <Button
            variant="primary"
            size="sm"
            onClick={() => redirecionar(rows.row.original.keyTitularDados)}
            disabled={loading}
          >
            {loading ? 'Aguarde...' : 'Atualizar'}
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Table data={vencidos} columns={columnsExcluidos} />
    </>
  );
}
