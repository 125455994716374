import React from 'react';
import { useVinculo } from '../../contexts/vinculo';

export default function TipoDeCadastro() {
  const { vinculo } = useVinculo();

  return (
    <small
      style={{
        display: 'block',
        marginBottom: '30px',
        backgroundColor: '#fafafa',
        borderRadius: '5px',
        padding: '10px',
      }}
    >
      Vínculo: <strong>{vinculo?.titularTipoDescricao}</strong> Status:{' '}
      <strong style={{ color: 'red' }}>
        {vinculo?.cadastroStatusDescricao}
      </strong>
    </small>
  );
}
